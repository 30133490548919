import React, { Component } from 'react'
import { Modal, Select, Table, DatePicker } from 'antd'
import moment from 'moment';
import 'antd/dist/antd.css'; // or 'antd/dist/antd.less'
import Notification from '../Notification'
import PropagateLoader from '../SubmitingForm'
import ajax from '../../utils/ajax';
import functions from '../../utils/functions';
import Viewer from 'react-viewer';

import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'


const { Option } = Select;

class ReviewPrescriptionToAcceptOrder extends Component {

  state = {
    userId: functions.sessionGuard(),
    prescriptionId: "",
    load: '',
    visible:false, 
    approveState:"",
    reorderId:"",
    clientId:""

  }

  componentDidMount() {
    this.setState({
      prescriptionId: this.props.visible.presId,
      prescriptionImage: this.props.visible.presImage,
      reorderId:this.props.visible.reorderId,
      clientId:this.props.visible.clientId
    })
  }

  prescriptionStateSelect = (value) => {
    if (value != null) {
      this.setState({approveState:value})
    } else {
     //
    }
  }

  handleCancel = () => {
    const sent = this.props
    this.setState({ visible: false })
    sent.handleCancel(false)
  }
  setVisible = ()=>{
    this.setState({
      visible:true, setVisible:true
    })
  }

  onPressReviewPrescription = async (event) => {
    event.preventDefault()
    const {prescriptionId,approveState,reorderId,clientId } = this.state

    if (approveState.length > 0 || reorderId.length>0 || clientId.length > 0 || prescriptionId.length>0) {

      this.setState({
        type: '',
        info: '',
        load: <PropagateLoader />
      })

      const server_response = await ajax.reviewOrder(approveState,reorderId, clientId, prescriptionId, this.state.userId)

      if (server_response.status === "OK") {
        this.setState({
          type: 'alert alert-success',
          info: server_response.details.message,
          load: '',
        })
        window.location.reload()

      } else {

        this.setState({
          type: 'alert alert-danger',
          info: server_response.details.message,
          load: '',
        })

      }

    } else {
      this.setState({
        type: 'alert alert-danger',
        info: 'Please be advised to fill in all required fields - All fields are required',
        load: '',
      })
    }


  }

  render() {
    const {visible, setVisible } = this.state
    return (
      <>
      <Modal
        title={"VIEW SUBMITTED PRESCRIPTION IMAGE"}
        centered
        bodyStyle={{ paddingBottom: '0px', paddingTop: '0px' }}
        visible={this.props.visible.visible}
        maskClosable={false}
        footer={[null]}
        maskTransitionName=""
        onCancel={this.handleCancel}
        width={600}
        okButtonProps={{ disabled: true }}
        cancelButtonProps={{ disabled: false }}
      >

        <br />
        <div class="row">



          <div class="col-sm-12 mobile-inputs">
            {this.state.load}
            <Notification
              message={{
                type: this.state.type,
                info: this.state.info,
              }}
            />

            <form method="post" onSubmit={this.onPressReviewPrescription}>

              <div class="form-group row">
                <div class="col-sm-12">
                <Zoom>
                  <img
                    alt="prescription preview"
                    // src="http://159.65.199.43/img/prescription/img_1658100296.png"
                    src={this.state.prescriptionImage}
                    width="550"
                    height="400"
                  />
                </Zoom>
                </div>
              </div>

              <div class="form-group row">
              <div class="col-sm-12">
                  <Select placeholder="Select Prescripiton State" style={{ width: '100%' }} onChange={this.prescriptionStateSelect}>
                    <Option value="" disabled="true">Select Order State</Option>
                    <Option value="1">Approve Order</Option>
                    <Option value="0">Deny Order</Option>
                  </Select>

                </div>

                {/* <div class="col-sm-6">
                  <Select placeholder="Select Review Comment" style={{ width: '100%' }} disabled={this.state.approveState} onChange={this.handleChangeComment}>
                    <Option value="" disabled="true">Select Review Comment</Option>
                    <Option value="Image Not Clear please take another. try cleaning the camera, go to a place with more light.">Not clear</Option>
                    <Option value="does not have accepted features like a stamp by the doctor and signature, please follow our guidelines to avoid incoviniences">Not a professional prescription</Option>
                  </Select>
                </div> */}

              </div>
              <div class="row">
                <div class="col-sm-6 card-header-right" >
                  <button type="submit" class="btn btn-primary btn-block mb-2">Submit Order</button>
                </div>
              </div>
            </form>
          </div>
        </div>

      </Modal>
      <Viewer
      visible={visible}
      noNavbar={true}
      noToolbar={false}
      changeable={false}
      attribute={false}
      scalable={false}
      onClose={() => { this.setVisible(false); } }
      images={[{src:"http://159.65.199.43/img/prescription/img_1658099795.png", alt: ''}]}
      container={document.getElementById("container")}/>
      </>

    )
  }
}

export default ReviewPrescriptionToAcceptOrder