import React, { Component } from 'react'
import Preloader from '../common/Preloader'
import { Table, Tooltip } from 'antd'
import NewHeader from '../common/NewHeader'
import moment from 'moment'
import SideNav from '../common/SideNav'
import NewFooter from '../common/NewFooter'
import ajax from '../utils/ajax';
import functions from '../utils/functions'
import RescheduleAppointment from '../common/Modals/RescheduleAppointment'
import SweetAlert from 'react-bootstrap-sweetalert'


class Pending extends Component {

    state = {
        userId: functions.sessionGuard(),
        roleList:functions.roleGuard(),
        sweetAlert: false,
        reschedule: false,
        prefDateEdit: "",
        prefTimeIdentity: "",
        sweetAlertConfirm:false,
        appointmentId: "",
        serviceId:"",
        hospitalId:"",
        specialistId:"",
        clientId:"",
        fullhospitalName:"",
        fullhospitalId:""
    }
        
    confirmAppointment = (key) => {
        this.setState({
            appointmentId:key,
            sweetAlert: true
        })
    }

    recievedStateReschedule = (data) => {
        this.setState({ reschedule: data })
    }

    onCancelConfirm = () => {
        this.setState({
            sweetAlert: false,
            confirmError: "Patient Apppointment will be scheduled !",
        })
    }

    okAcceptance = (key) => {
        window.location.reload()
    }

    getUser = async () => {
        const server_response = await ajax.userInfo(this.state.userId);
        if (server_response.status === "OK") {
            this.setState({
                fullhospitalName: this.state.roleList === "RECEP"?server_response.details.content.user_facility +" || Appointments Pending Acceptance":"All easyCare || Appointments Pending Acceptance",
                fullhospitalId:server_response.details.content.facility_id
            })
            this.getPeindingAppointmentList();

        } else {
            //console.log(server_response)
        }
    }

    onClickReAppointment = (preId, preDate,service,hospital,specailist,client,appointment) => {
        this.setState({
            reschedule: true,
            prefTimeIdentity: preId,
            prefDateEdit: preDate,
            serviceId:service,
            hospitalId:hospital,
            specialistId:specailist,
            clientId:client,
            appointmentId:appointment

        })
    }

    onConfirmAppointment = async () => {

        const { appointmentId } = this.state

        if (appointmentId.length > 0) {

            const server_response = await ajax.acceptClientAppointment(appointmentId)

            if (server_response.status === "OK") {
                this.setState({
                    sweetAlert: false,
                    sweetAlertConfirm:true,
                    confirmSuccess: server_response.details.message,
                })
            
            } else {
                this.setState({
                    confirmError: server_response.details.message
                })
            }

        } else {

            this.setState({
                confirmError: "#####  Please select appointment to process  #####"
            })

        }

    }

    componentDidMount() {
        this.getUser();
    }


    getPeindingAppointmentList = async () => {
        const server_response = await ajax.listPeindingAppointmentsByHospital(this.state.roleList === "RECEP"?this.state.fullhospitalId:"");
        const tableData = []


        if (server_response.status === "OK") {

            //console.log("ioioio",server_response.details.content)

            server_response.details.content.map((appointment) =>
                tableData.push({
                    hospitalName: appointment.appointment_hospital.full_hospital_name,
                    hospitalId:appointment.appointment_hospital.hospital_id,
                    serviceName: appointment.appointment_service.service_name,
                    serviceId:appointment.appointment_service.service_id,
                    clientName: appointment.appointment_client.full_name, 
                    clientId: appointment.appointment_client.client_id, 
                    clientPhone: appointment.appointment_client.phone_number,
                    appointmentSpecialistId: appointment.appointment_specialist,
                    prefDate: appointment.appointment_date.short_date,
                    prefTime: appointment.appointment_time.appointment_time,
                    prefTimeId: appointment.appointment_time.id,
                    appointmentId: appointment.appointment_id,
                    when:appointment.when_dates.period,
                    appointmentStatus: appointment.appointment_accept_status,
                    attendStatus : appointment.appointment_attend_status

                }),
            )
            this.setState({
                appointmentsList: tableData
            })

        } else {
            // this.setState({
            //     categoryList: "404"
            // })
        }
    }

    render() {
        const roleList = functions.roleGuard()
        const permissionList = functions.permissionGuard()
        const columns = [
            {
                title: 'Hospital / Clinic',
                dataIndex: 'hospitalName',
                hidden: this.state.roleList === "RECEP"?true:false 
            },
            {
                title: 'Client Name',
                dataIndex: 'clientName',
            },
            {
                title: 'ClientIds',
                dataIndex: 'clientId',
                hidden:true
            },
            {
                title: 'Hospital / Clinic id',
                dataIndex: 'hospitalId',
                hidden: true
            },
            {
                title: 'Service Name',
                dataIndex: 'serviceName',
            },
            {
                title: 'ServiceId',
                dataIndex: 'serviceId',
                hidden: true
            },
            {
                title: 'appointmentSpecialist',
                dataIndex: 'appointmentSpecialistId',
                hidden: true
            },
            {
                title: 'Client Phone',
                dataIndex: 'clientPhone',
                 hidden: true
            },
            {
                title: 'Preffered Date',
                dataIndex: 'prefDate',
                 hidden: false
            },
            {
                title: 'Preffered Time',
                dataIndex: 'prefTime',
                 hidden: false
            },
            {
                title: 'Preffered Time id',
                dataIndex: 'prefTimeId',
                hidden: true
            },
            {
                title: 'When',
                dataIndex: 'when',
            },
            {
                title: 'Status',
                dataIndex: 'appointmentStatus',
            },
            {
                title: 'Attend Status',
                dataIndex: 'attendStatus',
            },
            {
                title: 'appointmentId',
                dataIndex: 'appointmentId',
                hidden: true
            },
            {
                title: 'Actions - [ Approve , Re-Schedule ]',
                dataIndex: 'actioned',

                className: 'bg-transparent text-center',
                render: (text, records, index) => {
                    return (
                        <div className="text-nowrap">
                            <Tooltip
                                placement="leftTop"
                                title="Confirm Appointment"
                                color="bg-green"
                                key="#f50"
                                style={{ margin: "10px" }}
                            >
                                <button
                                    type="button"
                                    className="btn btn-inverse-light"
                                    onClick={() => this.confirmAppointment(records.appointmentId)}
                                    style={{ marginInlineEnd: "10px" }}

                                >
                                    <i className="fa fa-check" />
                                </button>
                            </Tooltip>

                            <Tooltip
                                placement="leftTop"
                                title="Edit Appointment"
                                color="bg-green"
                                key="#f50"
                                style={{ margin: "10px" }}
                            >
                                <button
                                    type="button"
                                    className="btn btn-inverse-light"
                                    onClick={() => this.onClickReAppointment(records.prefTimeId, records.prefDate,records.serviceId,records.hospitalId,records.appointmentSpecialistId,records.clientId,records.appointmentId)}

                                >
                                    <i className="fa fa-edit" />
                                </button>
                            </Tooltip>

                        </div>
                    )
                }
            },

        ].filter(item => !item.hidden)
        return (

            <body>
                {/* {roleList === "RECEP" && */}
                <>
                <div class="app">
                    {/* <!-- begin app-wrap --> */}
                    <div class="app-wrap">

                            <Preloader />

                            <NewHeader />

                        <div class="app-container">

                            <SideNav />

                            {/* <!-- begin app-main --> */}
                            <div class="app-main" id="main">
                                {/* <!-- begin container-fluid --> */}
                                <div class="container-fluid">  

                                    {/* <!-- begin row --> */}
                                    <div class="row">

                                        <div class="col-md-12 m-b-30">

                                            <div class="d-block d-lg-flex flex-nowrap align-items-center">
                                                <div class="page-title mr-4 pr-4 border-right">
                                                <h1>{this.state.fullhospitalName}</h1>
                                                </div>
                                            </div>

                                        </div>

                                    </div> 

                                    <div class="row">
                                        <div class="col-sm-12">

                                            {/* <!-- begin row --> */}
                                            <div class="row">
                                                <div class="col-lg-12">
                                                    <div class="card card-statistics">
                                                        <div class="card-body">

                                                            <div class="datatable-wrapper table-responsive">

                                                                <Table
                                                                    columns={columns}
                                                                    bordered
                                                                    // onChange={this.onchange}
                                                                    dataSource={this.state.appointmentsList}
                                                                    size="small"
                                                                    rowClassName = {(record,index)=>(record.appointmentStatus === "Pending" ? "badge-info-inverse":"badge-info-inverse")}
                                                                    pagination={{ pageSizeOptions: ['10'], showTitle: false, hideOnSinglePage: false }}/>


                                                                {this.state.reschedule ? (
                                                                    <RescheduleAppointment
                                                                        visible={{ visible: true, prefTimeId: this.state.prefTimeIdentity, prefDate: this.state.prefDateEdit,hospitalId:this.state.hospitalId,serviceId:this.state.serviceId,specialistId:this.state.specialistId,client:this.state.clientId,appointment:this.state.appointmentId }}
                                                                        handleCancel={this.recievedStateReschedule}
                                                                    />
                                                                ) : null}

                                                                {this.state.sweetAlert ? (

                                                                    <SweetAlert
                                                                        warning
                                                                        showCancel
                                                                        confirmBtnText="Yes, confirm!"
                                                                        confirmBtnBsStyle="success"
                                                                        title="Confirm Appointment?"
                                                                        cancelBtnBsStyle="secondary"
                                                                        onConfirm={this.onConfirmAppointment}
                                                                        onCancel={this.onCancelConfirm}
                                                                        focusCancelBtn
                                                                    >
                                                                        {this.state.confirmError}
                                                                    </SweetAlert>

                                                                ) : null}

                                                                {this.state.sweetAlertConfirm ? (

                                                                    <SweetAlert
                                                                        success
                                                                        title={"Appointment Accepted !"}
                                                                        onConfirm={this.okAcceptance}
                                                                        timeout={5000}
                                                                    >
                                                                        {this.state.confirmSuccess} !
                                                                    </SweetAlert>

                                                                ) : null}

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <!-- end row --> */}

                                        </div>
                                    </div>
                                    {/* <!-- end row --> */}

                                </div>
                                {/* <!-- end container-fluid --> */}
                            </div>
                            {/* <!-- end app-main --> */}
                        </div>
                        {/* <!-- end app-container --> */}
                        {/* <!-- begin footer --> */}
                        <NewFooter />
                        {/* <!-- end footer --> */}
                    </div>
                    {/* <!-- end app-wrap --> */}
                </div>
                </>
                {/* } */}
            </body>

        )
    }
}
export default Pending