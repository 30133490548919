import React, { Component } from 'react'
import { Modal, Select } from 'antd'
import moment from 'moment';
import 'antd/dist/antd.css'; // or 'antd/dist/antd.less'
import Notification from '../Notification'
import PropagateLoader from '../SubmitingForm'
import ajax from '../../utils/ajax';
import functions from '../../utils/functions';


const { Option } = Select;

class UpdateHospitalServices extends Component {

  state = {
    userId: functions.sessionGuard(),
    load: '',
    hospitalId:"",
    serviceList: false,
    // servicesNew:false,
    hospitalSelectServices: false
  }

  componentDidMount() {
    this.setState({
      hospitalId: this.props.visible.hospitalId
    })
    this.getServices()
  }

  selectedService = () => {
    const seletedSer = []
    this.props.visible.services.map((service) =>
      seletedSer.push(service.service_id),
    )
    this.setState({
      hospitalSelectServices: seletedSer
    })
  }

  handleCancel = () => {
    const sent = this.props
    this.setState({ visible: false })
    sent.handleCancel(false)
  }

  getServices = async () => {
    const server_response = await ajax.listServices();
    const serOptions = []

    if (server_response.status === "OK") {
      this.selectedService()
      server_response.details.content.map((service) =>
        serOptions.push(<Option value={service.service_id}>{service.service_name}</Option>
        ),
      )
      this.setState({
        serviceList: serOptions
      })

    } else {
      //
    }
  }

  onPressUpdateHospitalServices = async (event) => {
    event.preventDefault()
    const { hospitalId, hospitalSelectServices } = this.state

    //console.log("jkjkjkjkjk ",this.state.hospitalSelectServices)

    if (hospitalId.length > 0 &&
      hospitalSelectServices.length > 0 ) {

      this.setState({
        type: '',
        info: '',
        load: <PropagateLoader />
      })
      
      const server_response = await ajax.updateSelectHospitalServices(this.state.hospitalId,hospitalSelectServices)

      if (server_response.status === "OK") {
        this.setState({
          type: 'alert alert-success',
          info: server_response.details.message,
          load: '',
        })
        window.location.reload()

      } else {

        this.setState({
          type: 'alert alert-danger',
          info: server_response.details.message,
          load: '',
        })

      }

    } else {
      this.setState({
        type: 'alert alert-danger',
        info: 'Please be advised to fill in all required fields - All fields are required',
        load: '',
      })
    }


  }

  hospitalServices = (value) => {
    console.log("new service",value)
    this.setState({ 
      // hospitalServices: value,
      hospitalSelectServices:value })
  }

  render() {

    return (

      <Modal
        title={"UPDATE HOSPITAL SERVICES "}
        centered
        bodyStyle={{ paddingBottom: '0px', paddingTop: '0px' }}
        visible={this.props.visible.visible}
        maskClosable={false}
        footer={[null]}
        maskTransitionName=""
        onCancel={this.handleCancel}
        width={700}
        okButtonProps={{ disabled: true }}
        cancelButtonProps={{ disabled: false }}
      >

        <br />
        <div class="row">

          <div class="col-sm-12 mobile-inputs">
            {this.state.load}
            <Notification
              message={{
                type: this.state.type,
                info: this.state.info,
              }}
            />
            <br />

            <form method="post" onSubmit={this.onPressUpdateHospitalServices}>

              <div class="form-group">

                <Select
                  mode="multiple"
                  name="hospitalServices"
                  style={{ width: '100%' }}
                  value={this.state.hospitalSelectServices}
                  key={this.state.hospitalSelectServices}
                  placeholder="Select Services Offered"
                  onChange={this.hospitalServices}>
                  {this.state.serviceList}

                </Select>
              </div>
              <div class="row">
                <div class="col-sm-4 card-header-right" >
                  <button type="submit" class="btn btn-primary btn-block mb-3">Update Services</button>
                </div>
              </div>
            </form>
          </div>
        </div>



      </Modal>

    )
  }
}

export default UpdateHospitalServices