import React, { Component } from 'react'
import ajax from '../../utils/ajax'
import functions from '../../utils/functions'
import { decode as base64_decode, encode as base64_encode } from 'base-64'
import Preloader from '../../common/Preloader'
import NewHeader from '../../common/NewHeader'
import UpdateHospitalServices from '../../common/Modals/UpdateHospitalServices'
import UpdateHospitalPaymentMethods from '../../common/Modals/UpdateHospitalPaymentMethods'
import SideNav from '../../common/SideNav'
import NewFooter from '../../common/NewFooter'


class NewHospitalDetails extends Component {


    state = {
        userId: functions.sessionGuard(),
        dialog: false,
        hospitalId: "",
        edit: false,
        profileEdit: true,
        hospitalCategory:"",
        hospitalFullNameL:"",
        hospialPhone:"",
        hospitalEmail:"",
        hospitalAddress:"",
        hospitalHours:"",
        hospitalDays:"",
        profilePhoto:"",
        updateSers:false,
        updatePays:false,
        hospitalServices:[],
        hospitalPayments:[]

    }

    componentDidMount() {
        if (!this.props.match.params) {
            this.props.history.push('/clinics-hospitals')
        } else {
              this.setState(
                {
                    hospitalId: this.props.match.params.hospital,
                },
                () => {
                  this.getHospitalDetails()
                }
              )
              //this.getHospitalDetails()
        }
    }

    recievedState = (data) => {
        this.setState({ updateSers: data })
    }

    recievedStatePays = (data) => {
        this.setState({ updatePays: data })
    }

    updateServices = () => {
        this.setState({
            updateSers: true,
        })
    }

    updatePayment = () => {
        this.setState({
            updatePays: true,
        })
    }

    // AddUser = ()=>{
    //     this.setState({
    //         dialog:true
    //     })
    // }

    showEdit = (data) => {
        this.setState({
            // edit:true,
            profileEdit: data
        })
    }

    getHospitalDetails = async () => {
        const {hospitalId} = this.state
        const server_response = await ajax.getHospitalDetails(this.state.hospitalId)
         //console.log("jkjkjkjjkjkjkjkjk"+hospitalId)
        if (server_response.status === 'OK') {
          this.setState({
            hospitalCategory:server_response.details.content.hospital_category.category_name,
            hospitalFullName:server_response.details.content.full_hospital_name,
            hospialPhone:server_response.details.content.phone_number,
            hospitalEmail:server_response.details.content.email,
            hospitalAddress:server_response.details.content.hospital_address,
            hospitalHours:server_response.details.content.hospital_hours,
            hospitalDays:server_response.details.content.hospital_days,
            profilePhoto:server_response.details.content.profile_photo.file_name,
            hospitalServices:server_response.details.content.services_offered,
            hospitalPayments:server_response.details.content.hospital_insurance

          })
        } else {
          this.setState({
            userList: '404',
          })
        }
    }

    render() {
        const hospitalServicesed = this.state.hospitalServices 
        const hospitalPaymentMethod = this.state.hospitalPayments
        return (
            <body>
                {/* <!-- begin app --> */}
                <div class="app">
                    {/* <!-- begin app-wrap --> */}
                    <div class="app-wrap">
                        {/* <!-- begin pre-loader --> */}
                        <Preloader/>
                        <NewHeader/>
                        <div class="app-container">
                            <SideNav/>
                            {/* <!-- begin app-main --> */}
                            <div class="app-main" id="main">
                                {/* <!-- begin container-fluid --> */}
                                <div class="container-fluid">
                                    {/* <!-- begin row --> */}
                                    <div class="row">
                                        <div class="col-md-12 m-b-30">
                                            {/* <!-- begin page title --> */}
                                            <div class="d-block d-sm-flex flex-nowrap align-items-center">
                                                <div class="page-title mb-2 mb-sm-0">
                                                    <h1>Hospital Details </h1>
                                                </div>
                                                <div class="ml-auto d-flex align-items-center">
                                                    <nav>
                                                        <ol class="breadcrumb p-0 m-b-0">
                                                            <li class="breadcrumb-item">
                                                                <a href="index.html"><i class="ti ti-home"></i></a>
                                                            </li>
                                                            <li class="breadcrumb-item">
                                                            <a href="/clinics-hospitals">Hospitals</a>
                                                            </li>
                                                            <li class="breadcrumb-item active text-primary" aria-current="page">Hospital Details</li>
                                                        </ol>
                                                    </nav>
                                                </div>
                                            </div>
                                            {/* <!-- end page title --> */}
                                        </div>
                                    </div>
                                    {/* <!-- end row --> */}

                                    {/* <!--mail-Compose-contant-start--> */}
                                    <div class="row account-contant">
                                        <div class="col-12">
                                            <div class="card card-statistics">
                                                <div class="card-body p-0">
                                                    <div class="row no-gutters">
                                                        <div class="col-xl-3 pb-xl-0 pb-5 border-right">
                                                            <div class="page-account-profil pt-5">
                                                                <div class="profile-img text-center rounded-circle">
                                                                    <div class="pt-5">
                                                                        <div class="bg-img m-auto">
                                                                            <img src={this.state.profilePhoto} class="img-fluid" alt="users-avatar"/>
                                                                        </div>
                                                                        <div class="profile pt-4">
                                                                            <h6 class="mb-1">{this.state.hospitalFullName}</h6>
                                                                            <p>{this.state.hospitalCategory}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div class="py-5 profile-counter">
                                                                    <ul class="nav justify-content-center text-center">
                                                                        <li class="nav-item border-right px-3">
                                                                            <div>
                                                                                <h4 class="mb-0">90</h4>
                                                                                <p>Post</p>
                                                                            </div>
                                                                        </li>

                                                                        <li class="nav-item border-right px-3">
                                                                            <div>
                                                                                <h4 class="mb-0">1.5K</h4>
                                                                                <p>Messages</p>
                                                                            </div>
                                                                        </li>

                                                                        <li class="nav-item px-3">
                                                                            <div>
                                                                                <h4 class="mb-0">4.4K</h4>
                                                                                <p>Members</p>
                                                                            </div>
                                                                        </li>
                                                                    </ul>
                                                                </div>

                                                                <div class="profile-btn text-center">
                                                                    <div><button class="btn btn-light text-primary mb-2">Upload New Avatar</button></div>
                                                                    <div><button class="btn btn-danger">Delete</button></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-xl-5 col-md-6 col-12 border-t border-right">
                                                            <div class="page-account-form">
                                                                <div class="form-titel border-bottom p-3">
                                                                    <h4 class="mb-0 py-2">{this.state.hospitalFullName} Details</h4>
                                                                </div>
                                                                <div class="p-4">
                                                                    <form>
                                                                        <div class="form-row">
                                                                            <div class="form-group col-md-12">
                                                                                <label for="name1">Hospital / Clinic Name</label>
                                                                                <input type="text" class="form-control" id="name1" value={this.state.hospitalFullName}/>
                                                                            </div>
                                                                            <div class="form-group col-md-12">
                                                                                <label for="title1">Hospital / Clinic Category</label>
                                                                                <input type="text" class="form-control" id="title1" value={this.state.hospitalCategory}/>
                                                                            </div>

                                                                            <div class="form-group col-md-12">
                                                                                <label for="title1">Hospital / Clinic Working Days</label>
                                                                                <input type="text" class="form-control" id="title1" value={this.state.hospitalDays}/>
                                                                            </div>

                                                                            <div class="form-group col-md-12">
                                                                                <label for="title1">Hospital / Clinic Working Hours</label>
                                                                                <input type="text" class="form-control" id="title1" value={this.state.hospitalHours}/>
                                                                            </div>
                                                                            
                                                                            <div class="form-group col-md-12">
                                                                                <label for="phone1">Hospital / Clinic Phone</label>
                                                                                <input type="text" class="form-control" id="phone1" value={this.state.hospialPhone}/>
                                                                            </div>
                                                                            <div class="form-group col-md-12">
                                                                                <label for="email1">Hospital / Clinic Email</label>
                                                                                <input type="email" class="form-control" id="email1" value={this.state.hospitalEmail}/>
                                                                            </div>
                                                                        </div>
                                                                        <div class="form-group">
                                                                            <label for="add1">Hospital / Clinic Address</label>
                                                                            <input type="text" class="form-control" id="add1" value={this.state.hospitalAddress}/>
                                                                        </div>
                                                                        <button type="button" style={{ marginInlineEnd: "6px" }} class="btn btn-primary">Update Information</button>
                                                                        <button type="button" onClick={this.updateServices} style={{ marginInlineEnd: "6px" }} class="btn btn-primary">Update services</button>
                                                                        <button type="button" onClick = {this.updatePayment} class="btn btn-primary">Update Payment Method</button>
                                                                    </form>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-xl-4 col-md-6 border-t col-12">
                                                            <div class="page-account-form">
                                                                <div class="form-titel border-bottom p-3">
                                                                    <h4 class="mb-0 py-2">{this.state.hospitalFullName} Services</h4>
                                                                </div>
                                                                <div class="p-4">
                                                                    <form>
                                                                    <div class="form-row">
                                                                        
                                                                        {hospitalServicesed && hospitalServicesed !== '404' && hospitalServicesed.map((item, key) => (
                                                                                <h4><span class="mr-2 mb-2 mr-sm-0 mb-sm-0 badge badge-info" style={{margin:"3px"}}>{item.service_name}</span></h4>
                                                                            ))
                                                                        }
                                                                        </div>
                                                                    </form>
                                                                </div>
                                                            </div>

                                                            <div class="page-account-form">
                                                                <div class="form-titel border-top border-bottom p-3">
                                                                    <h4 class="mb-0 py-2">{this.state.hospitalFullName} Accepted Payment</h4>
                                                                </div>
                                                                <div class="p-4">
                                                                    <form>
                                                                    <div class="form-row">
                                                                        
                                                                        {hospitalPaymentMethod && hospitalPaymentMethod !== '404' && hospitalPaymentMethod.map((item, key) => (
                                                                                <h4><span class="mr-2 mb-2 mr-sm-0 mb-sm-0 badge badge-info" style={{margin:"3px"}}>{item.payment_method_name}</span></h4>
                                                                            ))
                                                                        }
                                                                        </div>
                                                                    </form>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {this.state.updateSers ? (
                                        <UpdateHospitalServices
                                            visible={{ visible: true, hospitalId: this.state.hospitalId, services:this.state.hospitalServices  }}
                                            handleCancel={this.recievedState}
                                        />
                                    ) : null}

                                    {this.state.updatePays ? (
                                        <UpdateHospitalPaymentMethods
                                            visible={{ visible: true,hospitalId: this.state.hospitalId, payment:hospitalPaymentMethod }}
                                            handleCancel={this.recievedStatePays}
                                        />
                                    ) : null}

                                    {/* <!--mail-Compose-contant-end--> */}
                                </div>
                                {/* <!-- end container-fluid --> */}
                            </div>
                            {/* <!-- end app-main --> */}
                        </div>
                        {/* <!-- end app-container --> */}
                        <NewFooter/>
                    </div>
                    {/* <!-- end app-wrap --> */}
                </div>
                {/* <!-- end app --> */}

            </body>
        )
    }
}
export default NewHospitalDetails

