import React, { Component } from 'react'

class Preloader extends Component {

        render() {
          return (
            <div class="loader">
                <div class="h-100 d-flex justify-content-center">
                    <div class="align-self-center">
                        <img src="asset/img/loader/loader.svg" alt="loader"/>
                    </div>
                </div>
            </div>
          )
        }
}
export default Preloader