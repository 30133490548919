import React, { Component } from 'react'
import { Modal, Select, Table, DatePicker } from 'antd'
import moment from 'moment';
import 'antd/dist/antd.css'; // or 'antd/dist/antd.less'
import Notification from '../Notification'
import PropagateLoader from '../SubmitingForm'
import ajax from '../../utils/ajax';
import functions from '../../utils/functions';
import Viewer from 'react-viewer';

import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'


const { Option } = Select;

class OrderParkaging extends Component {

  state = {
    userId: functions.sessionGuard(),
    appointmentTimeList: false,
    load: '',
    visible:false,
    billAmount:""

  }

  componentDidMount() {
    this.setState({
      prescriptionImage: this.props.visible.presImage 
    })
  }

  handleCancel = () => {
    const sent = this.props
    this.setState({ visible: false })
    sent.handleCancel(false)
  }
  setVisible = ()=>{
    this.setState({
      visible:true, setVisible:true
    })
  }

  onPressBillOrder = async (event) => {
    event.preventDefault()
    //this.props.visible.orderId + "# "+this.props.visible.clientId
    const {billAmount} = this.state

    if (this.props.visible.orderId.length>0  ) {

      this.setState({
        type: '',
        info: '',
        load: <PropagateLoader />
      })

      const server_response = await ajax.parkageOrderAndDischarge(this.props.visible.orderId,this.state.userId)

      if (server_response.status === "OK") {
        this.setState({
          type: 'alert alert-success',
          info: server_response.details.message,
          load: '',
        })
        window.location.reload()

      } else {

        this.setState({
          type: 'alert alert-danger',
          info: server_response.details.message,
          load: '',
        })

      }

    } else {
      this.setState({
        type: 'alert alert-danger',
        info: 'Please be advised to fill in all required fields - All fields are required',
        load: '',
      })
    }


  }

  render() {
    const {visible, setVisible } = this.state
    return (
      <>
      <Modal
        title={"VIEW PRESCRIPTION IMAGE AND PARCKAGE"}
        centered
        bodyStyle={{ paddingBottom: '0px', paddingTop: '0px' }}
        visible={this.props.visible.visible}
        maskClosable={false}
        footer={[null]}
        maskTransitionName=""
        onCancel={this.handleCancel}
        width={600}
        okButtonProps={{ disabled: true }}
        cancelButtonProps={{ disabled: false }}
      >

        <br />
        <div class="row">
          <div class="col-sm-12 mobile-inputs">
            {this.state.load}
            <Notification
              message={{
                type: this.state.type,
                info: this.state.info,
              }}
            />
            <br/>

            <form method="post" onSubmit={this.onPressBillOrder}>

              <div class="form-group row">
                <div class="col-sm-12">
                <Zoom>
                  <img
                    alt="prescription preview"
                    // src="http://159.65.199.43/img/prescription/img_1658100296.png"
                    src={this.state.prescriptionImage}
                    width="550"
                    height="400"
                  />
                </Zoom>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-6 card-header-right" >
                  <button type="submit" class="btn btn-primary btn-block mb-2">PARK AND DISPATCH</button>
                </div>
              </div>
            </form>
          </div>
        </div>

      </Modal>
      <Viewer
      visible={visible}
      noNavbar={true}
      noToolbar={false}
      changeable={false}
      attribute={false}
      scalable={false}
      onClose={() => { this.setVisible(false); } }
      images={[{src:"http://159.65.199.43/img/prescription/img_1658099795.png", alt: ''}]}
      container={document.getElementById("container")}/>
      </>

    )
  }
}

export default OrderParkaging