import React, { Component } from 'react'
// import Head from '../../common/Head'
// import Foot from '../common/Foot'
import Nav from '../../common/SideNavigation'
import ResultModal from '../../common/Modals/AddSystemUser'


class ManageUsers extends Component {



    state = {
        dialog: false
    }

    recievedState = (data) => {
        this.setState({ dialog: data })
    }

    AddUser = () => {
        this.setState({
            dialog: true
        })
    }

    render() {
        return (

            <body>

                <div class="theme-loader">
                    <div class="ball-scale">
                        <div class='contain'>
                            <div class="ring">
                                <div class="frame"></div>
                            </div>
                            <div class="ring">
                                <div class="frame"></div>
                            </div>
                            <div class="ring">
                                <div class="frame"></div>
                            </div>
                            <div class="ring">
                                <div class="frame"></div>
                            </div>
                            <div class="ring">
                                <div class="frame"></div>
                            </div>
                            <div class="ring">
                                <div class="frame"></div>
                            </div>
                            <div class="ring">
                                <div class="frame"></div>
                            </div>
                            <div class="ring">
                                <div class="frame"></div>
                            </div>
                            <div class="ring">
                                <div class="frame"></div>
                            </div>
                            <div class="ring">
                                <div class="frame"></div>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="pcoded" class="pcoded">
                    <div class="pcoded-overlay-box"></div>
                    <div class="pcoded-container navbar-wrapper">
                        {/* <Head/> */}

                        <div class="pcoded-main-container">
                            <div class="pcoded-wrapper">
                                <Nav />
                                <div class="pcoded-content">
                                    <div class="pcoded-inner-content">

                                        <div class="main-body">
                                            <div class="page-wrapper">

                                                <div class="page-header">
                                                    <div class="row align-items-end">
                                                        <div class="col-lg-8">
                                                            <div class="page-header-title">
                                                                <div class="d-inline">
                                                                    <h4>ALL SYSTEM USER(s) </h4>
                                                                    <span>ADD / EDIT / MANAGE</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-4">
                                                            <div class="page-header-breadcrumb">
                                                                <ul class="breadcrumb-title">
                                                                    <li class="breadcrumb-item">
                                                                        <a href="index.html"> <i class="feather icon-home"></i> </a>
                                                                    </li>
                                                                    <li class="breadcrumb-item"><a href="/">System Users</a> </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="page-body">
                                                    <div class="row">
                                                        <div class="col-sm-12">
                                                            <div class="card">
                                                                <div class="card-header">

                                                                    <div class="card-header-left">
                                                                        <ul class="list-unstyled card-option">
                                                                            <li><button onClick={this.AddUser} class="btn btn-mat btn-primary "><i class="icofont icofont-users"></i> Add System User (s)</button></li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                                <div class="card-block">

                                                                    {this.state.dialog ? (
                                                                        <ResultModal
                                                                            visible={{ visible: true }}
                                                                            handleCancel={this.recievedState}
                                                                        />
                                                                    ) : null}

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div id="styleSelector">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </body>

        )
    }
}
export default ManageUsers