import React, { Component } from 'react'
import { Table, Tooltip } from 'antd'
import { Link } from 'react-router-dom'
import Preloader from '../../common/Preloader'
import NewHeader from '../../common/NewHeader'
import SideNav from '../../common/SideNav'
import ResultModal from '../../common/Modals/AddClinicHospital'
import NewFooter from '../../common/NewFooter'
import ajax from '../../utils/ajax';
import functions from '../../utils/functions'


class NewHospitals extends Component {

    state = {
        userId: functions.sessionGuard(),
        dialog: false,
        isActive: false,
        hospitalsList: false,
        hosId: ""
    };

    componentDidMount() {
        this.getAllHospitals();
    }

    onClickRegister = () => {
      this.setState({ dialog: true })
    }

    recievedState = (data) => {
      this.setState({ dialog: data })
    }

    getAllHospitals = async () => {
        const server_response = await ajax.listHospitals();
        const tableData = []

        if (server_response.status === "OK") {
            // console.log(server_response.details.content.list)
            server_response.details.content.list.map((hospitals) =>
                tableData.push({
                    hospitalName: hospitals.full_hospital_name,
                    hospitalCat: hospitals.hospital_category.category_name,
                    hospitalHours: hospitals.hospital_hours,
                    hospitalEmail: hospitals.email,
                    hospitalPhone: hospitals.phone_number,

                    userId: hospitals.created_by.first_name + " " + hospitals.created_by.last_name,
                    created: hospitals.created_at.short_date,
                    actioned: hospitals.hospital_id,
                }),
            )

            this.setState({
                hospitalsList: tableData
            })

        } else {
            //   this.setState({
            //     hospitalsList: "404"
            //   })
        }
    }

    render() {

        const columns = [
            {
                title: 'Hospital Name',
                dataIndex: 'hospitalName',
            },
            {
                title: 'Hospital Category',
                dataIndex: 'hospitalCat',
            },
            {
                title: 'Working Hours',
                dataIndex: 'hospitalHours',
            },
            {
                title: 'Hospital Email',
                dataIndex: 'hospitalEmail',
            },
            {
                title: 'Telephone',
                dataIndex: 'hospitalPhone',
            },
            {
                title: 'Added By ',
                dataIndex: 'userId',
            },
            {
                title: 'Created On',
                dataIndex: 'created',
            },
            {
                title: 'Actions - [ View ]',
                dataIndex: 'actioned',

                className: 'bg-transparent text-right',
                render: (text, records, index) => {
                    return (
                        <div className="text-nowrap">
                            <Tooltip
                                placement="leftTop"
                                title="View Hospital Details "
                                color="#454B1B"
                                key="#f50"
                                style={{ marginRight: "10px" }}
                            >
                                <button
                                    type="button"
                                    className="btn btn-light text-red"
                                    onClick={() => this.setState({ hosId: text })}
                                >

                                    <Link
                                        to={{
                                            pathname: `/hospital-details/${text}`,
                                            state: { hospitalID: text },
                                        }}>
                                        View  <i className="fa fa-eye" />
                                    </Link>
                                </button>

                            </Tooltip>

                        </div>
                    )
                }
            },

        ]
        return (

            <body>
                {/* <!-- begin app --> */}
                <div class="app">
                    {/* <!-- begin app-wrap --> */}
                    <div class="app-wrap">
                        {/* <!-- begin pre-loader --> */}
                        <Preloader />
                        <NewHeader />
                        <div class="app-container">
                            <SideNav />
                            {/* <!-- begin app-main --> */}
                            <div class="app-main" id="main">
                                {/* <!-- begin container-fluid --> */}
                                <div class="container-fluid">
                                    {/* <!-- begin row --> */}
                                    <div class="row">
                                        <div class="col-md-12 m-b-30">
                                            {/* <!-- begin page title --> */}
                                            <div class="d-block d-sm-flex flex-nowrap align-items-center">
                                                <div class="page-title mb-2 mb-sm-0">
                                                    <h1>All Clinics / Hospitals</h1>
                                                </div>
                                                <div class="ml-auto d-flex align-items-center">
                                                    <nav>
                                                        <ol class="breadcrumb p-0 m-b-0">
                                                            <li class="breadcrumb-item">
                                                                <a href="index.html"><i class="ti ti-home"></i></a>
                                                            </li>
                                                            <li class="breadcrumb-item">
                                                                System Settings
                                                            </li>
                                                            <li class="breadcrumb-item active text-primary" aria-current="page">All Clinics / Hospitals</li>
                                                        </ol>
                                                    </nav>
                                                </div>
                                            </div>
                                            {/* <!-- end page title --> */}
                                        </div>
                                    </div>
                                    {/* <!-- end row --> */}
                                    {/* <!-- begin row --> */}
                                    <div class="row">
                                        <div class="col-lg-12">
                                            <div class="card card-statistics">
                                                <div class="card-body">

                                                    <div class="my-3">
                                                        <button type="button" onClick={this.onClickRegister} class="btn btn-primary">Add Hospital / Clinic</button>
                                                    </div>

                                                    <div class="datatable-wrapper table-responsive">

                                                        <Table
                                                            columns={columns}
                                                            bordered
                                                            // onChange={this.onchange}
                                                            dataSource={this.state.hospitalsList}
                                                            size="small"
                                                            pagination={{ pageSizeOptions: ['10'], showTitle: false, hideOnSinglePage: false }}

                                                        />

                                                        {this.state.dialog ? (
                                                        <ResultModal
                                                            visible={{ visible: true }}
                                                            handleCancel={this.recievedState}
                                                        />
                                                        ) : null}  

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <!-- end row --> */}
                                </div>
                                {/* <!-- end container-fluid --> */}
                            </div>
                            {/* <!-- end app-main --> */}
                        </div>
                        {/* <!-- end app-container --> */}
                        <NewFooter />
                    </div>
                    {/* <!-- end app-wrap --> */}
                </div>
                {/* <!-- end app --> */}
            </body>

        )
    }
}
export default NewHospitals