import React, { Component } from 'react'
import { Modal, Select, Table } from 'antd'
import 'antd/dist/antd.css'; // or 'antd/dist/antd.less'

import Notification from '../../common/Notification'
import PropagateLoader from '../../common/SubmitingForm'
import ajax from '../../utils/ajax'
import functions from '../../utils/functions'


const { Option } = Select;

class AddHospitalService extends Component {

  state = {
    userId: functions.sessionGuard(),
    serviceName:"",
    serviceCode:"",
    load:"",
    serviceCategory:"",
    specalityCategoryList:false
  }

  componentDidMount(){
    this.getSpecialityCatgories();
  }


  handleCancel = () => {
    const sent = this.props
    this.setState({ visible: false })
    sent.handleCancel(false)
  }

  handleChange = (value)=> {
    console.log(`selected ${value}`);
  }

  getSpecialityCatgories = async ()=>{
    const server_response = await ajax.listSpecialityCategories();
    const speOptions = []

    if (server_response.status === "OK") {

        server_response.details.content.map((specialityCategory) =>
          speOptions.push(<Option value={specialityCategory.category_id}>{specialityCategory.category_name}</Option>
          ),
        )
        this.setState({
            specalityCategoryList: speOptions
        })
        
    } else {
        // this.setState({
        //     categoryList: "404"
        // })
    }
  }

  serviceName = (value)=> {
    this.setState({ serviceName: value.target.value })
  }
  serviceCode = (value)=> {
    this.setState({ serviceCode: value.target.value })
  }

  serviceCategory = (value)=> {
    
    if(value != null){
      this.setState({
        serviceCategory:value
    })
    }else{
      this.setState({
        serviceCategory:""
    })
    }

  }

  onPressAddService= async (event)=>{
    event.preventDefault()
    const { serviceName,serviceCode,serviceCategory} = this.state

    if (serviceName.length > 0 && 
      serviceCode.length >0 ) {
        this.setState({
          type: '',
          info: '',
          load:<PropagateLoader/>
        })

          //fetch category from the API
          const server_response = await ajax.createService(serviceName, serviceCode,serviceCategory,this.state.userId)

          if (server_response.status === "OK") {
            this.setState({
              type: 'alert alert-success',
              info: server_response.details.message,
              load: '',
            })
            window.location.reload()

        } else {

          this.setState({
            type: 'alert alert-danger',
            info: server_response.details.message,
            load: '',
          })

        }


      }else{
        this.setState({
          type: 'alert alert-danger',
          info: 'Please be advised to fill in all required fields - All fields are required',
          load:'',
        })
      }
  }

  render() {
    return (

        <Modal
        title = {"ADD HOSPITAL SERVICE"}
        centered
        bodyStyle={{ paddingBottom: '0px', paddingTop: '0px' }}
        visible={this.props.visible.visible}
        maskClosable={false}
        footer={[null]}
        maskTransitionName=""
        onCancel={this.handleCancel}
        width={700}
        okButtonProps={{ disabled: true }}
        cancelButtonProps={{ disabled: false }}
      >

<br/>
<div class="row">

<div class="col-sm-12 mobile-inputs">

{this.state.load}
  <Notification
        message={{
          type: this.state.type,
          info: this.state.info,
        }}
    />

<form method="post" onSubmit={this.onPressAddService}>

<div class="form-group row">
<div class="col-sm-6">
<input type="text" onChange={this.serviceName} class="form-control form-txt-primary" placeholder="Service Name"/>
</div>
<div class="col-sm-6">
<Select  placeholder="Select Service Category" style={{ width: '100%'}} onChange={this.serviceCategory}>
  <option>Select Service Category</option>
  {this.state.specalityCategoryList}
</Select>
</div>
</div>

<div class="form-group row">
<div class="col-sm-6">
<input type="text" onChange={this.serviceCode} class="form-control form-txt-primary" placeholder="Service Code"/>
</div>
</div>



<div class="form-group row">
<div class="col-sm-6">
</div>

</div>
<div class="row">
<div class="col-sm-3 card-header-right" >
<button type="submit" class="btn btn-primary btn-block mb-3">Add Service</button>
 </div>
 </div>
</form>
</div>
</div>


     
      </Modal>

    )}}

    export default AddHospitalService