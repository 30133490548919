import React, { Component } from 'react'

class NewFooter extends Component {

  render() {
    return (
        <div>
            {/* <!-- begin footer --> */}
            <footer class="footer">
                <div class="row">
                    <div class="col-12 col-sm-6 text-center text-sm-left">
                        <p>&copy; Copyright 2022. All rights reserved.</p>
                    </div>
                   <div class="col  col-sm-6 ml-sm-auto text-center text-sm-right">
                        <p><a href="equiva.co.ug"></a>easyCare</p>
                    </div>
                </div>
            </footer>
            {/* <!-- end footer --> */}
        </div>
    )
  }
  
}
export default NewFooter