import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import functions from '../../utils/functions'

class NewNotFound extends Component {

    render() {
        const roleList = functions.roleGuard()
        return (

            <body>
                {/* <!-- begin app --> */}
                <div class="app">
                    {/* <!-- begin app-wrap --> */}
                    <div class="app-wrap">
                        {/* <!-- begin pre-loader --> */}
                        <div class="loader">
                            <div class="h-100 d-flex justify-content-center">
                                <div class="align-self-center">
                                    <img src="asset/img/loader/loader.svg" alt="loader" />
                                </div>
                            </div>
                        </div>
                        {/* <!-- end pre-loader --> */}

                        {/* <!--start error-404-contant--> */}
                        <div class="error-contant">
                            <div class="error-innr">
                                <div class="container">
                                    <div class="row justify-content-center align-items-center">
                                        <div class="col-md-8 text-center">
                                            <div class="error-text">
                                                <svg enable-background="new 0 0 398.1 226.6" version="1.1" viewBox="0 0 398.1 226.6" xmlspace="preserve" xmlns="http://www.w3.org/2000/svg">



                                                    <path class="st0" d="m45.6 67.8c-17.6 5.7-38 14.6-41.3 32.8-2.1 11.9 4.8 24.3 15.2 30.4 5.1 3 11.3 5.1 14 10.4 4.1 8.2-3.3 17.5-11.1 22.3s-17.4 8.5-20.9 17c-2.3 5.6-1.4 12 0.9 17.6 8.5 20.4 33.4 29.4 55.5 28.3s43.1-9.7 64.7-14.1c74.4-15.2 152.5 19.3 226.8 3.5 11.6-2.5 23.2-6.3 32.1-14.1 10.1-8.7 15.8-21.9 16.6-35.2 0.8-12.7-3.7-27-15-32.7-6.6-3.3-14.6-3.2-21.8-1.4-4.6 1.2-9.1 3-13.9 3-4.7 0-10-2.3-11.3-6.9-1.4-4.8 1.8-9.7 5.6-13 7.7-6.6 18-9.9 24.9-17.3 6.7-7.1 9.5-17.7 7.3-27.1-2.3-9.5-9.6-17.6-18.8-20.9-23.5-8.3-49 14.4-73 7.7-1.8-0.5-3.7-1.3-4.6-2.8-0.9-1.6-0.7-3.5-0.2-5.3 2.9-9.7 14.3-15.8 14.8-25.9 0.3-5.5-3-10.6-7.1-14.3-28.1-25.2-68.7 4.8-97.3 14.2-47 15.6-94.9 28.6-142.1 43.8z" />



                                                    <path class="st1" d="m148.9 141.9v0l8.5-5.1-3.8-6.5-8.5 5-22.4-38-8.9 5.3-4.2 55.1 3.2 5.4 28.4-16.8 9.8 16.6 7.8-4.6-9.9-16.4zm-31.7 9.9-0.1-0.2 2.7-29.6-0.3-12 0.3-0.2 5.4 9.7 12.1 20.4-20.1 11.9z" />




                                                    <path class="st1" d="m302.4 114.4-8.8-5.4-50.7 22-3.3 5.4 28.1 17.3-10.1 16.5 7.7 4.7 10.1-16.5 8.5 5.2 3.9-6.4-8.5-5.2 23.1-37.6zm-30.8 32.8-19.8-12.2 0.1-0.2 27.4-11.5 10.5-5.8 0.3 0.2-6.1 9.3-12.4 20.2z" />




                                                    <circle class="st2" cx="199.1" cy="126.6" r="34.5" />
                                                    <path class="st3" d="m164.9 122.3c10.2 1.5 27.4-5.8 32.9-6.9 11.2-2.2 22.7 0.4 34.1 0.8-0.8-2.5-1.9-4.9-3.2-7.1-4.2 0.2-8.6-0.6-12.9-0.7-8-0.2-16 1.9-24.1 1.4-3.7-0.2-12.1-3.5-19.7-4.8-3.7 4.9-6.3 10.8-7.1 17.3z" />
                                                    <path class="st3" d="m199.1 92.1c-3.5 0-6.9 0.5-10.1 1.5 4.3 2.5 13 3.3 15 3.6 3.6 0.5 7.5 0.7 10.6-1.3l0.1-0.1c-4.7-2.3-10-3.7-15.6-3.7z" />
                                                    <path class="st3" d="m233.5 127.1c-9.9-2.5-20.4-2.7-30.5-0.5-5.4 1.2-10.7 3.1-16.1 4.4-6.8 1.7-13.8 2.5-20.8 2.4h-0.9c1.4 6.9 4.8 13 9.6 17.8 8.3-2.9 16.2-6.8 23.6-11.4 6.5-4.1 13-8.9 20.6-9.6 4.7-0.4 9.4 0.8 14.1 1.8 0.3-1.7 0.4-3.3 0.4-4.9z" />
                                                    <path class="st3" d="m231.7 137.7c-4.7-0.7-13 3.4-15.1 5.2-1.8 1.5-3.4 3.4-5.3 4.8-2.5 1.7-5.5 2.5-8.5 3.2-5.9 1.5-11.8 3.2-17.7 5.1-0.7 0.2-1.5 0.6-1.9 1.2 4.7 2.5 10.1 3.9 15.8 3.9 4 0 7.8-0.7 11.4-1.9 3.6-5.1 5.7-11.7 11.4-14.1 1-0.4 4.3-0.9 7.4-1.7 1-1.9 1.8-3.7 2.5-5.7z" />

                                                    <path class="st4" d="m302 229.1c-8.6-45.8-48.8-80.5-97.1-80.5s-88.5 34.7-97.1 80.5h194.2z" />

                                                    <ellipse class="st5" transform="matrix(.9914 .1307 -.1307 .9914 28.441 -25.947)" cx="211.9" cy="203.7" rx="11.2" ry="6.3" />

                                                    <circle class="st6" cx="132.9" cy="67.9" r="12.8" />
                                                    <path class="st7" d="m132.9 55.1c-2.3 0-4.4 0.6-6.2 1.6 0.1 0.2 0.2 0.3 0.3 0.4 1.3 1.3 4.4 1.4 6.1 1.4 2.5 0.1 5-0.2 7.4-1-2.1-1.5-4.7-2.4-7.6-2.4z" />
                                                    <path class="st7" d="m142.7 61.9c0.4-0.1 0.8-0.3 1.2-0.5-0.3-0.6-0.7-1.1-1.1-1.6-1.3 0.6-2.5 1.1-3.8 1.7-1 0.4-2 0.9-3 1.2-1.5 0.4-3.2 0.4-4.7 0.4-3.3 0-6.5-0.3-9.8-0.7-0.8 1.7-1.2 3.5-1.2 5.5 0 0.9 0.1 1.8 0.3 2.7 0.7-0.3 1.3-0.7 1.9-1.1 1.6-1 3.5-1.7 5.3-2.3 4.8-1.8 9.9-3.5 14.9-5.3z" />
                                                    <path class="st7" d="m144.6 62.8c-1 0.9-1.7 2.1-2.8 2.9-1.1 0.9-2.4 1.6-3.7 2.1-0.9 0.4-1.9 0.6-2.8 1.1-0.3 0.2-0.7 0.4-0.8 0.8-0.1 0.7 0.6 1.1 1.3 1.3 1.3 0.3 2.6 0.2 4 0.2 1.6-0.1 3.1-0.1 4.7-0.2 0.3 0 0.6 0 0.8-0.1 0.2-1 0.4-2 0.4-3.1 0-1.7-0.4-3.4-1.1-5z" />
                                                    <path class="st7" d="m133.3 67.5c-2.3 0.5-4.5 1.2-6.6 2.1-1.4 0.6-2.8 1.4-4.2 1.9-0.4 0.1-1 0.2-1.7 0.2 0.8 2.4 2.2 4.4 4 6 0.1-0.2 0.3-0.3 0.4-0.5 1.9-2.5 3.2-5.7 6-7.3 0.5-0.3 1-0.5 1.5-0.9 0.4-0.3 0.7-0.9 0.6-1.5z" />
                                                    <path class="st7" d="m144.4 73.3h-0.4c-2.7 0.2-5.4 0.3-8.2 0.5-1.7 0.1-3.5 0.2-4.9 1.2-1.1 0.7-1.8 1.9-2.5 3-0.2 0.4-0.7 0.9-1 1.5 1.6 0.8 3.3 1.2 5.2 1.2 0.3-0.4 0.6-0.9 1-1.3 1.8-2.4 4.7-4 7.7-4.3 0.8-0.1 1.6-0.1 2.4-0.2 0.2-0.6 0.5-1.1 0.7-1.6z" />
                                                    <path class="st8" d="m144.6 72.9c0.3-0.8 0.6-1.6 0.8-2.4-3.3 1.2-7.2 2.2-11.3 3-4.2 0.8-8.2 1.3-11.7 1.4 0.5 0.7 1 1.3 1.6 1.9 3.2-0.2 6.8-0.7 10.5-1.4 3.6-0.6 7.1-1.5 10.1-2.5z" />

                                                    <path class="st1" d="m120.4 76c-4.6 0-8.3-0.7-8.7-2.9-0.4-2.1 2.3-4.2 8-6.4 0.5-0.2 1.1 0.1 1.3 0.6s-0.1 1.1-0.6 1.3c-5.8 2.2-6.8 3.8-6.8 4.1 0.2 1.1 7.4 2.4 20.4-0.2 5.5-1.1 10.5-2.5 14.2-4.1 4.2-1.8 4.9-3.1 4.8-3.3-0.1-0.3-1.7-1.5-8.2-1.3-0.5 0-1-0.4-1-1s0.4-1 1-1c6.4-0.2 9.9 0.8 10.3 2.9 0.4 1.8-1.6 3.6-6 5.6-3.8 1.7-9 3.2-14.6 4.3-3.8 0.6-9.4 1.4-14.1 1.4z" />



                                                    <circle class="st9" cx="330.2" cy="91.6" r="10.3" />
                                                    <path class="st10" d="m335.1 82.6c-1.6-0.9-3.3-1.3-5-1.2 0 0.1 0 0.3 0.1 0.4 0.4 1.4 2.6 2.6 3.7 3.3 1.7 1 3.6 1.8 5.6 2.1-0.9-1.9-2.4-3.5-4.4-4.6z" />
                                                    <path class="st10" d="m339.4 91.1c0.4 0.1 0.7 0.1 1.1 0.1 0-0.5-0.1-1-0.2-1.6-1.1-0.1-2.2-0.2-3.4-0.3-0.9-0.1-1.7-0.1-2.6-0.3-1.2-0.3-2.4-0.9-3.5-1.5-2.3-1.3-4.5-2.7-6.7-4.3-1.2 0.9-2.2 2-3 3.4-0.4 0.7-0.6 1.3-0.8 2 0.6 0.1 1.2 0 1.7-0.1 1.5-0.1 3.1 0.1 4.6 0.4 4.4 0.9 8.6 1.6 12.8 2.2z" />
                                                    <path class="st10" d="m340.4 92.5c-1 0.2-2 0.8-3.1 1s-2.3 0.2-3.5 0.1c-0.8-0.1-1.6-0.3-2.4-0.3-0.3 0-0.6 0-0.8 0.3-0.4 0.4 0 1 0.4 1.4 0.8 0.7 1.8 1.2 2.7 1.7 1.1 0.6 2.3 1.1 3.4 1.7l0.6 0.3c0.6-0.6 1-1.3 1.5-2 0.7-1.5 1.1-2.8 1.2-4.2z" />
                                                    <path class="st10" d="m330.6 91.5c-1.8-0.5-3.6-0.9-5.5-1-1.2-0.1-2.5-0.1-3.7-0.3-0.3-0.1-0.8-0.3-1.3-0.5-0.4 2-0.2 4 0.6 5.8 0.2-0.1 0.3-0.1 0.5-0.2 2.3-1.1 4.5-2.8 7-2.9 0.5 0 0.9 0 1.4-0.1s0.9-0.4 1-0.8z" />
                                                    <path class="st10" d="m336.3 99.9c-0.1-0.1-0.2-0.1-0.3-0.2-2-0.9-4-1.9-6-2.8-1.2-0.6-2.5-1.2-3.9-1-1 0.1-2 0.6-2.9 1.2-0.3 0.2-0.8 0.4-1.3 0.6 0.8 1.1 1.9 2.1 3.2 2.9l1.2-0.6c2.2-1 4.8-1.1 7.1-0.1 0.6 0.2 1.2 0.6 1.8 0.8 0.3-0.3 0.7-0.6 1.1-0.8z" />

                                                    <path class="st4" d="m129.3 187.6-13.1 24.8-3.6-1.9c-1.9-1-2.7-3.4-1.6-5.3l9.4-17.8c1-1.9 3.4-2.7 5.3-1.6l3.6 1.8z" />
                                                    <path class="st4" d="m292.9 211.8-7.8-19.5 3.8-1.5c2-0.8 4.3 0.2 5.1 2.2l4.9 12.2c0.8 2-0.2 4.3-2.2 5.1l-3.8 1.5z" />
                                                    <path class="st4" d="m171.9 157.2-22.7 16.3-2.4-3.3c-1.3-1.8-0.9-4.2 0.9-5.5l16.3-11.7c1.8-1.3 4.2-0.9 5.5 0.9l2.4 3.3z" />
                                                    <path class="st4" d="m274.9 184.2-22.3-16.9 2.5-3.2c1.3-1.7 3.8-2.1 5.5-0.8l16.1 12.1c1.7 1.3 2.1 3.8 0.8 5.5l-2.6 3.3z" />


                                                    <ellipse class="st5" transform="matrix(.8917 -.4527 .4527 .8917 -64.764 91.567)" cx="158.9" cy="181.1" rx="15.7" ry="7" />
                                                    <path class="st11" d="m156.8 177.9c7-3.6 14-4.2 16.5-1.8 0.1-0.8 0-1.5-0.3-2.1-1.8-3.4-9.5-3-17.2 0.9-7.8 3.9-12.6 9.9-10.9 13.4 0.2 0.3 0.4 0.6 0.7 0.9 0.4-3.5 4.8-8.1 11.2-11.3z" />



                                                    <ellipse class="st5" transform="matrix(.8479 .5301 -.5301 .8479 134.93 -109.83)" cx="258.9" cy="180.2" rx="10.6" ry="4.7" />
                                                    <path class="st11" d="m260 177.9c4.5 2.8 7.3 6.6 6.7 8.9 0.5-0.2 0.9-0.5 1.1-0.9 1.4-2.2-1.5-6.5-6.5-9.6s-10.1-3.8-11.5-1.6c-0.1 0.2-0.2 0.5-0.3 0.7 2.3-1 6.4-0.1 10.5 2.5z" />

                                                    <path class="st11" d="m212.7 197.4c-6.2-0.8-11.5 1.3-12 4.8v0.8c1.3-2.8 6.2-4.4 11.6-3.7 5.7 0.8 10.1 3.8 10.3 7 0.2-0.4 0.3-0.7 0.3-1.1 0.6-3.5-4-7-10.2-7.8z" />
                                                    <circle class="st5" cx="59.7" cy="112" r="3.3" />
                                                    <circle class="st5" cx="248.6" cy="21.6" r="1.5" />
                                                    <circle class="st5" cx="269.2" cy="48.6" r="1.5" />
                                                    <circle class="st5" cx="25.7" cy="96.1" r="1.5" />
                                                    <circle class="st4" cx="232.3" cy="74.2" r="1.9" />
                                                    <circle class="st4" cx="311.2" cy="160.5" r="1.9" />
                                                    <circle class="st4" cx="366.6" cy="163.6" r="1.9" />
                                                    <circle class="st4" cx="71.2" cy="191" r="1.9" />
                                                    <circle class="st4" cx="179.6" cy="55.1" r="1.9" />
                                                    <polygon class="st4" points="158.7 100.7 161.2 102.1 158.6 103.5 157.2 106 155.9 103.4 153.4 102 155.9 100.7 157.3 98.2" />
                                                    <polygon class="st4" points="276.6 74.4 278.2 75.3 276.5 76.2 275.6 77.8 274.7 76.2 273.1 75.2 274.8 74.3 275.7 72.7" />
                                                    <polygon class="st4" points="83.7 152.8 85.4 153.7 83.7 154.6 82.8 156.2 81.9 154.6 80.2 153.6 81.9 152.8 82.9 151.1" />
                                                    <polygon class="st4" points="84.7 86.2 86.3 87.1 84.6 88 83.7 89.6 82.8 87.9 81.2 87 82.9 86.1 83.8 84.5" />
                                                    <polygon class="st4" points="34.7 185.6 36.3 186.5 34.7 187.4 33.7 189 32.8 187.4 31.2 186.4 32.9 185.6 33.8 183.9" />
                                                    <polygon class="st4" points="331.5 139.5 333.2 140.4 331.5 141.3 330.6 142.9 329.7 141.3 328.1 140.3 329.7 139.5 330.7 137.8" />
                                                    <polygon class="st4" points="351.7 205.3 353.3 206.2 351.7 207.1 350.7 208.7 349.8 207 348.2 206.1 349.9 205.2 350.8 203.6" />


                                                </svg>

                                                <h3 class="m-t-30">What on earth are you doing here!</h3>
                                                <p>Well this is awkward, the page you were trying to view does not exist.</p>
                                                {/* <Link class="btn btn-round btn-primary mt-3" to={roleList === "RECEP" ? "/equiva" : "/"}>Back to Home</Link> */}
                                                <a href="/" class="btn btn-round btn-primary mt-3">Back to Home</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!--end error-404-contant--> */}

                    </div>
                    {/* <!-- end app-wrap --> */}
                </div>
                {/* <!-- end app --> */}
            </body>

        )
    }
}
export default NewNotFound
