import React, { Component } from 'react'
import { Table, Tooltip } from 'antd'
import { Link } from 'react-router-dom'
import Preloader from '../../common/Preloader'
import NewHeader from '../../common/NewHeader'
import SweetAlert from 'react-bootstrap-sweetalert'
import SideNav from '../../common/SideNav'
import ResultModal from '../../common/Modals/AddHospitalService'
import EditModal from '../../common/Modals/EditHospitalService'
import NewFooter from '../../common/NewFooter'
import functions from '../../utils/functions'
import ajax from '../../utils/ajax'


class NewHospitalServices extends Component {

  state = {
    dialog: false,
    edit: false,
    sweetAlert: false,
    confirmAlert:false,
    serviceList: false,
    serviceName: "",
    serviceCode: "",
    serviceCat:"",
    serviceId: ""
  }

  componentDidMount() {
    this.getAllServices();
  }

  recievedState = (data) => {
    this.setState({ dialog: data })
  }

  recievedEditState = (data) => {
    this.setState({ edit: data })
  }

  onClickRegister = () => {
    this.setState({
      dialog: true
    })
  }

  editRow = (key) => {

    this.setState({
      edit: true, serviceName: key.serviceName,
      serviceCode: key.serviceCode, serviceId: key.actioned,
      serviceCat:key.serviceCatId
    })
  }

  deleteRow = (key) => {
    this.setState({ sweetAlert: true, serviceId: key })
  }

  onCancelDelet = () => {
    this.setState({ sweetAlert: false, serviceId: "" })
  }

  onConfirmed = () => {
    window.location.reload()
  }

  confirmDelete = async () => {

    const { serviceId } = this.state

    if (serviceId.length > 0) {

      const server_response = await ajax.deleteService(serviceId);

      if (server_response.status === "OK") {
        this.setState({
          sweetAlert: false,
          confirmAlert:true
        })
        

      } else {

        this.setState({
          sweetAlert: false,
          confirmAlert:false
        })

      }
    } else {
      console.log("Nothing to delete")
    }

  }

  getAllServices = async () => {
    const server_response = await ajax.listServices();
    const tableData = []

    if (server_response.status === "OK") {

      server_response.details.content.map((services) =>
        tableData.push({
          serviceName: services.service_name,
          serviceCode: services.service_code,
          userId: services.created_by.first_name + " " + services.created_by.last_name + " - " + services.created_by.user_role.role_name,
          updated: services.updated_on.long_date,
          serviceCat:services.service_category.category_name,
          serviceCatId:services.service_category.category_id,
          actioned: services.service_id,
        }),
      )

      this.setState({
        serviceList: tableData
      })

    } else {
      // this.setState({
      //     categoryList: "404"
      // })
    }
  }

  render() {

    const columns = [
      {
        title: 'Hospital Service Name',
        dataIndex: 'serviceName',
      },
      {
        title: 'Hospital Service Code',
        dataIndex: 'serviceCode',
      },
      {
        title: 'Hospital Category',
        dataIndex: 'serviceCat',
      },
      {
        title: 'Hospital Category Id',
        dataIndex: 'serviceCatId',
        hidden:true
      },
      {
        title: 'Added By ',
        dataIndex: 'userId',
      },
      {
        title: 'Upddated On',
        dataIndex: 'updated',
      },
      {
        title: 'Actions - [ Edit, Delete ]',
        dataIndex: 'actioned',

        className: 'bg-transparent text-right',
        render: (text, records, index) => {
          return (
            <div className="text-nowrap">
              <Tooltip
                placement="leftTop"
                title="Edit Category"
                color="#454B1B"
                key="#f50"
                style={{ marginRight: "10px" }}
              >
                <button
                  type="button"
                  className="btn btn-light text-red"
                  onClick={() => this.editRow(records)}

                >
                  <i className="fa fa-edit" />
                </button>
              </Tooltip>

              <Tooltip
                placement="leftTop"
                title="Delet Category"
                color="#f50"
                key="#f50"
              >
                <button
                  type="button"
                  className="btn btn-light text-red"
                  onClick={() => this.deleteRow(text)}
                >
                  <i className="fa fa-trash" />
                </button>
              </Tooltip>

            </div>
          )
        }
      },

    ].filter(item => !item.hidden)

    return (

      <body>
        {/* <!-- begin app --> */}
        <div class="app">
          {/* <!-- begin app-wrap --> */}
          <div class="app-wrap">
            {/* <!-- begin pre-loader --> */}
            <Preloader />
            <NewHeader />
            <div class="app-container">
              <SideNav />
              {/* <!-- begin app-main --> */}
              <div class="app-main" id="main">
                {/* <!-- begin container-fluid --> */}
                <div class="container-fluid">
                  {/* <!-- begin row --> */}
                  <div class="row">
                    <div class="col-md-12 m-b-30">
                      {/* <!-- begin page title --> */}
                      <div class="d-block d-sm-flex flex-nowrap align-items-center">
                        <div class="page-title mb-2 mb-sm-0">
                          <h1>Hospital Services </h1>
                        </div>
                        <div class="ml-auto d-flex align-items-center">
                          <nav>
                            <ol class="breadcrumb p-0 m-b-0">
                              <li class="breadcrumb-item">
                                <a href="index.html"><i class="ti ti-home"></i></a>
                              </li>
                              <li class="breadcrumb-item">
                                System Settings
                              </li>
                              <li class="breadcrumb-item active text-primary" aria-current="page">Hospital Services</li>
                            </ol>
                          </nav>
                        </div>
                      </div>
                      {/* <!-- end page title --> */}
                    </div>
                  </div>
                  {/* <!-- end row --> */}
                  {/* <!-- begin row --> */}
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="card card-statistics">
                        <div class="card-body">

                          <div class="my-3">
                            <button type="button" onClick={this.onClickRegister} class="btn btn-primary">Add Hospital Service</button>
                          </div>

                          <div class="datatable-wrapper table-responsive">

                            <Table
                              columns={columns}
                              bordered
                              // onChange={this.onchange}
                              dataSource={this.state.serviceList}
                              size="small"
                              pagination={{ pageSizeOptions: ['10'], showTitle: false, hideOnSinglePage: false }} />

                            {this.state.dialog ? (
                              <ResultModal
                                visible={{ visible: true }}
                                handleCancel={this.recievedState}
                              />
                            ) : null}

                            {this.state.edit ? (
                              <EditModal
                                visible={{
                                  visible: true, serviceName: this.state.serviceName,
                                  serviceCode: this.state.serviceCode,
                                  serviceId: this.state.serviceId,
                                  serviceCat:this.state.serviceCat
                                }}
                                handleEditCancel={this.recievedEditState}
                              />
                            ) : null}

                            {this.state.sweetAlert ? (

                              <SweetAlert
                                warning
                                showCancel
                                confirmBtnText="Yes, delete it!"
                                confirmBtnBsStyle={'danger'}
                                cancelBtnBsStyle="secondary"
                                title="Are you sure?"
                                onConfirm={this.confirmDelete}
                                onCancel={this.onCancelDelet}
                                focusCancelBtn
                              >
                                This action is cant be undone!
                              </SweetAlert>

                            ) : null}

                            {this.state.confirmAlert ? (
                            <SweetAlert
                              success
                              title="Deleted !"
                              onConfirm={this.onConfirmed}
                              timeout={2000}
                            >
                              Deleted Successfully !
                            </SweetAlert>
                            ) : null}

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <!-- end row --> */}
                </div>
                {/* <!-- end container-fluid --> */}
              </div>
              {/* <!-- end app-main --> */}
            </div>
            {/* <!-- end app-container --> */}
            <NewFooter />
          </div>
          {/* <!-- end app-wrap --> */}
        </div>
        {/* <!-- end app --> */}
      </body>

    )
  }
}
export default NewHospitalServices