import React, { Component } from 'react'
import { Modal, Select, Table, DatePicker } from 'antd'
import moment from 'moment';
import 'antd/dist/antd.css'; // or 'antd/dist/antd.less'
import Notification from '../Notification'
import PropagateLoader from '../SubmitingForm'
import ajax from '../../utils/ajax';
import functions from '../../utils/functions';


const { Option } = Select;

class RescheduleAppointment extends Component {

  state = {
    userId: functions.sessionGuard(),
    appointmentTimeList: false,
    rescheduleReasonList: false,
    selectedAppointmentDate: "",
    selectedSpecialist: "",
    prefTimeId: "",
    scheduleComment: "",
    appointmentId: "",
    load: ''
  }

  componentDidMount() {
    // console.log("ooooooooooooooooo",this.props.visible)
    this.setState({
      prefTimeId: this.props.visible.prefTimeId,
      selectedAppointmentDate: this.props.visible.prefDate,
      serviceId: this.props.visible.serviceId,
      hospitalId: this.props.visible.hospitalId,
      clientId: this.props.visible.client,
      selectedSpecialist: this.props.visible.specialistId,
      appointmentId: this.props.visible.appointment
    })

    this.getAppointmentTimes();
    this.fetchRescheduleReason();
    this.fetchServiceSpecialist(this.props.visible.serviceId, this.props.visible.hospitalId)
  }

  selectedDate = (_, value) => {
    //console.log("data",value)
    if (value != null) {
      this.setState({ selectedAppointmentDate: value })
    } else {
      this.setState({
        selectedAppointmentDate: ""
      })
    }
  }

  handleChangeDoctor = (value) => {
    if (value != null) {

    } else {
      //this.setState({
      //  hospital: "", hosptalServicesList: false,
      //})
    }
  }

  handleChangeTime = (value) => {
    //console.log("time ",value)
    if (value != null) {
      this.setState({ prefTimeId: value })
    } else {
      this.setState({
        prefTimeId: ""
      })
    }
  }

  handleChangeComment = (value) => {
    //console.log("comment ",value)
    if (value != null) {
      this.setState({ scheduleComment: value })
    } else {
      this.setState({
        scheduleComment: ""
      })
    }
  }

  preferedSpecialistSelect = (value) => {
    //console.log("specialist ",value)
    if (value != null) {
      this.setState({ selectedSpecialist: value })
    } else {
      this.setState({
        selectedSpecialist: ""
      })
    }
  }

  handleCancel = () => {
    const sent = this.props
    this.setState({ visible: false })
    sent.handleCancel(false)
  }

  handleChange = (value) => {
    // console.log(`selected ${value}`);
  }

  getAppointmentTimes = async () => {

    const server_response = await ajax.listAppointmentTimes();
    const appointmentOptions = []

    if (server_response.status === "OK") {

      server_response.details.content.map((appointmentTime) =>
        appointmentOptions.push(<Option value={appointmentTime.id}>{appointmentTime.appointment_time}</Option>
        ),
      )

      this.setState({
        appointmentTimeList: appointmentOptions
      })

    } else {
      // this.setState({
      //     categoryList: "404"
      // })
    }

  }

  fetchServiceSpecialist = async (serviceId, hospitalID) => {

    if (serviceId.length > 0 && hospitalID.length > 0) {

      const server_response = await ajax.listServiceSpecialist(serviceId, hospitalID);
      const speOptions = []

      if (server_response.status === "OK") {

        // console.log("hooops ",server_response.details.content)

        server_response.details.content.map((specialists) =>
          speOptions.push(<Option value={specialists.specialist_id}>{specialists.full_name}</Option>
          ),
        )

        this.setState({
          serviceSpecialist: speOptions
        })

      } else {
        // this.setState({
        //     categoryList: "404"
        // })
      }
    } else {
        //
    }

  }

  fetchRescheduleReason = async () => {


    const server_response = await ajax.rescheduleReasons();
    const speOptions = []

    if (server_response.status === "OK") {

      //console.log("hooopsffffffffffff ",server_response.details.content)

      server_response.details.content.map((reason) =>
        speOptions.push(<Option value={reason.id}>{reason.reschedule_reason}</Option>
        ),
      )

      this.setState({
        rescheduleReasonList: speOptions
      })

    } else {
      // this.setState({
      //     categoryList: "404"
      // })
    }

  }

  onPressRescheduleAppointment = async (event) => {
    event.preventDefault()
    const { selectedAppointmentDate, prefTimeId, scheduleComment, selectedSpecialist, serviceId, hospitalId, clientId, appointmentId } = this.state

    if (prefTimeId.length > 0 &&
      selectedAppointmentDate.length > 0 &&
      prefTimeId.length > 0 &&
      selectedSpecialist.length > 0 &&
      scheduleComment.length > 0) {

      this.setState({
        type: '',
        info: '',
        load: <PropagateLoader />
      })

      //console.log(selectedAppointmentDate+" date "+ prefTimeId+" time "+scheduleComment+" comment "+selectedSpecialist+" doc +appointmentId+" aappointment")

      const server_response = await ajax.rescheduleAppointment(selectedAppointmentDate, prefTimeId, scheduleComment, selectedSpecialist, appointmentId, this.state.userId)

      if (server_response.status === "OK") {
        this.setState({
          type: 'alert alert-success',
          info: server_response.details.message,
          load: '',
        })
        window.location.reload()

      } else {

        this.setState({
          type: 'alert alert-danger',
          info: server_response.details.message,
          load: '',
        })

      }

    } else {
      this.setState({
        type: 'alert alert-danger',
        info: 'Please be advised to fill in all required fields - All fields are required',
        load: '',
      })
    }


  }

  render() {

    return (

      <Modal
        title={"ADJUST APPOINTMENT DATE AND TIME "}
        centered
        bodyStyle={{ paddingBottom: '0px', paddingTop: '0px' }}
        visible={this.props.visible.visible}
        maskClosable={false}
        footer={[null]}
        maskTransitionName=""
        onCancel={this.handleCancel}
        width={700}
        okButtonProps={{ disabled: true }}
        cancelButtonProps={{ disabled: false }}
      >

        <br />
        <div class="row">

          <div class="col-sm-12 mobile-inputs">
            {this.state.load}
            <Notification
              message={{
                type: this.state.type,
                info: this.state.info,
              }}
            />
            <br />

            <form method="post" onSubmit={this.onPressRescheduleAppointment}>

              <div class="form-group row">

                <div class="col-sm-6">
                  <DatePicker style={{ width: '100%' }}
                    disabledDate={(current) => {
                      let customDate = moment().format("YYYY-MM-DD");
                      return current && current < moment(customDate, "YYYY-MM-DD");
                    }}
                    defaultValue={moment(this.props.visible.prefDate)}
                    onChange={this.selectedDate}
                  />


                </div>

                <div class="col-sm-6">

                  <Select placeholder="Select Prefered Time" style={{ width: '100%' }} value={this.state.prefTimeId} onChange={this.handleChangeTime}>
                    <Option value="">Select Prefered Time</Option>
                    {this.state.appointmentTimeList}
                  </Select>

                </div>
              </div>

              <div class="form-group row">
                <div class="col-sm-6">
                  <Select placeholder="Select Reschedule Comment" style={{ width: '100%' }} onChange={this.handleChangeComment}>
                    <Option value="">Select Reschedule Comment</Option>
                    {this.state.rescheduleReasonList}
                  </Select>
                </div>

                <div class="col-sm-6">
                  <Select placeholder="Select Preferred Doctor" value={this.props.visible.specialistId} style={{ width: '100%' }} onChange={this.preferedSpecialistSelect}>
                    <Option value="">Preferred Doctor</Option>
                    {this.state.serviceSpecialist}
                  </Select>

                </div>

              </div>
              <div class="row">
                <div class="col-sm-4 card-header-right" >
                  <button type="submit" class="btn btn-primary btn-block mb-3">Re-Schedule Appointment</button>
                </div>
              </div>
            </form>
          </div>
        </div>



      </Modal>

    )
  }
}

export default RescheduleAppointment