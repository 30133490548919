import React, { Component } from 'react'
import { Modal, Select } from 'antd'
import 'antd/dist/antd.css'; // or 'antd/dist/antd.less'
import ajax from '../../utils/ajax';
import PropagateLoader from '../../common/SubmitingForm'
import functions from '../../utils/functions';
import Notification from '../../common/Notification'

const { Option } = Select;

class EditSpeciality extends Component {
    state = {
        userId: functions.sessionGuard(),
        specalityCategoryList: false,
        specialityCategory: '',
        specialityCode: "",
        specialityId: "",
        load: "",
        specialityName: ""
    }

    componentDidMount() {
        this.getSpecialityCatgories();
        this.setState({
            specialityName: this.props.visible.specialityName,
            specialityCode: this.props.visible.specialityCode,
            specialityId: this.props.visible.specialityId,
            specialityCategory:this.props.visible.specialityCodeId
        })
    }

    handleEditCancel = () => {
        const sent = this.props
        this.setState({ visible: false })
        sent.handleEditCancel(false)
    }

    specialityCategory = (value) => {

        if (value != null) {
            this.setState({
                specialityCategory: value
            })
        } else {
            this.setState({
                specialityCategory: ""
            })
        }

    }

    specialityName = (value) => {
        this.setState({ specialityName: value.target.value })
    }

    specialityCode = (value) => {
        this.setState({ specialityCode: value.target.value })
    }

    getSpecialityCatgories = async () => {
        const server_response = await ajax.listSpecialityCategories();
        const speOptions = []

        if (server_response.status === "OK") {
            // console.log(server_response.details.content)

            server_response.details.content.map((specialityCategory) =>
                speOptions.push(<Option value={specialityCategory.category_id}>{specialityCategory.category_name}</Option>
                ),
            )
            this.setState({
                specalityCategoryList: speOptions
            })

        } else {
            // this.setState({
            //     categoryList: "404"
            // })
        }
    }

    onPressAddSpecialityCategory = async (event) => {
        event.preventDefault()

        const { specialityName, specialityCategory, specialityCode,specialityId } = this.state

    console.log("uyuyu  ",specialityName,specialityCategory,specialityCode,specialityId)

        if (specialityCategory.length > 0 &&
            specialityName.length > 0 &&
            specialityId.length > 0 &&
            specialityCode.length > 0) {
            this.setState({
                type: '',
                info: '',
                load: <PropagateLoader />
            })

            //fetch category from the API
            const server_response = await ajax.editSpeciality(specialityName, specialityCategory, specialityCode,specialityId, this.state.userId)

            if (server_response.status === "OK") {
                this.setState({
                    type: 'alert alert-success',
                    info: server_response.details.message,
                    load: '',
                })
                window.location.reload()

            } else {

                this.setState({
                    type: 'alert alert-danger',
                    info: server_response.details.message,
                    load: '',
                })

            }


        } else {
            this.setState({
                type: 'alert alert-danger',
                info: 'Please be advised to fill in all required fields - All fields are required',
                load: '',
            })
        }
    }

    render() {

        return (

            <Modal
                title={"EDIT PHYSICIAN SPECIALITY"}
                centered
                bodyStyle={{ paddingBottom: '0px', paddingTop: '0px' }}
                visible={this.props.visible.visible}

                maskClosable={false}
                footer={[null]}
                maskTransitionName=""
                onCancel={this.handleEditCancel}
                width={700}
                okButtonProps={{ disabled: true }}
                cancelButtonProps={{ disabled: false }}
            >

                <br />
                <div class="row">

                    <div class="col-sm-12 mobile-inputs">

                        {this.state.load}
                        <Notification
                            message={{
                                type: this.state.type,
                                info: this.state.info,
                            }}
                        />

                        <form method="post" onSubmit={this.onPressAddSpecialityCategory}>

                            <div class="form-group row">
                                <div class="col-sm-6">
                                    <input type="text" onChange={this.specialityName} value={this.state.specialityName} class="form-control form-txt-primary" placeholder="Speciality Name" />
                                </div>
                                <div class="col-sm-6">
                                    <Select placeholder="Select Specialiy Category" value={this.state.specialityCategory} style={{ width: '100%' }} onChange={this.specialityCategory}>
                                        <option>Select Specialiy Category</option>
                                        {this.state.specalityCategoryList}
                                    </Select>
                                </div>
                            </div>

                            <div class="form-group row">
                                <div class="col-sm-6">
                                    <input type="text" onChange={this.specialityCode} value={this.state.specialityCode} class="form-control form-txt-primary" placeholder="Speciality Code" />
                                </div>
                            </div>


                            <div class="form-group row">
                                <div class="col-sm-6">
                                    {/* <input type="text" class="form-control form-txt-primary" placeholder="Accepts Insurance"/> */}
                                </div>

                            </div>
                            <div class="row">
                                <div class="col-sm-3 card-header-right" >
                                    <button type="submit" class="btn btn-primary btn-block mb-3">Edit Speciality</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>



            </Modal>

        )
    }
}

export default EditSpeciality