import React, { Component } from 'react'
import Notification from '../../common/Notification'
import Loader from '../../common/Loader'
import ajax from '../../utils/ajax'
import functions from '../../utils/functions'

class UserAuth extends Component {

    state = {
        username: '',
        password: '',
        type: '',
        info: '',
        load: '',
    }

    onPressLogin = async (event) => {

        event.preventDefault()
        const { username, password } = this.state

        if (username.length > 0 && password.length > 0) {

            this.setState({
                type: '',
                info: '',
                load: <Loader />
            })
            //fetch login from the API
            const server_response = await ajax.loginUser(username, password)

            if (server_response.status === 'OK') {
                localStorage.setItem(
                    'equiva@user',
                    server_response.details.content.access_token,
                )

                // decode the token to access some variables for use
                const access_token = localStorage.getItem('equiva@user')
                const decorded_token = functions.parseJwt(access_token)

                const role_id = decorded_token['data']['role_id']
                const is_secure = decorded_token['data']['is_secure']

                if (role_id === '2' || role_id === '3' || role_id === '4') {
                    this.setState({
                        type: 'alert alert-danger',
                        info: 'You are not authorised to use this system',
                        load: '',
                    })
                } else if (is_secure === '0') {
                    this.props.history.push('/reset-password')
                    window.location.reload()
                } else if (server_response.status === 'OK') {

                    this.setState({
                        type: 'alert alert-success',
                        info: 'Credentials Accepted, Redirecting you . . ',
                        load: '',
                    })

                    this.props.history.push('/')
                    window.location.reload()
                }
            } else {
                this.setState({
                    type: 'alert alert-danger',
                    info: server_response.details.message,
                    load: '',
                })
            }
        } else {
            this.setState({
                type: 'alert alert-danger',
                info: 'Username and Password Required',
                load: '',
            })
        }


    }

    onChangeUsername = (event) => {
        this.setState({ username: event.target.value })
    }

    onChangePassword = (event) => {
        this.setState({ password: event.target.value })
    }

    render() {
        return (

            <body class="bg-white">
                <div class="app">
                    <div class="app-wrap">
                        {/* <div class="loader">
                            <div class="h-100 d-flex justify-content-center">
                                <div class="align-self-center">
                                    <img src="asset/img/loader/loader.svg" alt="loader" />
                                </div>
                            </div>
                        </div> */}
                        <div class="app-contant">

                            <div class="bg-white">
                                <div class="container-fluid p-0">
                                    <div class="row no-gutters">
                                        <div class="col-sm-4 align-self-center order-2 order-sm-1">
                                            <div class="d-flex align-items-center h-100-vh">
                                                <div class="login p-50">
                                                    {this.state.load}
                                                    <h1 class="mb-2 text-center"><strong>Sign In</strong></h1>
                                                    <p class="text-center">Welcome back, please login to continue.</p>

                                                    <Notification
                                                        message={{
                                                            type: this.state.type,
                                                            info: this.state.info,
                                                        }}
                                                    />
                                                    <form method="post" onSubmit={this.onPressLogin} class="mt-3 mt-sm-5">
                                                        <div class="row">
                                                            <div class="col-12">
                                                                <div class="form-group">
                                                                    <label class="control-label">User Name*</label>
                                                                    <input type="text" onChange={this.onChangeUsername} class="form-control" placeholder="Username" />
                                                                </div>
                                                            </div>
                                                            <div class="col-12">
                                                                <div class="form-group">
                                                                    <label class="control-label">Password*</label>
                                                                    <input type="password" name="password" onChange={this.onChangePassword} class="form-control" placeholder="Password" />
                                                                </div>
                                                            </div>
                                                            <div class="col-12">
                                                                <div class="d-block d-sm-flex  align-items-center">
                                                                    <a href="javascript:void(0);" class="ml-auto">Forgot Password ?</a>
                                                                </div>
                                                            </div>
                                                            <div class="col-12 mt-3">
                                                                <button type="submit" class="btn btn-primary text-uppercase">Sign in</button>
                                                            </div>
                                                            {/* <div class="col-12  mt-3">
                                                                <p>Don't have an account ?<a href="#"> Contact Adminstrator</a></p>
                                                            </div> */}
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-8 bg-gradient o-hidden order-1 order-sm-2">
                                            <div class="row align-items-center h-100">
                                                <div class="col-7 mx-auto ">
                                                    <img class="img-fluid" src="asset/img/bg/login.svg" alt="" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </body>

        )
    }
}
export default UserAuth