import React, { Component } from 'react'
import Preloader from '../../common/Preloader'
import { Table, Tooltip } from 'antd'
import NewHeader from '../../common/NewHeader'
import moment from 'moment'
import SideNav from '../../common/SideNav'
import NewFooter from '../../common/NewFooter'
import ajax from '../../utils/ajax';
import functions from '../../utils/functions'
import PropagateLoader from '../../common/SubmitingForm'
import RescheduleAppointment from '../../common/Modals/RescheduleAppointment'
import SweetAlert from 'react-bootstrap-sweetalert'


class Dashboard extends Component {

    state = {
        userId: functions.sessionGuard(),
        completed: "00",
        appointments: "00",
        missedApps: "00",
        pendingAccept: "00",
        sweetAlert: false,
        reschedule: false,
        workState: false,
        prefDateEdit: "",
        prefTimeIdentity: "",
        sweetAlertConfirm: false,
        appointmentId: "",
        serviceId: "",
        hospitalId: "",
        specialistId: "",
        clientId: "",
        fullhospitalName: "",
        fullhospitalId: "",
        load: "",
        loadError: "Please use the cancel button otherwise"
    }

    confirmAppointment = (key) => {
        this.setState({
            appointmentId: key,
            sweetAlert: true
        })
    }

    recievedStateReschedule = (data) => {
        this.setState({ reschedule: data })
    }

    onCancelConfirm = () => {
        this.setState({
            sweetAlert: false,
            confirmError: "Patient Apppointment will be scheduled !",
        })
    }

    onCancelAttend = () => {
        this.setState({
            load: "",
            workState: false,
            loadError: "Please use the cancel button otherwise"
        })
    }

    onMarkComplete = async () => {

        const { appointmentId } = this.state

        if (appointmentId.length > 0) {

            this.setState({
                load: <PropagateLoader />
            })

            const server_response = await ajax.markAppointmentComplete(appointmentId, this.state.userId)

            if (server_response.status === "OK") {

                this.setState({
                    workState: false,
                    sweetAlertConfirm: true,
                    confirmSuccess: server_response.details.message,
                })

            } else {
                //console.log("hjhjhjhjhjhjhjhjjh",server_response)
                this.setState({
                    load: "",
                    loadError: server_response.details.message
                })
            }

        } else {

            this.setState({
                workState: false,
                sweetAlertConfirm: true,
                confirmError: "#####  Please select appointment to process  #####"
            })

        }

    }

    onMarkMissed = async () => {

        const { appointmentId } = this.state

        if (appointmentId.length > 0) {

            this.setState({
                load: <PropagateLoader />
            })

            const server_response = await ajax.markAppointmentMissed(appointmentId, this.state.userId)

            if (server_response.status === "OK") {
                this.setState({
                    workState: false,
                    load: "",
                    sweetAlertConfirm: true,
                    confirmSuccess: server_response.details.message,
                })

            } else {
                //console.log("hjhjhjhjhjhjhjhjjh",server_response)
                this.setState({
                    load: "",
                    loadError: server_response.details.message
                })
            }

        } else {

            this.setState({
                load: "",
                confirmError: "#####  Please select appointment to process  #####"
            })

        }

    }

    okAcceptance = (key) => {
        window.location.reload()
    }

    getUser = async () => {
        const server_response = await ajax.userInfo(this.state.userId);
        if (server_response.status === "OK") {
            // console.log("uuiii",server_response)
            this.setState({
                fullhospitalName: server_response.details.content.user_facility + " || easyCare  Appointment",
                fullhospitalId: server_response.details.content.facility_id
            })

            this.countFinishedAppointments();
            this.countMissedAppointments();
            this.countAppointments();
            this.getAllAppointmentList();
            this.countNotAcceptanceAppointments();

        } else {
            //console.log(server_response)
        }
    }

    onClickReAppointment = (preId, preDate, service, hospital, specailist, client, appointment) => {
        this.setState({
            reschedule: true,
            prefTimeIdentity: preId,
            prefDateEdit: preDate,
            serviceId: service,
            hospitalId: hospital,
            specialistId: specailist,
            clientId: client,
            appointmentId: appointment

        })
    }

    onClickAttendence = (appointment) => {
        this.setState({
            workState: true,
            appointmentId: appointment
        })
    }


    onConfirmAppointment = async () => {

        const { appointmentId } = this.state

        if (appointmentId.length > 0) {

            const server_response = await ajax.acceptClientAppointment(appointmentId)

            if (server_response.status === "OK") {
                this.setState({
                    sweetAlert: false,
                    sweetAlertConfirm: true,
                    confirmSuccess: server_response.details.message,
                })

            } else {
                this.setState({
                    confirmError: server_response.details.message
                })
            }

        } else {

            this.setState({
                confirmError: "#####  Please select appointment to process  #####"
            })

        }

    }

    componentDidMount() {
        this.getUser();
    }

    countFinishedAppointments = async () => {

        const server_response = await ajax.countFinishedAppointment(this.state.fullhospitalId);

        if (server_response.status === "OK") {
            // console.log("uuiii",server_response)
            this.setState({
                completed: server_response.details.content.total_c
            })

        } else {
            // this.setState({
            //     categoryList: "404"
            // })
        }
    }

    countMissedAppointments = async () => {

        const server_response = await ajax.countMissedAppointment(this.state.fullhospitalId);

        if (server_response.status === "OK") {

            this.setState({
                missedApps: server_response.details.content.total_c
            })

        } else {
            // this.setState({
            //     categoryList: "404"
            // })
        }
    }

    countNotAcceptanceAppointments = async () => {

        const server_response = await ajax.countNotAcceptedAppointment(this.state.fullhospitalId);

        if (server_response.status === "OK") {

            this.setState({
                pendingAccept: server_response.details.content.total_c
            })

        } else {
            // this.setState({
            //     categoryList: "404"
            // })
        }
    }

    countAppointments = async () => {

        const server_response = await ajax.countAppointments(this.state.fullhospitalId);

        if (server_response.status === "OK") {

            this.setState({
                appointments: server_response.details.content.total_c
            })

        } else {
            // this.setState({
            //     categoryList: "404"
            // })
        }
    }

    getAllAppointmentList = async () => {
        const server_response = await ajax.listAllAppointmentsByHospital(this.state.fullhospitalId);
        const tableData = []


        if (server_response.status === "OK") {

            //console.log("ioioio",server_response.details.content)

            server_response.details.content.map((appointment) =>
                tableData.push({
                    hospitalName: appointment.appointment_hospital.full_hospital_name,
                    hospitalId: appointment.appointment_hospital.hospital_id,
                    serviceName: appointment.appointment_service.service_name,
                    serviceId: appointment.appointment_service.service_id,
                    clientName: appointment.appointment_client.full_name,
                    clientId: appointment.appointment_client.client_id,
                    clientPhone: appointment.appointment_client.phone_number,
                    appointmentSpecialistId: appointment.appointment_specialist,
                    prefDate: appointment.appointment_date.short_date,
                    prefTime: appointment.appointment_time.appointment_time,
                    prefTimeId: appointment.appointment_time.id,
                    appointmentId: appointment.appointment_id,
                    when: appointment.when_dates.period,
                    appointmentStatus: appointment.appointment_accept_status,
                    attendStatus: appointment.appointment_attend_status

                }),
            )
            this.setState({
                appointmentsList: tableData
            })

        } else {
            // this.setState({
            //     categoryList: "404"
            // })
        }
    }

    render() {
        const roleList = functions.roleGuard()
        const permissionList = functions.permissionGuard()
        const columns = [
            {
                title: 'Client Name',
                dataIndex: 'clientName',
            },
            {
                title: 'ClientIds',
                dataIndex: 'clientId',
                hidden: true
            },
            {
                title: 'Hospital / Clinic',
                dataIndex: 'hospitalName',
                hidden: true
            },
            {
                title: 'Hospital / Clinic id',
                dataIndex: 'hospitalId',
                hidden: true
            },
            {
                title: 'Service Name',
                dataIndex: 'serviceName',
            },
            {
                title: 'ServiceId',
                dataIndex: 'serviceId',
                hidden: true
            },
            {
                title: 'appointmentSpecialist',
                dataIndex: 'appointmentSpecialistId',
                hidden: true
            },
            {
                title: 'Client Phone',
                dataIndex: 'clientPhone',
                hidden: true
            },
            {
                title: 'Preffered Date',
                dataIndex: 'prefDate',
                hidden: false
            },
            {
                title: 'Preffered Time',
                dataIndex: 'prefTime',
                hidden: false
            },
            {
                title: 'Preffered Time id',
                dataIndex: 'prefTimeId',
                hidden: true
            },
            {
                title: 'When',
                dataIndex: 'when',
            },
            {
                title: 'Status',
                dataIndex: 'appointmentStatus',
            },
            {
                title: 'Attend Status',
                dataIndex: 'attendStatus',
            },
            {
                title: 'appointmentId',
                dataIndex: 'appointmentId',
                hidden: true
            },
            {
                title: 'Actions - [ Approve , Re-Schedule, Finish ]',
                dataIndex: 'actioned',

                className: 'text-center',
                render: (text, records, index) => {
                    return (
                        <div className="text-nowrap">
                            <Tooltip
                                placement="leftTop"
                                title="Confirm Appointment"
                                color="bg-green"
                                key="#f50"
                                style={{ margin: "10px" }}
                            >
                                <button
                                    type="button"
                                    className="btn btn-inverse-light"
                                    onClick={() => this.confirmAppointment(records.appointmentId)}
                                    style={{ marginInlineEnd: "10px" }}

                                >
                                    <i className="fa fa-check" />
                                </button>
                            </Tooltip>

                            <Tooltip
                                placement="leftTop"
                                title="Reschedule Appointment"
                                color="bg-green"
                                key="#f50"

                            >
                                <button
                                    type="button"
                                    style={{ marginInlineEnd: "10px" }}
                                    className="btn btn-inverse-light"
                                    onClick={() => this.onClickReAppointment(records.prefTimeId, records.prefDate, records.serviceId, records.hospitalId, records.appointmentSpecialistId, records.clientId, records.appointmentId)}

                                >
                                    <i className="fa fa-edit" />
                                </button>
                            </Tooltip>

                            <Tooltip
                                placement="leftTop"
                                title="Attendence"
                                color="bg-green"
                                key="#f50"
                            >
                                <button
                                    type="button"
                                    className="btn btn-inverse-light"
                                    onClick={() => this.onClickAttendence(records.appointmentId)}
                                >
                                    <i className="fa fa-list" />
                                </button>
                            </Tooltip>

                        </div>
                    )
                }
            },

        ].filter(item => !item.hidden)
        return (

            <body>
                {roleList === "RECEP" &&
                    <>
                        <div class="app">
                            {/* <!-- begin app-wrap --> */}
                            <div class="app-wrap">

                                <Preloader />

                                {/* {functions.findInPermission(permissionList, 'R-SESSION-02') && */}
                                <NewHeader />
                                {/* } */}

                                {/* <RenderSecure code='R-SESSION-02'> */}
                                {/* <NewHeader /> */}
                                {/* </RenderSecure> */}
                                {/* <!-- begin app-container --> */}
                                <div class="app-container">

                                    <SideNav />

                                    {/* <!-- begin app-main --> */}
                                    <div class="app-main" id="main">
                                        {/* <!-- begin container-fluid --> */}
                                        <div class="container-fluid">

                                            {/* <!-- begin row --> */}
                                            <div class="row">

                                                <div class="col-md-12 m-b-30">

                                                    <div class="d-block d-lg-flex flex-nowrap align-items-center">
                                                        <div class="page-title mr-4 pr-4 border-right">
                                                            <h1>{this.state.fullhospitalName}</h1>
                                                        </div>
                                                        {/* <div class="breadcrumb-bar align-items-center">
                                                </div>

                                                <div class="ml-auto d-flex align-items-center secondary-menu text-center">
                                                    <a href="javascript:void(0);" class="tooltip-wrapper" data-toggle="tooltip" data-placement="top" title="" data-original-title="Todo list">
                                                        <i class="fe fe-edit btn btn-icon text-primary"></i>
                                                    </a>
                                                    <a href="javascript:void(0);" class="tooltip-wrapper" data-toggle="tooltip" data-placement="top" title="" data-original-title="Projects">
                                                        <i class="fa fa-lightbulb-o btn btn-icon text-success"></i>
                                                    </a>
                                                    <a href="javascript:void(0);" class="tooltip-wrapper" data-toggle="tooltip" data-placement="top" title="" data-original-title="Task">
                                                        <i class="fa fa-check btn btn-icon text-warning"></i>
                                                    </a>
                                                    <a href="javascript:void(0);" class="tooltip-wrapper" data-toggle="tooltip" data-placement="top" title="" data-original-title="Calendar">
                                                        <i class="fa fa-calendar-o btn btn-icon text-cyan"></i>
                                                    </a>
                                                    <a href="javascript:void(0);" class="tooltip-wrapper" data-toggle="tooltip" data-placement="top" title="" data-original-title="Analytics">
                                                        <i class="fa fa-bar-chart-o btn btn-icon text-danger"></i>
                                                    </a>
                                                </div> */}
                                                    </div>

                                                </div>

                                            </div>

                                            <div class="row">
                                                <div class="col-xs-6 col-xxl-3 m-b-30">
                                                    <div class="card card-statistics h-100 m-b-0 bg-pink">
                                                        <div class="card-body">
                                                            <h2 class="text-white mb-0">{this.state.appointments}</h2>
                                                            <p class="text-white">Upcoming Appointments</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-xs-6 col-xxl-3 m-b-30">
                                                    <div class="card card-statistics h-100 m-b-0 bg-primary">
                                                        <div class="card-body">
                                                            <h2 class="text-white mb-0">{this.state.completed}</h2>
                                                            <p class="text-white">Completed Appointments </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-xs-6 col-xxl-3 m-b-30">
                                                    <div class="card card-statistics h-100 m-b-0 bg-orange">
                                                        <div class="card-body">
                                                            <h2 class="text-white mb-0">{this.state.missedApps}</h2>
                                                            <p class="text-white">Missed Appointments</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-xs-6 col-xxl-3 m-b-30">
                                                    <div class="card card-statistics h-100 m-b-0 bg-info">
                                                        <div class="card-body">
                                                            <h2 class="text-white mb-0">{this.state.pendingAccept}</h2>
                                                            <p class="text-white">Pending Acceptance</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="row">
                                                <div class="col-sm-12">

                                                    {/* <!-- begin row --> */}
                                                    <div class="row">
                                                        <div class="col-lg-12">
                                                            <div class="card card-statistics">
                                                                <div class="card-body">

                                                                    <div class="datatable-wrapper table-responsive">

                                                                        <Table
                                                                            columns={columns}
                                                                            bordered
                                                                            // onChange={this.onchange}
                                                                            dataSource={this.state.appointmentsList}
                                                                            size="small"
                                                                            pagination={{ pageSizeOptions: ['10'], showTitle: false, hideOnSinglePage: false }}
                                                                            rowClassName={(record, index) => (record.prefDate < moment().format('DD MMMM YYYY') ? "badge-danger-inverse" : ((record.prefDate === moment().format('DD MMMM YYYY')) ? ("badge-success-inverse") : (Math.ceil((new Date(record.prefDate) - new Date(new Date().toLocaleDateString())) / (1000 * 60 * 60 * 24) === 1) ? ("badge-warning-inverse") : (""))))} />

                                                                        {/* var variable = (condition) ? (true block) : ((condition2) ? (true block2) : (else block2)) */}


                                                                        {this.state.reschedule ? (
                                                                            <RescheduleAppointment
                                                                                visible={{ visible: true, prefTimeId: this.state.prefTimeIdentity, prefDate: this.state.prefDateEdit, hospitalId: this.state.hospitalId, serviceId: this.state.serviceId, specialistId: this.state.specialistId, client: this.state.clientId, appointment: this.state.appointmentId }}
                                                                                handleCancel={this.recievedStateReschedule}
                                                                            />
                                                                        ) : null}

                                                                        {this.state.sweetAlert ? (

                                                                            <SweetAlert
                                                                                warning
                                                                                showCancel
                                                                                confirmBtnText="Yes, confirm!"
                                                                                confirmBtnBsStyle="success"
                                                                                title="Confirm Appointment?"
                                                                                cancelBtnBsStyle="secondary"
                                                                                onConfirm={this.onConfirmAppointment}
                                                                                onCancel={this.onCancelConfirm}
                                                                                focusCancelBtn
                                                                            >
                                                                                {this.state.confirmError}
                                                                            </SweetAlert>

                                                                        ) : null}

                                                                        {this.state.sweetAlertConfirm ? (

                                                                            <SweetAlert
                                                                                success
                                                                                title={"Action Completeted !"} //Appointment Accepted 
                                                                                onConfirm={this.okAcceptance}
                                                                                timeout={5000}
                                                                            >
                                                                                {this.state.confirmSuccess} !
                                                                            </SweetAlert>

                                                                        ) : null}

                                                                        {this.state.workState ? (

                                                                            <SweetAlert
                                                                                custom
                                                                                showCancel
                                                                                customButtons={
                                                                                    <React.Fragment>
                                                                                        <button className="btn btn-inverse-danger" style={{ marginInlineEnd: "10px" }} onClick={this.onMarkMissed}>No - Mark as missed</button>
                                                                                        <button className="btn btn-inverse-success" onClick={this.onMarkComplete}>Yes - Mark as complete</button> <br /><br />
                                                                                        <button className="btn btn-warning" style={{ marginTop: "20px" }} onClick={this.onCancelAttend}>Cancel</button>
                                                                                    </React.Fragment>
                                                                                }
                                                                                customIcon="https://raw.githubusercontent.com/djorg83/react-bootstrap-sweetalert/master/demo/assets/thumbs-up.jpg"
                                                                                title="Did the patient attend their appointment?"

                                                                            >
                                                                                {this.state.loadError}<br />
                                                                                {this.state.load}
                                                                            </SweetAlert>

                                                                        ) : null}

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* <!-- end row --> */}

                                                </div>
                                            </div>
                                            {/* <!-- end row --> */}

                                        </div>
                                        {/* <!-- end container-fluid --> */}
                                    </div>
                                    {/* <!-- end app-main --> */}
                                </div>
                                {/* <!-- end app-container --> */}
                                {/* <!-- begin footer --> */}
                                <NewFooter />
                                {/* <!-- end footer --> */}
                            </div>
                            {/* <!-- end app-wrap --> */}
                        </div>
                    </>
                }
            </body>

        )
    }
}
export default Dashboard