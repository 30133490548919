import React, { Component } from 'react'
import { Modal, Select, Table, DatePicker } from 'antd'
import moment from 'moment';
import 'antd/dist/antd.css'; // or 'antd/dist/antd.less'
import Notification from '../Notification'
import PropagateLoader from '../SubmitingForm'
import ajax from '../../utils/ajax';
import functions from '../../utils/functions';
import Viewer from 'react-viewer';

import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'


const { Option } = Select;

class ReviewPrescription extends Component {

  state = {
    userId: functions.sessionGuard(),
    appointmentTimeList: false,
    reviewComment: "",
    prescriptionId: "",
    clientId:"",
    load: '',
    visible:false, 
    setVisible:false,
    approveState:true,
    verifyStatus:"",
    requestStatus:""

  }

  componentDidMount() {
    console.log("lplplplplplplplooo",this.props.visible)
    this.setState({
      prescriptionId: this.props.visible.presId,
      prescriptionImage: this.props.visible.presImage,
      clientId:this.props.visible.clientId
    })
  }

  handleChangeDoctor = (value) => {
    if (value != null) {

    } else {
      //this.setState({
      //  hospital: "", hosptalServicesList: false,
      //})
    }
  }

  handleChangeComment = (value) => {
    if (value != null) {
      this.setState({ reviewComment: value })
    } else {
      this.setState({
        reviewComment: ""
      })
    }
  }

  prescriptionStateSelect = (value) => {
    if (value != null) {
      
      if(value == "0"){
        this.setState({ approveState: false,
        verifyStatus:"0",
        requestStatus:"1" })
      }else{
   
          this.setState({ approveState: true,
            verifyStatus:"1",
            requestStatus:"1",
            reviewComment:"met our requirements and was approved" })
      }
      
    } else {
     //
    }
  }

  handleCancel = () => {
    const sent = this.props
    this.setState({ visible: false })
    sent.handleCancel(false)
  }
  setVisible = ()=>{
    this.setState({
      visible:true, setVisible:true
    })
  }

  getAppointmentTimes = async () => {

    const server_response = await ajax.listAppointmentTimes();
    const appointmentOptions = []

    if (server_response.status === "OK") {

      server_response.details.content.map((appointmentTime) =>
        appointmentOptions.push(<Option value={appointmentTime.id}>{appointmentTime.appointment_time}</Option>
        ),
      )

      this.setState({
        appointmentTimeList: appointmentOptions
      })

    } else {
      // this.setState({
      //     categoryList: "404"
      // })
    }

  }

  fetchServiceSpecialist = async (serviceId, hospitalID) => {

    if (serviceId.length > 0 && hospitalID.length > 0) {

      const server_response = await ajax.listServiceSpecialist(serviceId, hospitalID);
      const speOptions = []

      if (server_response.status === "OK") {

        server_response.details.content.map((specialists) =>
          speOptions.push(<Option value={specialists.specialist_id}>{specialists.full_name}</Option>
          ),
        )

        this.setState({
          serviceSpecialist: speOptions
        })

      } else {
        // this.setState({
        //     categoryList: "404"
        // })
      }
    } else {
        //
    }

  }

  onPressReviewPrescription = async (event) => {
    event.preventDefault()
    const {reviewComment, verifyStatus, requestStatus, prescriptionId,clientId } = this.state

    if (reviewComment.length > 0 || verifyStatus.length>0 || requestStatus.length > 0 || prescriptionId.length>0 || clientId.length>0) {

      this.setState({
        type: '',
        info: '',
        load: <PropagateLoader />
      })

      const server_response = await ajax.reviewPrescription(reviewComment,verifyStatus, requestStatus, prescriptionId, this.state.userId,clientId)

      if (server_response.status === "OK") {
        this.setState({
          type: 'alert alert-success',
          info: server_response.details.message,
          load: '',
        })
        window.location.reload()

      } else {

        this.setState({
          type: 'alert alert-danger',
          info: server_response.details.message,
          load: '',
        })

      }

    } else {
      this.setState({
        type: 'alert alert-danger',
        info: 'Please be advised to fill in all required fields - All fields are required',
        load: '',
      })
    }


  }

  render() {
    const {visible, setVisible } = this.state
    return (
      <>
      <Modal
        title={"REVIEW SUBMITTED PRESCRIPTION IMAGE"}
        centered
        bodyStyle={{ paddingBottom: '0px', paddingTop: '0px' }}
        visible={this.props.visible.visible}
        maskClosable={false}
        footer={[null]}
        maskTransitionName=""
        onCancel={this.handleCancel}
        width={600}
        okButtonProps={{ disabled: true }}
        cancelButtonProps={{ disabled: false }}
      >

        <br />
        <div class="row">



          <div class="col-sm-12 mobile-inputs">
            {this.state.load}
            <Notification
              message={{
                type: this.state.type,
                info: this.state.info,
              }}
            />

            <form method="post" onSubmit={this.onPressReviewPrescription}>

              <div class="form-group row">
                <div class="col-sm-12">
                <Zoom>
                  <img
                    alt="prescription preview"
                    // src="http://159.65.199.43/img/prescription/img_1658100296.png"
                    src={this.state.prescriptionImage}
                    width="550"
                    height="400"
                  />
                </Zoom>
                </div>
              </div>

              <div class="form-group row">
              <div class="col-sm-6">
                  <Select placeholder="Select Prescripiton State" style={{ width: '100%' }} onChange={this.prescriptionStateSelect}>
                    <Option value="" disabled="true">Select Prescripiton State</Option>
                    <Option value="1">Prescription Approved</Option>
                    <Option value="0">Prescription Denied</Option>
                  </Select>

                </div>

                <div class="col-sm-6">
                  <Select placeholder="Select Review Comment" style={{ width: '100%' }} disabled={this.state.approveState} onChange={this.handleChangeComment}>
                    <Option value="" disabled="true">Select Review Comment</Option>
                    <Option value="Image Not Clear please take another. try cleaning the camera, go to a place with more light.">Not clear</Option>
                    <Option value="does not have accepted features like a stamp by the doctor and signature, please follow our guidelines to avoid incoviniences">Not a professional prescription</Option>
                  </Select>
                </div>

              </div>
              <div class="row">
                <div class="col-sm-6 card-header-right" >
                  <button type="submit" class="btn btn-primary btn-block mb-2">Submit Prescripiton Review</button>
                  {/* <button onClick={() => { this.setVisible(true); } }>show</button> */}
                </div>
              </div>
            </form>
          </div>
        </div>

      </Modal>
      <Viewer
      visible={visible}
      noNavbar={true}
      noToolbar={false}
      changeable={false}
      attribute={false}
      scalable={false}
      onClose={() => { this.setVisible(false); } }
      images={[{src:"http://159.65.199.43/img/prescription/img_1658099795.png", alt: ''}]}
      container={document.getElementById("container")}/>
      </>

    )
  }
}

export default ReviewPrescription