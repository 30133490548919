import React, { Component } from 'react'
import { Modal, Select, Table, DatePicker } from 'antd'
import moment from 'moment';
import 'antd/dist/antd.css'; // or 'antd/dist/antd.less'
import Notification from '../../common/Notification'
import PropagateLoader from '../../common/SubmitingForm'
import ajax from '../../utils/ajax';
import functions from '../../utils/functions';


const { Option } = Select;

class AddAppointment extends Component {

  state = {
    userId: functions.sessionGuard(),
    hospitalList: false,
    hosptalServicesList: false,
    hosptialDoctorsList: false,
    appointmentTimeList: false,
    serviceSpecialist: false,
    appointmentTime: "",
    selectedService: "",
    selectedClient: "",
    selectedHospital: "",
    selectedSpecialist:"",
    selectedAppointmentDate: "",
    load: ''
  }

  componentDidMount() {
    this.getHospitals();
    this.getAppointmentTimes();
  }

  hospitalSelect = (value) => {
    // console.log("hospital ",value)
    if (value != null) {
      
      this.setState({ selectedHospital: value,
        hosptalServicesList:false
      })
      this.fetchHospitalsServices(value);
    } else {
      this.setState({
        selectedHospital: "", hospitalList: false,hosptalServicesList:false
      })
    }
  }

  preferedSpecialistSelect = (value) => {
    // console.log("specialist ",value)
    if (value != null) {
      // this.state.
      this.fetchHospitalsServices(value);
      this.setState({ selectedSpecialist: value })
    } else {
      this.setState({
        selectedSpecialist: "", hosptalServicesList: false,
      })
    }
  }

  handleChangeClient = (value) => {
    // console.log("client ",value)
    if (value != null) {
      this.setState({ selectedClient: value })
    } else {
      this.setState({
        selectedClient: ""
      })
    }
  }

  handleChangeService = (value) => {
    // console.log("service ",value)
    if (value != null) {
      this.fetchServiceSpecialist(value)
      this.setState({ selectedService: value })
    } else {
      this.setState({
        selectedService: ""
      })
    }
  }

  selectedDate = (_,value) => {
    // console.log("dated ",value)
    if (value != null) {
      this.setState({ selectedAppointmentDate: value })
    } else {
      this.setState({
        selectedAppointmentDate: ""
      })
    }
  }

  handleChangeTime = (value) => {
    console.log("time ",value)
    if (value != null) {
      this.setState({ appointmentTime: value })
    } else {
      this.setState({
        appointmentTime: ""
      })
    }
  }

  fetchHospitalsServices = async (hospitalID) => {

    const server_response = await ajax.listServiceByHospital(hospitalID);
    const serOptions = []

    if (server_response.status === "OK") {
      server_response.details.content.map((services) =>
        serOptions.push(<Option value={services.services.service_id}>{services.services.service_name}</Option>
        ),
      )

      this.setState({
        hosptalServicesList: serOptions
      })

    } else {
      // this.setState({
      //     categoryList: "404"
      // })
    }

  }

  fetchServiceSpecialist = async (serviceId) => {

    const { selectedHospital} = this.state

    if (selectedHospital.length > 0) {

    const server_response = await ajax.listServiceSpecialist(serviceId,selectedHospital);
    const speOptions = []

    if (server_response.status === "OK") {

      // console.log("hooops ",server_response.details.content)

      server_response.details.content.map((specialists) =>
        speOptions.push(<Option value={specialists.specialist_id}>{specialists.full_name}</Option>
        ),
      )

      this.setState({
        serviceSpecialist: speOptions
      })

    } else {
      // this.setState({
      //     categoryList: "404"
      // })
    }
  }else{
          this.setState({
          serviceSpecialist: "Please select hospital"
      })
  }

  }

  handleCancel = () => {
    const sent = this.props
    this.setState({ visible: false })
    sent.handleCancel(false)
  }

  handleChange = (value) => {
    // console.log(`selected ${value}`);
  }

  getHospitals = async () => {

    const server_response = await ajax.listHospitals();
    const hosOptions = []

    if (server_response.status === "OK") {

      server_response.details.content.list.map((hospitals) =>
        hosOptions.push(<Option value={hospitals.hospital_id}>{hospitals.full_hospital_name}</Option>
        ),
      )

      this.setState({
        hospitalList: hosOptions
      })

    } else {
      // this.setState({
      //     categoryList: "404"
      // })
    }

  }

  getAppointmentTimes = async () => {

    const server_response = await ajax.listAppointmentTimes();
    const appointmentOptions = []

    if (server_response.status === "OK") {

      server_response.details.content.map((appointmentTime) =>
        appointmentOptions.push(<Option value={appointmentTime.id}>{appointmentTime.appointment_time}</Option>
        ),
      )

      this.setState({
        appointmentTimeList: appointmentOptions
      })

    } else {
      // this.setState({
      //     categoryList: "404"
      // })
    }

  }

  onPressAddAppointment = async (event) => {
    event.preventDefault()
    const { appointmentTime, selectedService, selectedClient,selectedSpecialist,selectedHospital,selectedAppointmentDate } = this.state

    if (appointmentTime.length > 0 &&
      selectedService.length > 0 &&
      selectedClient.length > 0 &&
      selectedSpecialist.length >0 &&
      selectedAppointmentDate.length >0 &&
      selectedHospital.length > 0) {

      this.setState({
        type: '',
        info: '',
        load: <PropagateLoader />
      })

      const server_response = await ajax.createAppointment(appointmentTime, selectedService, selectedClient, selectedHospital,selectedSpecialist,selectedAppointmentDate, this.state.userId)

      if (server_response.status === "OK") {
        this.setState({
          type: 'alert alert-success',
          info: server_response.details.message,
          load: '',
        })
        window.location.reload()

      } else {

        this.setState({
          type: 'alert alert-danger',
          info: server_response.details.message,
          load: '',
        })

      }

    } else {

      this.setState({
        type: 'alert alert-danger',
        info: 'Please be advised to fill in all required fields - All fields are required',
        load: '',
      })
    }


  }

  render() {
    return (

      <Modal
        title={"SCHEDULE APPOINTMENT"}
        centered
        bodyStyle={{ paddingBottom: '0px', paddingTop: '0px' }}
        visible={this.props.visible.visible}
        maskClosable={false}
        footer={[null]}
        maskTransitionName=""
        onCancel={this.handleCancel}
        width={700}
        okButtonProps={{ disabled: true }}
        cancelButtonProps={{ disabled: false }}
      >

        <br />
        <div class="row">

          <div class="col-sm-12 mobile-inputs">
            {this.state.load}
            <Notification
              message={{
                type: this.state.type,
                info: this.state.info,
              }}
            />
            <br />

            <form method="post" onSubmit={this.onPressAddAppointment}>

              <div class="form-group row">
                <div class="col-sm-6">
                  <Select placeholder="Select Client" style={{ width: '100%' }} onChange={this.handleChangeClient}>
                    <Option value="1">Genesis Einstein</Option>
                    <Option value="2">Abdalla Wanjusi</Option>
                    <Option value="3">Omar Umar</Option>
                    <Option value="4">Abdul Kadir</Option>
                  </Select>
                </div>
                <div class="col-sm-6">
                  <Select placeholder="Please Select Clinic - Hospital" style={{ width: '100%' }} onChange={this.hospitalSelect}>
                    <Option >Please Select Clinic - Hospital</Option>
                    {this.state.hospitalList}
                  </Select>
                </div>
              </div>

              <div class="form-group row">
                <div class="col-sm-6">
                  <Select placeholder="Select Services Type" style={{ width: '100%' }} onChange={this.handleChangeService}>
                    <Option >Please Select Services Type</Option>
                    {this.state.hosptalServicesList}
                  </Select>
                </div>
                <div class="col-sm-6">
                  <Select placeholder="Select Doctor / Physician" style={{ width: '100%' }} onChange={this.preferedSpecialistSelect}>
                    <Option value="">Select Prefered Doctor</Option>
                    {this.state.serviceSpecialist}
                  </Select>
                </div>
              </div>

              <div class="form-group row">
                <div class="col-sm-6">

                  <Select placeholder="Select Prefered Time" style={{ width: '100%' }} onChange={this.handleChangeTime}>
                    <Option value="">Select Prefered Time</Option>
                    {this.state.appointmentTimeList}
                  </Select>

                </div>

                <div class="col-sm-6">
                  <DatePicker style={{ width: '100%' }}
                    disabledDate={(current) => {
                      let customDate = moment().format("YYYY-MM-DD");
                      return current && current < moment(customDate, "YYYY-MM-DD");
                    }}
                    onChange={this.selectedDate}
                  />
                </div>

              </div>

              <div class="form-group row">
                <div class="col-sm-6">
                </div>

              </div>
              <div class="row">
                <div class="col-sm-3 card-header-right" >
                  <button type="submit" class="btn btn-primary btn-block mb-3">Schedule</button>
                </div>
              </div>
            </form>
          </div>
        </div>



      </Modal>

    )
  }
}

export default AddAppointment