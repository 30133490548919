import React, { Component } from 'react'
import { Modal, Select } from 'antd'
import 'antd/dist/antd.css'; // or 'antd/dist/antd.less'

import Notification from '../Notification'
import PropagateLoader from '../SubmitingForm'
import ajax from '../../utils/ajax'
import functions from '../../utils/functions'


// const { Option } = Select;

class AddHospitalPaymet extends Component {

  state = {
    userId: functions.sessionGuard(),
    paymentName:"",
    paymentCode:"",
    load:""
  }


  handleCancel = () => {
    const sent = this.props
    this.setState({ visible: false })
    sent.handleCancel(false)
  }

  handleChange = (value)=> {
    console.log(`selected ${value}`);
  }

  paymentName = (value)=> {
    this.setState({ paymentName: value.target.value })
  }
  paymentCode = (value)=> {
    this.setState({ paymentCode: value.target.value })
  }

  onPressAddService= async (event)=>{
    event.preventDefault()
    const { paymentName,paymentCode} = this.state

    if (paymentName.length > 0 && 
      paymentCode.length >0 ) {
        this.setState({
          type: '',
          info: '',
          load:<PropagateLoader/>
        })

          //fetch category from the API
          const server_response = await ajax.createPaymentMethods(paymentName, paymentCode,this.state.userId)

          if (server_response.status === "OK") {
            this.setState({
              type: 'alert alert-success',
              info: server_response.details.message,
              load: '',
            })
            window.location.reload()

        } else {

          this.setState({
            type: 'alert alert-danger',
            info: server_response.details.message,
            load: '',
          })

        }


      }else{
        this.setState({
          type: 'alert alert-danger',
          info: 'Please be advised to fill in all required fields - All fields are required',
          load:'',
        })
      }
  }

  render() {
    return (

        <Modal
        title = {"ADD HOSPITAL PAYMENT METHODS"}
        centered
        bodyStyle={{ paddingBottom: '0px', paddingTop: '0px' }}
        visible={this.props.visible.visible}
      
        maskClosable={false}
        footer={[null]}
        maskTransitionName=""
        onCancel={this.handleCancel}
        width={700}
        okButtonProps={{ disabled: true }}
        cancelButtonProps={{ disabled: false }}
      >

<br/>
<div class="row">

<div class="col-sm-12 mobile-inputs">

{this.state.load}
  <Notification
        message={{
          type: this.state.type,
          info: this.state.info,
        }}
    />

<form method="post" onSubmit={this.onPressAddService}>

<div class="form-group row">
<div class="col-sm-6">
<input type="text" onChange={this.paymentName} class="form-control form-txt-primary" placeholder="Payment Method Name"/>
</div>
<div class="col-sm-6">
<input type="text" onChange={this.paymentCode} class="form-control form-txt-primary" placeholder="Payment Method Code"/>
</div>
</div>



<div class="form-group row">
<div class="col-sm-6">
{/* <input type="text" class="form-control form-txt-primary" placeholder="Accepts Insurance"/> */}
</div>

</div>
<div class="row">
<div class="col-sm-3 card-header-right" >
<button type="submit" class="btn btn-primary btn-block mb-3">Add Method</button>
 </div>
 </div>
</form>
</div>
</div>


     
      </Modal>

    )}}

    export default AddHospitalPaymet