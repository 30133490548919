import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import NewFooter from '../common/NewFooter';
import NewHeader from '../common/NewHeader';
import Preloader from '../common/Preloader';
import SideNav from '../common/SideNav';
import Nav from '../common/SideNavigation'
import ajax from '../utils/ajax';
import functions from '../utils/functions'

class NewSystemSettings extends Component {

    state = {
        userId: functions.sessionGuard(),
        hospitalCategories: "00",
        hospitalsCount: "00",
        paymentMethods: "00",
        hospitalServices: "00",
        physiciansCount: "00",
        // console:"00",
        specialitiesCount: "00",
        allAppointments: "00",
        specialityCategorieCount: "00"
    }
    componentDidMount() {
        this.countHospitalCategeries();
        this.countPaymentMethods();
        this.countHospitalServices();
        this.countPysicians();
        this.countHospitals();
        this.countSpeciality();
        this.countSpecialityCategory();
        this.countAppointments();
    }

    countAppointments = async () => {

        const server_response = await ajax.countAppointments(this.state.userId);

        if (server_response.status === "OK") {

            this.setState({
                allAppointments: server_response.details.content.total_c
            })

        } else {
            // this.setState({
            //     categoryList: "404"
            // })
        }
    }

    countPysicians = async () => {

        const server_response = await ajax.countSpecialist(this.state.userId);

        if (server_response.status === "OK") {

            this.setState({
                physiciansCount: server_response.details.content.total_c
            })

        } else {
            // this.setState({
            //     categoryList: "404"
            // })
        }
    }

    countHospitalCategeries = async () => {

        const server_response = await ajax.countCategory(this.state.userId);

        if (server_response.status === "OK") {

            this.setState({
                hospitalCategories: server_response.details.content.total_c
            })

        } else {
            // this.setState({
            //     categoryList: "404"
            // })
        }
    }

    countHospitalServices = async () => {

        const server_response = await ajax.countHospitalServices(this.state.userId);

        if (server_response.status === "OK") {

            this.setState({
                hospitalServices: server_response.details.content.total_c
            })

        } else {
            // this.setState({
            //     categoryList: "404"
            // })
        }
    }

    countPaymentMethods = async () => {

        const server_response = await ajax.countPaymentMethods(this.state.userId);

        if (server_response.status === "OK") {

            this.setState({
                paymentMethods: server_response.details.content.total_c
            })

        } else {
            // this.setState({
            //     categoryList: "404"
            // })
        }
    }

    countSpeciality = async () => {

        const server_response = await ajax.countSpeciality(this.state.userId);

        if (server_response.status === "OK") {

            this.setState({
                specialitiesCount: server_response.details.content.total_c
            })

        } else {
            // this.setState({
            //     categoryList: "404"
            // })
        }
    }

    countSpecialityCategory = async () => {

        const server_response = await ajax.countSpecialityCategory(this.state.userId);

        if (server_response.status === "OK") {

            this.setState({
                specialityCategorieCount: server_response.details.content.total_c
            })

        } else {
            // this.setState({
            //     categoryList: "404"
            // })
        }
    }

    countHospitals = async () => {

        const server_response = await ajax.countHospitals(this.state.userId);

        if (server_response.status === "OK") {

            this.setState({
                hospitalsCount: server_response.details.content.total_c
            })

        } else {
            // this.setState({
            //     categoryList: "404"
            // })
        }
    }


    render() {
        return (

            <body>
                {/* <!-- begin app --> */}
                <div class="app">
                    {/* <!-- begin app-wrap --> */}
                    <div class="app-wrap">
                        <Preloader />
                        <NewHeader />

                        <div class="app-container">
                            {/* <!-- begin app-nabar --> */}
                            <SideNav />
                            <div class="app-main" id="main">
                                {/* <!-- begin container-fluid --> */}
                                <div class="container-fluid">
                                    {/* <!-- begin row --> */}
                                    <div class="row">
                                        <div class="col-md-12 m-b-30">
                                            {/* <!-- begin page title --> */}
                                            <div class="d-block d-sm-flex flex-nowrap align-items-center">
                                                <div class="page-title mb-2 mb-sm-0">
                                                    <h1>System Settings</h1>
                                                </div>
                                                <div class="ml-auto d-flex align-items-center">
                                                    <nav>
                                                        <ol class="breadcrumb p-0 m-b-0">
                                                            <li class="breadcrumb-item">
                                                                <a href="index.html"><i class="ti ti-home"></i></a>
                                                            </li>
                                                            <li class="breadcrumb-item">
                                                                Home
                                                            </li>
                                                            <li class="breadcrumb-item active text-primary" aria-current="page">System Settings</li>
                                                        </ol>
                                                    </nav>
                                                </div>
                                            </div>
                                            {/* <!-- end page title --> */}
                                        </div>
                                    </div>
                                    {/* <!-- end row --> */}

                                    {/* <!-- start file maneger --> */}
                                    <div class="row">
                                        <div class="col-xl-3 col-sm-6">
                                            <div class="card card-statistics">
                                                <div class="card-body">
                                                    <div class="text-center p-2">
                                                        <div class="mb-2">
                                                            <img src="asset/img/file-icon/open_ticket.png" alt="png-img" />
                                                        </div>
                                                        <h4 class="mb-0">00</h4>
                                                        <p class="mb-2">Open Tickets</p>
                                                        <a href="javascript:void(0)" class="btn btn-light">View</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-xl-3 col-sm-6">
                                            <div class="card card-statistics">
                                                <div class="card-body">
                                                    <div class="text-center p-2">
                                                        <div class="mb-2">
                                                            <img src="asset/img/file-icon/closed_ticket.png" alt="png-img" />
                                                        </div>
                                                        <h4 class="mb-0">00</h4>
                                                        <p class="mb-2">Closed Tickets</p>
                                                        <a href="javascript:void(0)" class="btn btn-light">View</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-xl-3 col-sm-6">
                                            <div class="card card-statistics">
                                                <div class="card-body">
                                                    <div class="text-center p-2">
                                                        <div class="mb-2">
                                                            <img src="asset/img/file-icon/appoint.png" alt="png-img" />
                                                        </div>
                                                        <h4 class="mb-0">{this.state.allAppointments}</h4>
                                                        <p class="mb-2">All Appointments</p>
                                                        <a href="javascript:void(0)" class="btn btn-light">View</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-xl-3 col-sm-6">
                                            <div class="card card-statistics">
                                                <div class="card-body">
                                                    <div class="text-center p-2">
                                                        <div class="mb-2">
                                                            <img src="asset/img/file-icon/payment.png" alt="png-img" />
                                                        </div>
                                                        <h4 class="mb-0">{this.state.paymentMethods}</h4>
                                                        <p class="mb-2">Payment Methods</p>
                                                        <a href="/payment-methods" class="btn btn-light">View</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-xl-3 col-sm-6">
                                            <div class="card card-statistics">
                                                <div class="card-body">
                                                    <div class="text-center p-2">
                                                        <div class="mb-2">
                                                            <img src="asset/img/file-icon/psd.png" alt="png-img" />
                                                        </div>
                                                        <h4 class="mb-0">4</h4>
                                                        <p class="mb-2">Hospital Types</p>
                                                        <a href="javascript:void(0)" class="btn btn-light">View</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-xl-3 col-sm-6">
                                            <div class="card card-statistics">
                                                <div class="card-body">
                                                    <div class="text-center p-2">
                                                        <div class="mb-2">
                                                            <img src="asset/img/file-icon/hospital.png" alt="png-img" />
                                                        </div>
                                                        <h4 class="mb-0">{this.state.hospitalsCount}</h4>
                                                        <p class="mb-2">Hospitals</p>
                                                        <a href="/clinics-hospitals" class="btn btn-light">View</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-xl-3 col-sm-6">
                                            <div class="card card-statistics">
                                                <div class="card-body">
                                                    <div class="text-center p-2">
                                                        <div class="mb-2">
                                                            <img src="asset/img/file-icon/services.png" alt="png-img" />
                                                        </div>
                                                        <h4 class="mb-0">{this.state.hospitalServices}</h4>
                                                        <p class="mb-2">Hospital Services</p>
                                                        <a href="/add-hospital-service" class="btn btn-light">View</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-xl-3 col-sm-6">
                                            <div class="card card-statistics">
                                                <div class="card-body">
                                                    <div class="text-center p-2">
                                                        <div class="mb-2">
                                                            <img src="asset/img/file-icon/nodes.png" alt="png-img" />
                                                        </div>
                                                        <h4 class="mb-0">{this.state.hospitalCategories}</h4>
                                                        <p class="mb-2">Hospital Categories</p>
                                                        <a href="/clinic-hospital-categories" class="btn btn-light">View</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-xl-3 col-sm-6">
                                            <div class="card card-statistics">
                                                <div class="card-body">
                                                    <div class="text-center p-2">
                                                        <div class="mb-2">
                                                            <img src="asset/img/file-icon/doctor.png" alt="png-img" />
                                                        </div>
                                                        <h4 class="mb-0">{this.state.physiciansCount}</h4>
                                                        <p class="mb-2">Doctors / Physicians</p>
                                                        <a href="/physicians" class="btn btn-light">View</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-xl-3 col-sm-6">
                                            <div class="card card-statistics">
                                                <div class="card-body">
                                                    <div class="text-center p-2">
                                                        <div class="mb-2">
                                                            <img src="asset/img/file-icon/speciality.png" alt="png-img" />
                                                        </div>
                                                        <h4 class="mb-0">{this.state.specialitiesCount}</h4>
                                                        <p class="mb-2">Doctor / Pysician Specialities</p>
                                                        <a href="/add-speciality" class="btn btn-light">View</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-xl-3 col-sm-6">
                                            <div class="card card-statistics">
                                                <div class="card-body">
                                                    <div class="text-center p-2">
                                                        <div class="mb-2">
                                                            <img src="asset/img/file-icon/nodes.png" alt="png-img" />
                                                        </div>
                                                        <h4 class="mb-0">{this.state.specialityCategorieCount}</h4>
                                                        <p class="mb-2">Speciality Categories</p>
                                                        <a href="/speciality-categories" class="btn btn-light">View</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-xl-3 col-sm-6">
                                            <div class="card card-statistics">
                                                <div class="card-body">
                                                    <div class="text-center p-2">
                                                        <div class="mb-2">
                                                            <img src="asset/img/file-icon/users.png" alt="png-img" />
                                                        </div>
                                                        <h4 class="mb-0">00</h4>
                                                        <p class="mb-2">System Users</p>
                                                        <a href="javascript:void(0)" class="btn btn-light">View</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    {/* <!-- start file maneger --> */}
                                </div>
                                {/* <!-- end container-fluid --> */}
                            </div>
                            {/* <!-- end app-main --> */}
                        </div>
                        {/* <!-- end app-container --> */}
                        <NewFooter />
                    </div>
                    {/* <!-- end app-wrap --> */}
                </div>
                {/* <!-- end app --> */}

            </body>
        )
    }
}
export default NewSystemSettings