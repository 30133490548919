import React, { Component } from 'react'
import { Modal, Select, Table } from 'antd'
import 'antd/dist/antd.css'; // or 'antd/dist/antd.less'
import Notification from '../../common/Notification'
import PropagateLoader from '../../common/SubmitingForm'
import ajax from '../../utils/ajax'
import functions from '../../utils/functions'


const { Option } = Select;

class AddClinicHospital extends Component {
  state = {
    hospitalName: "",
    hospitalServices: "",
    hospitalCategory: "",
    hospitalWorkingHours: "",
    hospitalAddress: "",
    hospitalLat:"",
    hospitalLng:"",
    hospitalEmail: "",
    hospitalPhone: "",
    hospitalInsurance: "",
    serviceList: false,
    categoryList: false,
    insuranceList: false,
    type: '',
    info: '',
    load: '',
  }

  componentDidMount() {
    this.getCategories();
    this.getInsurance();
    this.getServices();
  }

  handleCancel = () => {
    const sent = this.props
    this.setState({ visible: false })
    sent.handleCancel(false)
  }

  hospitalName = (value) => {
    this.setState({ hospitalName: value.target.value })
  }
  hospitalServices = (value) => {
    this.setState({ hospitalServices: value })
  }
  hospitalCategory = (value) => {
    this.setState({ hospitalCategory: value })
  }
  hospitalWorkingHours = (value) => {
    this.setState({ hospitalWorkingHours: value.target.value })
  }
  hospitalAddress = (value) => {
    this.setState({ hospitalAddress: value.target.value })
  }
  hospitalLat = (value) => {
    this.setState({ hospitalLat: value.target.value })
  }

  hospitalLng = (value) => {
    this.setState({ hospitalLng: value.target.value })
  }

  hospitalEmail = (value) => {
    this.setState({ hospitalEmail: value.target.value })
  }
  hospitalPhone = (value) => {
    this.setState({ hospitalPhone: value.target.value })
  }
  hospitalInsurance = (value) => {
    this.setState({ hospitalInsurance: value })
  }

  getCategories = async () => {
    const server_response = await ajax.listCategories();
    const catOptions = []

    if (server_response.status === "OK") {

      server_response.details.content.map((category) =>
        catOptions.push(<Option value={category.category_id}>{category.category_name}</Option>
        ),
      )
      this.setState({
        categoryList: catOptions
      })

    } else {
      // this.setState({
      //     categoryList: "404"
      // })
    }
  }

  getServices = async () => {
    const server_response = await ajax.listServices();
    const serOptions = []

    if (server_response.status === "OK") {

      server_response.details.content.map((service) =>
        serOptions.push(<Option value={service.service_id}>{service.service_name}</Option>
        ),
      )
      this.setState({
        serviceList: serOptions
      })

    } else {
      // this.setState({
      //     categoryList: "404"
      // })
    }
  }

  getInsurance = async () => {
    const server_response = await ajax.listPaymentMethods();
    const payOptions = []

    if (server_response.status === "OK") {

      // console.log(server_response.details.content)

      server_response.details.content.map((payment) =>
        payOptions.push(<Option value={payment.payment_method_id}>{payment.payment_method_name}</Option>
        ),
      )
      this.setState({
        insuranceList: payOptions
      })

    } else {
      // this.setState({
      //     categoryList: "404"
      // })
    }
  }


  onPressAddFacility = async (event) => {
    event.preventDefault()
    const { hospitalName, hospitalServices, hospitalCategory, hospitalWorkingHours, hospitalAddress,hospitalLat,hospitalLng, hospitalEmail, hospitalPhone, hospitalInsurance } = this.state

    if (hospitalName.length > 0 &&
      hospitalServices.length > 0 &&
      hospitalCategory.length > 0 &&
      hospitalWorkingHours.length > 0 &&
      hospitalAddress.length > 0 &&
      hospitalLat.length > 0 &&
      hospitalLng.length > 0 &&
      hospitalEmail.length > 0 &&
      hospitalPhone.length > 0 &&
      hospitalInsurance.length > 0) {

      this.setState({
        type: '',
        info: '',
        load: <PropagateLoader />
      })

      const server_response = await ajax.addClinicHospital(hospitalName, hospitalServices, hospitalCategory, hospitalWorkingHours, hospitalAddress, hospitalEmail, hospitalPhone, hospitalInsurance, hospitalLat,hospitalLng)

      if (server_response.status === "OK") {
        this.setState({
          type: 'alert alert-success',
          info: server_response.details.message,
          load: '',
        })
        window.location.reload()

      } else {

        this.setState({
          type: 'alert alert-danger',
          info: server_response.details.message,
          load: '',
        })

      }


    } else {
      this.setState({
        type: 'alert alert-danger',
        info: 'Please be advised to fill in all required fields - All fields are required',
        load: '',
      })
    }


  }

  render() {
    return (

      <Modal
        title={"ADD NEW CLINIC - HOSPITAL"}
        centered
        bodyStyle={{ paddingBottom: '0px', paddingTop: '0px' }}
        visible={this.props.visible.visible}

        maskClosable={false}
        footer={[null]}
        maskTransitionName=""
        onCancel={this.handleCancel}
        width={700}
        okButtonProps={{ disabled: true }}
        cancelButtonProps={{ disabled: false }}
      >

        <br />
        <div class="row">

          <div class="col-sm-12 mobile-inputs">
            {this.state.load}
            <Notification
              message={{
                type: this.state.type,
                info: this.state.info,
              }}
            />
            <br/>

            <form method="post" onSubmit={this.onPressAddFacility}>

              <div class="form-group row">
                <div class="col-sm-6">
                  <input type="text" name="hospitalAddress" onChange={this.hospitalName} class="form-control" placeholder="Clinic - Hospital fullname" />
                </div>
                <div class="col-sm-6">
                  <Select name="hospitalCategory" placeholder="Clinic - Hospital Category" style={{ width: '100%' }} onChange={this.hospitalCategory}>
                    {this.state.categoryList}
                  </Select>
                </div>
              </div>

              <div class="form-group">

                <Select
                  mode="multiple"
                  name="hospitalServices"
                  style={{ width: '100%' }}
                  placeholder="Select Services Offered"
                  onChange={this.hospitalServices}>
                  {this.state.serviceList}

                </Select>
              </div>

              <div class="form-group">
                <Select
                  mode="multiple"
                  name="hospitalInsurance"
                  style={{ width: '100%' }}
                  placeholder="Select Accepted Insurance"
                  onChange={this.hospitalInsurance}>
                  {this.state.insuranceList}
                </Select>
              </div>

              <div class="form-group row">
                <div class="col-sm-6">
                  <input type="text" name="hospitalAddress" onChange={this.hospitalAddress} class="form-control" placeholder="Physical Address" />
                </div>

                  <div class="col-sm-6">
                    <input type="text" name="hospitalWorkingHours" onChange={this.hospitalWorkingHours} class="form-control" placeholder="Working Hours" />
                  </div>
                </div>

                <div class="form-group row">
                <div class="col-sm-6">
                    <input type="text" name="hospitalLat" onChange={this.hospitalLat} class="form-control" placeholder="Google Coordinate - [ Lng ]" />
                    </div>
                  <div class="col-sm-6">
                    <input type="text" name="hospitalLng" onChange={this.hospitalLng} class="form-control" placeholder="Google Coordinate - [ Lng ]" />
                  </div>
                  </div>
              

              <div class="form-group row">
                <div class="col-sm-6">
                  <input type="text" name="hospitalEmail" onChange={this.hospitalEmail} class="form-control" placeholder="E-Mail" />
                </div>
                <div class="col-sm-6">
                  <input type="text" name="hospitalPhone" onChange={this.hospitalPhone} class="form-control" placeholder="Telephone" />
                </div>
              </div>

              <div class="row">
                <div class="col-sm-3 card-header-right" >
                  <button type="submit" class="btn btn-primary btn-block mb-3">Add Facility</button>
                </div>
              </div>
            </form>
          </div>
        </div>



      </Modal>

    )
  }
}

export default AddClinicHospital