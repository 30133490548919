import React, { Component } from 'react'
import Notification from '../../common/Notification'
import Loader from '../../common/Loader'
import ajax from '../../utils/ajax'
import functions from '../../utils/functions'


class Login extends Component {

  state = {
    username: '',
    password: '',
    type: '',
    info: '',
    load: '',
  }

  onPressLogin = async (event) => {

    event.preventDefault()
    const { username, password } = this.state

    if (username.length > 0 && password.length > 0) {

      this.setState({
        type: '',
        info: '',
        load: <Loader />
      })
      //fetch login from the API
      const server_response = await ajax.loginUser(username, password)

      if (server_response.status === 'OK') {
        localStorage.setItem(
          'equiva@user',
          server_response.details.content.access_token,
        )

        // decode the token to access some variables for use
        const access_token = localStorage.getItem('equiva@user')
        const decorded_token = functions.parseJwt(access_token)

        const role_id = decorded_token['data']['role_id']
        const is_secure = decorded_token['data']['is_secure']

        if (role_id === '2' || role_id === '3' || role_id === '4') {
          this.setState({
            type: 'alert alert-danger',
            info: 'You are not authorised to use this system',
            load: '',
          })
        } else if (is_secure === '0') {
          this.props.history.push('/reset-password')
          window.location.reload()
        } else if (server_response.status === 'OK') {

          this.setState({
            type: 'alert alert-success',
            info: 'Credentials Accepted, Redirecting you . . ',
            load: '',
          })

          this.props.history.push('/')
          window.location.reload()
        }
      } else {
        this.setState({
          type: 'alert alert-danger',
          info: server_response.details.message,
          load: '',
        })
      }
    } else {
      this.setState({
        type: 'alert alert-danger',
        info: 'Username and Password Required',
        load: '',
      })
    }


  }

  onChangeUsername = (event) => {
    this.setState({ username: event.target.value })
  }

  onChangePassword = (event) => {
    this.setState({ password: event.target.value })
  }

  render() {
    return (

      <body class="fix-menu">

        <div class="theme-loader">
          <div class="ball-scale">
            <div class='contain'>
              <div class="ring"><div class="frame"></div></div>
              <div class="ring"><div class="frame"></div></div>
              <div class="ring"><div class="frame"></div></div>
              <div class="ring"><div class="frame"></div></div>
              <div class="ring"><div class="frame"></div></div>
              <div class="ring"><div class="frame"></div></div>
              <div class="ring"><div class="frame"></div></div>
              <div class="ring"><div class="frame"></div></div>
              <div class="ring"><div class="frame"></div></div>
              <div class="ring"><div class="frame"></div></div>
            </div>
          </div>
        </div>

        <section class="login-block">

          <div class="container">
            <div class="row">
              <div class="col-sm-12">

                <form class="md-float-material form-material" method="post" onSubmit={this.onPressLogin}>
                  <div class="text-center">
                    <img src="assets/images/logo.png" alt="logo.png" />
                  </div>
                  <div class="auth-box card">
                    <div class="card-block">
                      <div class="row m-b-20">
                        <div class="col-md-12">
                          <h3 class="text-center">Sign In</h3>
                        </div>
                      </div>

                      {this.state.load}
                      <Notification
                        message={{
                          type: this.state.type,
                          info: this.state.info,
                        }}
                      />

                      <div class="form-group form-primary">
                        <input type="text" name="username" onChange={this.onChangeUsername} class="form-control" required="" placeholder="Username" />
                        <span class="form-bar"></span>
                      </div>
                      <div class="form-group form-primary">
                        <input type="password" name="password" onChange={this.onChangePassword} class="form-control" required="" placeholder="Password" />
                        <span class="form-bar"></span>
                      </div>
                      <div class="row m-t-25 text-left">
                        <div class="col-12">
                          <div class="checkbox-fade fade-in-primary d-">
                                                          {/* <label>
                              <input type="checkbox" value=""/>
                              <span class="cr"><i class="cr-icon icofont icofont-ui-check txt-primary"></i></span>
                              <span class="text-inverse">Remember me</span>
                              </label> */}
                          </div>
                          <div class="forgot-phone text-right f-right">
                            <a href="/forgot-password" class="text-right f-w-600">Forgot Password ?</a>
                          </div>
                        </div>
                      </div>
                      <div class="row m-t-30">
                        <div class="col-md-12">
                          <button type="submit" class="btn btn-primary btn-md btn-block waves-effect waves-light text-center m-b-20">Sign in</button>
                        </div>
                      </div>
                      <br />
                    </div>
                  </div>
                </form>

              </div>

            </div>

          </div>

        </section>

      </body>

    )
  }
}

export default Login




