import React, { Component } from 'react'

class Forgot extends Component {

  render() {
    return (

<body class="fix-menu">

<div class="theme-loader">
<div class="ball-scale">
<div class='contain'>
<div class="ring"><div class="frame"></div></div>
<div class="ring"><div class="frame"></div></div>
<div class="ring"><div class="frame"></div></div>
<div class="ring"><div class="frame"></div></div>
<div class="ring"><div class="frame"></div></div>
<div class="ring"><div class="frame"></div></div>
<div class="ring"><div class="frame"></div></div>
<div class="ring"><div class="frame"></div></div>
<div class="ring"><div class="frame"></div></div>
<div class="ring"><div class="frame"></div></div>
</div>
</div>
</div>

<section class="login-block">

<div class="container-fluid">
<div class="row">
<div class="col-sm-12">

<form class="md-float-material form-material">
<div class="text-center">
<img src="assets/images/logo.png" alt="logo.png"/>
</div>
<div class="auth-box card">
<div class="card-block">
<div class="row m-b-20">
<div class="col-md-12">
<h3 class="text-left">Recover your password</h3>
</div>
</div>
<div class="form-group form-primary">
<input type="text" name="email-address" class="form-control" required="" placeholder="Your Email Address"/>
<span class="form-bar"></span>
</div>
<div class="row">
<div class="col-md-12">
<button type="button" class="btn btn-primary btn-md btn-block waves-effect text-center m-b-20">Reset Password</button>
</div>
 </div>
<p class="f-w-600 text-right"><a href="/login">Back to Login.</a></p>

</div>
</div>
</form>

</div>

</div>

</div>

</section>
</body>


    )}}

    export default Forgot