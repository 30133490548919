import React, { Component } from 'react'
import { Table, Tooltip } from 'antd'
import { Link } from 'react-router-dom'
import Preloader from '../../common/Preloader'
import NewHeader from '../../common/NewHeader'
import SideNav from '../../common/SideNav'
import ResultModal from '../../common/Modals/AddSystemUser'
import EditResultModal from '../../common/Modals/EditHospitalPayment'
import SweetAlert from 'react-bootstrap-sweetalert'
import NewFooter from '../../common/NewFooter'
import functions from '../../utils/functions'
import ajax from '../../utils/ajax';


class NewManageUsers extends Component {

    state = {
        dialog: false
    }

    recievedState = (data) => {
        this.setState({ dialog: data })
    }

    AddUser = () => {
        this.setState({
            dialog: true
        })
    }

    render() {
        return (
            <body>
                {/* <!-- begin app --> */}
                <div class="app">
                    {/* <!-- begin app-wrap --> */}
                    <div class="app-wrap">
                        {/* <!-- begin pre-loader --> */}
                        <Preloader />
                        <NewHeader />
                        <div class="app-container">
                            <SideNav />
                            {/* <!-- begin app-main --> */}
                            <div class="app-main" id="main">
                                {/* <!-- begin container-fluid --> */}
                                <div class="container-fluid">
                                    {/* <!-- begin row --> */}
                                    <div class="row">
                                        <div class="col-md-12 m-b-30">
                                            {/* <!-- begin page title --> */}
                                            <div class="d-block d-sm-flex flex-nowrap align-items-center">
                                                <div class="page-title mb-2 mb-sm-0">
                                                    <h1>All System User(s)</h1>
                                                </div>
                                                <div class="ml-auto d-flex align-items-center">
                                                    <nav>
                                                        <ol class="breadcrumb p-0 m-b-0">
                                                            <li class="breadcrumb-item">
                                                                <a href="index.html"><i class="ti ti-home"></i></a>
                                                            </li>
                                                            <li class="breadcrumb-item">
                                                                Home
                                                            </li>
                                                            <li class="breadcrumb-item active text-primary" aria-current="page">All System User(s)</li>
                                                        </ol>
                                                    </nav>
                                                </div>
                                            </div>
                                            {/* <!-- end page title --> */}
                                        </div>
                                    </div>
                                    {/* <!-- end row --> */}
                                    {/* <!-- begin row --> */}
                                    <div class="row">
                                        <div class="col-lg-12">
                                            <div class="card card-statistics">
                                                <div class="card-body">

                                                    <div class="my-3">
                                                        <button type="button" onClick={this.AddUser} class="btn btn-primary">Add System User (s)</button>
                                                    </div>

                                                    <div class="datatable-wrapper table-responsive">

                                                        {this.state.dialog ? (
                                                            <ResultModal
                                                                visible={{ visible: true }}
                                                                handleCancel={this.recievedState}
                                                            />
                                                        ) : null}

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <!-- end row --> */}
                                </div>
                                {/* <!-- end container-fluid --> */}
                            </div>
                            {/* <!-- end app-main --> */}
                        </div>
                        {/* <!-- end app-container --> */}
                        <NewFooter />
                    </div>
                    {/* <!-- end app-wrap --> */}
                </div>
                {/* <!-- end app --> */}
            </body>
        )
    }
}
export default NewManageUsers