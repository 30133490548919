import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import functions from '../utils/functions'

class SideNav extends Component {

    render() {
        const permissionList = functions.permissionGuard()
        const roleList = functions.roleGuard()
        //console.log("kskskskss",roleList)
        return (
            <div>
                {/* <!-- begin app-nabar --> */}
                <aside class="app-navbar">
                    {/* <!-- begin sidebar-nav --> */}
                    <div class="sidebar-nav scrollbar scroll_light">
                        <ul class="metismenu " id="sidebarNav">
                        {functions.findInPermission(permissionList, 'RECEPTION-00') &&
                        <>
                            <li class="nav-static-title">Main Menu</li>
                            
                            <li class="active">
                                <a href="/">
                                    <i class="nav-icon ti ti-home"></i>
                                    <span class="nav-title">Dashboard</span>
                                    <span class="nav-label label label-danger">New</span>
                                </a>
                            </li>
                            </>
                            }
                            {/* {functions.findInPermission(permissionList, 'R-SESSION-02') &&
                                <li><a href="app-chat.html" aria-expanded="false"><i class="nav-icon ti ti-comment"></i><span class="nav-title">Chat</span></a> </li>
                            } */}
                            {functions.findInPermission(permissionList, 'R-SESSION-02') &&
                                <li><a class="has-arrow" href="javascript:void(0)" aria-expanded="false"><i class="fa fa-hospital-o"></i><span class="nav-title">Facilities</span></a>
                                    <ul aria-expanded="false">
                                        <li>
                                            <Link to={{ pathname: `/clinics-hospitals` }}>All Clinic / Hosptials</Link>
                                        </li>
                                        {/* {functions.findInPermission(permissionList, 'R-SESSION-02') &&
                                            <li> <a href='#'>Clinics</a> </li>
                                        } */}

                                    </ul>
                                </li>
                            }
                            {functions.findInPermission(permissionList, 'R-SESSION-02') &&
                                <li>
                                    <a class="has-arrow" href="javascript:void(0)" aria-expanded="false"><i class="fa fa-user"></i> <span class="nav-title">Physicians</span></a>
                                    <ul aria-expanded="false">
                                        <li> <Link to={{ pathname: `/physicians` }}>All Pysicians</Link> </li>
                                        <li> <a href="#">Accordions</a> </li>
                                    </ul>
                                </li>
                            }
                            {functions.findInPermission(permissionList, 'RECEPTION-00') &&
                                <li>
                                    <a class="has-arrow" href="javascript:void(0)" aria-expanded="false"><i class="fa fa-calendar"></i><span class="nav-title">Appointments</span> </a>
                                    <ul aria-expanded="false">
                                    {functions.findInPermission(permissionList, 'R-SESSION-02') &&
                                        <li> <Link to={{ pathname: `/appointments` }}>All Appointments</Link> </li>
                                    }
                                    {functions.findInPermission(permissionList, 'RECEPTION-00') &&
                                    <>
                                        <li> <Link to={{ pathname: `/pending-accept` }}>Not Accepted Appoin...</Link> </li>
                                        <li> <Link to={{ pathname: `/missed` }}>Missed Appointments</Link> </li>
                                        <li><Link to={{ pathname: `/completed` }}>Completed Appointme...</Link> </li>
                                    </>
                                    }
                                    </ul>
                                </li>
                            }

                            {functions.findInPermission(permissionList, 'R-SESSION-02') &&
                                <li>
                                    <a class="has-arrow" href="javascript:void(0)" aria-expanded="false"><i class="fa fa-user"></i> <span class="nav-title">Prescriptions</span></a>
                                    <ul aria-expanded="false">
                                        <li> <Link to={{ pathname: `/prescriptions-pending-approval` }}>Approve Prescription</Link> </li>
                                        <li> <Link to={{ pathname: `/prescriptions-approved` }}>Approved Prescription</Link> </li>
                                        <li> <Link to={{ pathname: `/prescriptions-declined` }}>Declined Prescription</Link> </li>
                                    </ul>
                                </li>
                            }

                            {functions.findInPermission(permissionList, 'R-SESSION-02') &&
                                <li>
                                    <a class="has-arrow" href="javascript:void(0)" aria-expanded="false"><i class="nav-icon ti ti-pie-chart"></i> <span class="nav-title">Medicine Requests</span></a>
                                    <ul aria-expanded="false">
    
                                        <li> <Link to={{ pathname: `/orders-pending-reviews` }}>Pending Orders</Link> </li>
                                        <li> <Link to={{ pathname: `/order-tracking`}}>Track Orders</Link> </li>
                                    </ul>
                                </li>
                            }

                            {functions.findInPermission(permissionList, 'R-SESSION-02') &&
                                <li class="nav-static-title">Order Billing</li>
                            }

                            {functions.findInPermission(permissionList, 'R-SESSION-02') &&
                                <li>
                                    <a class="has-arrow" href="javascript:void(0)" aria-expanded="false"><i class="nav-icon ti ti-pie-chart"></i><span class="nav-title">Billings</span></a>
                                    <ul aria-expanded="false">
                                        <li> <Link to={{ pathname: `/billing-orders`}}>Bill Order</Link> </li>
                                        {/* <li> <a href="#">Inbound</a> </li>
                                        <li> <a href="#">Outbound</a> </li>
                                        <li> <a href="#">Test One</a> </li>
                                        <li> <a href="#">Test Two</a> </li> */}
                                    </ul>
                                </li>
                            }

                            {functions.findInPermission(permissionList, 'R-SESSION-02') &&
                                <li class="nav-static-title">Order Processing</li>
                            }

                            {functions.findInPermission(permissionList, 'R-SESSION-02') &&
                                <li>
                                    <a class="has-arrow" href="javascript:void(0)" aria-expanded="false"><i class="nav-icon ti ti-pie-chart"></i><span class="nav-title">Park And Deliver</span></a>
                                    <ul aria-expanded="false">
                                        <li> <Link to={{ pathname: `/orders-parking`}}>Park & Dispatch Order</Link> </li>
                                        <li> <a href="#">Deliver Order</a> </li>
                                        {/* <li> <a href="#">Test One</a> </li>
                                        <li> <a href="#">Dispatch Order</a> </li>
                                        <li> <a href="#">Test Two</a> </li> */}
                                    </ul>
                                </li>
                            }

                            {functions.findInPermission(permissionList, 'R-SESSION-02') &&
                                <li class="nav-static-title">System & Settings</li>
                            }
                            {functions.findInPermission(permissionList, 'R-SESSION-02') &&
                                <li>
                                    <a class="has-arrow" href="javascript:void(0)" aria-expanded="false"> <i class="nav-icon ti ti-layout-grid4-alt"></i> <span class="nav-title">System Users</span> <span class="nav-label label label-success">New</span> </a>
                                    <ul aria-expanded="false">
                                        <li><Link to={{ pathname: `/manage-users` }}>All Users</Link> </li>
                                    </ul>
                                </li>
                            }
                            {functions.findInPermission(permissionList, 'R-SESSION-02') &&
                                <li>
                                    <a class="has-arrow" href="javascript:void(0)" aria-expanded="false"><i class="fa fa-bell"></i><span class="nav-title">Notifications</span></a>
                                    <ul aria-expanded="false">
                                        <li> <a href="#">System Notifications</a> </li>
                                        <li> <a href="#">User Notifications </a> </li>
                                        <li> <a href="#">Feedback Notifications</a> </li>
                                    </ul>
                                </li>
                            }

                            {functions.findInPermission(permissionList, 'R-SESSION-02') &&
                                <li>
                                    <a href="system-settings"><i class="nav-icon ti ti-layout"></i> <span class="nav-title">System Settings</span></a>
                                </li>
                            }

                            {functions.findInPermission(permissionList, 'R-SESSION-02') &&
                                <li class="nav-static-title">Finance & More</li>
                            }

                            {functions.findInPermission(permissionList, 'R-SESSION-02') &&
                                <li>
                                    <a class="has-arrow" href="javascript:void(0)" aria-expanded="false"><i class="nav-icon ti ti-pie-chart"></i><span class="nav-title">Finance</span></a>
                                    <ul aria-expanded="false">
                                        <li> <a href="#">Inbound</a> </li>
                                        <li> <a href="#">Outbound</a> </li>
                                        <li> <a href="#">Test One</a> </li>
                                        <li> <a href="#">Test Two</a> </li>
                                    </ul>
                                </li>
                            }
                            {functions.findInPermission(permissionList, 'RECEPTION-00') &&
                            <>
                                <li class="nav-static-title">System Support</li>
                                <li>
                                    <a class="has-arrow" href="javascript:void(0)" aria-expanded="false"><i class="nav-icon ti ti-layers">
                                        </i><span class="nav-title">Field Teams</span>{functions.findInPermission(permissionList, 'R-SESSION-02') &&<span class="nav-label label label-primary">12</span>}</a>
                                    <ul aria-expanded="false">
                                        <li> <a href="#">Contact Support</a> </li>
                                        {functions.findInPermission(permissionList, 'R-SESSION-02') &&
                                        <>
                                        <li> <a href="#">Support Two</a> </li>
                                        <li> <a href="#">Support Three</a> </li>
                                        </>
                                        }
                                    </ul>
                                </li>
                            </>
                            }
                        </ul>
                    </div>
                    {/* <!-- end sidebar-nav --> */}
                </aside>
                {/* <!-- end app-navbar --> */}
            </div>
        )
    }
}
export default SideNav