import React, { Component } from 'react'

class SideNavigation extends Component {

  render() {
    return (
<nav className="pcoded-navbar">
<div className="pcoded-inner-navbar main-menu">
<div className="pcoded-navigatio-lavel">Home</div>
<ul className="pcoded-item pcoded-left-item">
<li className="pcoded active pcoded-trigger">
<a href="/">
<span className="pcoded-micon"><i className="feather icon-home"></i></span>
<span className="pcoded-mtext">Dashboard</span>
</a>
</li>
<li className="pcoded-hasmenu">
<div className="pcoded-navigatio-lavel">Facilities And Physicians</div>
<a href="javascript:void(0)">
<span className="pcoded-micon"><i className="icofont icofont-hospital"></i></span>
<span className="pcoded-mtext">Clinics / Hospitals</span>
<span className="pcoded-badge label label-warning">NEW</span>
</a>
<ul className="pcoded-submenu">

{/* <li className=" pcoded-hasmenu">
<a href="javascript:void(0)">
<span className="pcoded-mtext">Horizontal</span>
</a>
<ul className="pcoded-submenu">
<li className=" ">
<a href="menu-horizontal-static.html" target="_blank">
<span className="pcoded-mtext">Static Layout</span>
</a>
</li>
<li className=" ">
<a href="menu-horizontal-fixed.html" target="_blank">
<span className="pcoded-mtext">Fixed layout</span>
</a>
</li>
<li className=" ">
<a href="menu-horizontal-icon.html" target="_blank">
<span className="pcoded-mtext">Static With Icon</span>
</a>
</li>
<li className=" ">
<a href="menu-horizontal-icon-fixed.html" target="_blank">
<span className="pcoded-mtext">Fixed With Icon</span>
</a>
</li>
</ul>
</li> */}
<li className=" ">
<a href="clinics-hospitals">
<span className="pcoded-mtext">All Clinincs / Hospitals</span>
</a>
</li>

{/* <li className=" ">
<a href="clinics-hospitals-categories">
<span className="pcoded-mtext">Clinincs / Hospital Categories</span>
</a>
</li> */}
</ul>
</li>

<li className="pcoded-hasmenu">
<a href="javascript:void(0)">
<span className="pcoded-micon"><i class="icofont icofont-doctor"></i></span>
<span className="pcoded-mtext">Physcians</span>
<span className="pcoded-badge label label-danger">200+</span>
</a>
<ul className="pcoded-submenu">

<li className=" ">
<a href="/physicians">
<span className="pcoded-mtext">All Pysicians</span>
</a>
</li>

{/* <li className=" ">
<a href="/add-speciality">
<span className="pcoded-mtext">Physician Specialities</span>
</a>
</li> */}
</ul>
</li>

<li className="pcoded-hasmenu">
<a href="javascript:void(0)">
<span className="pcoded-micon"><i className="icofont icofont-listing-box"></i></span>
<span className="pcoded-mtext">Appointments</span>
<span className="pcoded-badge label label-danger">100+</span>
</a>
<ul className="pcoded-submenu">
<li className=" ">
<a href="/appointments">
<span className="pcoded-mtext">All Appointments</span>
</a>
</li>
<li className=" ">
<a href="widget-data.html">
<span className="pcoded-mtext">Data</span>
</a>
</li>
<li className=" ">
<a href="widget-chart.html">
<span className="pcoded-mtext">Chart Widget</span>
</a>
</li>
</ul>
</li>
</ul>
<div className="pcoded-navigatio-lavel">System And Settings</div>
<ul className="pcoded-item pcoded-left-item">
<li className="pcoded-hasmenu">
<a href="javascript:void(0)">
<span className="pcoded-micon"><i className="icofont icofont-users-alt-4"></i></span>
<span className="pcoded-mtext">System Users</span>
</a>
<ul className="pcoded-submenu">
<li className=" ">
<a href="/manage-users">
<span className="pcoded-mtext">Manage Users </span>
</a>
</li>
<li className=" ">
<a href="/hospital-details">
<span className="pcoded-mtext">Hospital Details</span>
</a>
</li>
 <li className=" ">
<a href="button.html">
<span className="pcoded-mtext">Button</span>
</a>
</li>
{/* <li className=" ">
<a href="box-shadow.html">
<span className="pcoded-mtext">Box-Shadow</span>
</a>
</li>
<li className=" ">
<a href="accordion.html">
<span className="pcoded-mtext">Accordion</span>
</a>
</li>
<li className=" ">
<a href="generic-class.html">
<span className="pcoded-mtext">Generic Class</span>
</a>
</li>
<li className=" ">
<a href="tabs.html">
<span className="pcoded-mtext">Tabs</span>
</a>
</li>
<li className=" ">
<a href="color.html">
<span className="pcoded-mtext">Color</span>
</a>
</li>
<li className=" ">
<a href="label-badge.html">
<span className="pcoded-mtext">Label Badge</span>
</a>
</li>
<li className=" ">
<a href="progress-bar.html">
<span className="pcoded-mtext">Progress Bar</span>
</a>
</li>
<li className=" ">
<a href="preloader.html">
<span className="pcoded-mtext">Pre-Loader</span>
</a>
</li>
<li className=" ">
<a href="list.html">
<span className="pcoded-mtext">List</span>
</a>
</li>
<li className=" ">
<a href="tooltip.html">
<span className="pcoded-mtext">Tooltip And Popover</span>
</a>
</li>
<li className=" ">
<a href="typography.html">
<span className="pcoded-mtext">Typography</span>
</a>
</li>
<li className=" ">
<a href="other.html">
<span className="pcoded-mtext">Other</span>
</a>
</li> */}
 </ul>
</li>
<li className="pcoded-hasmenu">
<a href="javascript:void(0)">
<span className="pcoded-micon"><i className="icofont icofont-notification"></i></span>
<span className="pcoded-mtext">Notifications</span>
</a>
<ul className="pcoded-submenu">
{/* <li className=" ">
<a href="draggable.html">
<span className="pcoded-mtext">Draggable</span>
</a>
</li>
<li className=" ">
<a href="bs-grid.html">
<span className="pcoded-mtext">Grid Stack</span>
</a>
</li>
<li className=" ">
<a href="light-box.html">
<span className="pcoded-mtext">Light Box</span>
</a>
</li>
<li className=" ">
<a href="modal.html">
<span className="pcoded-mtext">Modal</span>
</a>
</li>
<li className=" ">
<a href="notification.html">
<span className="pcoded-mtext">Notifications</span>
</a>
</li> */}
<li className=" ">
<a href="notify.html">
<span className="pcoded-mtext">System Notifications</span>
{/* <span className="pcoded-badge label label-info">NEW</span> */}
</a>
</li>
<li className=" ">
<a href="rating.html">
<span className="pcoded-mtext">User Notifications</span>
</a>
</li>
<li className=" ">
<a href="range-slider.html">
<span className="pcoded-mtext">Engagement Notifications</span>
</a>
</li>
<li className=" ">
<a href="slider.html">
<span className="pcoded-mtext">Feedback Notifications</span>
</a>
</li>
{/* <li className=" ">
<a href="syntax-highlighter.html">
<span className="pcoded-mtext">Syntax Highlighter</span>
</a>
</li>
<li className=" ">
<a href="tour.html">
<span className="pcoded-mtext">Tour</span>
</a>
 </li>
<li className=" ">
<a href="treeview.html">
<span className="pcoded-mtext">Tree View</span>
</a>
</li>
<li className=" ">
<a href="nestable.html">
<span className="pcoded-mtext">Nestable</span>
</a>
</li>
<li className=" ">
<a href="toolbar.html">
<span className="pcoded-mtext">Toolbar</span>
</a>
</li>
<li className=" ">
<a href="x-editable.html">
<span className="pcoded-mtext">X-Editable</span>
</a>
</li> */}
</ul>
</li>

<li className="">
<a href="/system-settings">
<span className="pcoded-micon"><i class="icofont icofont-ui-settings"></i></span>
<span className="pcoded-mtext">System Settings</span>
</a>
</li>

{/* <li className="pcoded-hasmenu">
<a href="javascript:void(0)">
<span className="pcoded-micon"><i className="feather icon-package"></i></span>
<span className="pcoded-mtext">Extra Components</span>
</a>
<ul className="pcoded-submenu">
<li className=" ">
<a href="session-timeout.html">
<span className="pcoded-mtext">Session Timeout</span>
</a>
</li>
<li className=" ">
<a href="session-idle-timeout.html">
<span className="pcoded-mtext">Session Idle Timeout</span>
</a>
</li>
<li className=" ">
<a href="offline.html">
<span className="pcoded-mtext">Offline</span>
</a>
</li>
</ul>
</li>
<li className=" ">
<a href="animation.html">
<span className="pcoded-micon"><i className="feather icon-aperture rotate-refresh"></i><b>A</b></span>
<span className="pcoded-mtext">Animations</span>
</a>
</li>
<li className=" ">
<a href="sticky.html">
<span className="pcoded-micon"><i className="feather icon-cpu"></i></span>
<span className="pcoded-mtext">Sticky Notes</span>
<span className="pcoded-badge label label-danger">HOT</span>
</a>
</li>
<li className="pcoded-hasmenu">
<a href="javascript:void(0)">
<span className="pcoded-micon"><i className="feather icon-command"></i></span>
<span className="pcoded-mtext">Icons</span>
</a>
<ul className="pcoded-submenu">
<li className=" ">
<a href="icon-font-awesome.html">
<span className="pcoded-mtext">Font Awesome</span>
</a>
</li>
<li className=" ">
<a href="icon-themify.html">
<span className="pcoded-mtext">Themify</span>
</a>
</li>
<li className=" ">
<a href="icon-simple-line.html">
<span className="pcoded-mtext">Simple Line Icon</span>
</a>
</li>
<li className=" ">
<a href="icon-ion.html">
<span className="pcoded-mtext">Ion Icon</span>
</a>
</li>
<li className=" ">
<a href="icon-material-design.html">
<span className="pcoded-mtext">Material Design</span>
</a>
</li>
<li className=" ">
<a href="icon-icofonts.html">
<span className="pcoded-mtext">Ico Fonts</span>
</a>
</li>
<li className=" ">
<a href="icon-weather.html">
<span className="pcoded-mtext">Weather Icon</span>
</a>
</li>
<li className=" ">
<a href="icon-typicons.html">
<span className="pcoded-mtext">Typicons</span>
</a>
</li>
<li className=" ">
<a href="icon-flags.html">
<span className="pcoded-mtext">Flags</span>
</a>
</li>
</ul>
</li> */}
</ul>
{/* <div className="pcoded-navigatio-lavel">Forms</div>
<ul className="pcoded-item pcoded-left-item">
<li className="pcoded-hasmenu">
<a href="javascript:void(0)">
<span className="pcoded-micon"><i className="feather icon-clipboard"></i></span>
<span className="pcoded-mtext">Form Components</span>
</a>
<ul className="pcoded-submenu">
<li className=" ">
<a href="form-elements-component.html">
<span className="pcoded-mtext">Form Components</span>
</a>
</li>
 <li className=" ">
<a href="form-elements-add-on.html">
<span className="pcoded-mtext">Form-Elements-Add-On</span>
</a>
</li>
<li className=" ">
<a href="form-elements-advance.html">
<span className="pcoded-mtext">Form-Elements-Advance</span>
</a>
</li>
<li className=" ">
<a href="form-validation.html">
<span className="pcoded-mtext">Form Validation</span>
</a>
</li>
</ul>
</li>
<li className=" ">
<a href="form-picker.html">
<span className="pcoded-micon"><i className="feather icon-edit-1"></i></span>
<span className="pcoded-mtext">Form Picker</span>
<span className="pcoded-badge label label-warning">NEW</span>
</a>
</li>
<li className=" ">
<a href="form-select.html">
<span className="pcoded-micon"><i className="feather icon-feather"></i></span>
<span className="pcoded-mtext">Form Select</span>
</a>
</li>
<li className=" ">
<a href="form-masking.html">
<span className="pcoded-micon"><i className="feather icon-shield"></i></span>
<span className="pcoded-mtext">Form Masking</span>
</a>
</li>
<li className=" ">
<a href="form-wizard.html">
<span className="pcoded-micon"><i className="feather icon-tv"></i></span>
<span className="pcoded-mtext">Form Wizard</span>
</a>
</li>
<li className="pcoded-hasmenu">
<a href="javascript:void(0)">
<span className="pcoded-micon"><i className="feather icon-book"></i></span>
<span className="pcoded-mtext">Ready To Use</span>
<span className="pcoded-badge label label-danger">HOT</span>
</a>
<ul className="pcoded-submenu">
<li className=" ">
<a href="ready-cloned-elements-form.html">
<span className="pcoded-mtext">Cloned Elements Form</span>
</a>
</li>
<li className=" ">
<a href="ready-currency-form.html">
<span className="pcoded-mtext">Currency Form</span>
</a>
</li>
<li className=" ">
<a href="ready-form-booking.html">
 <span className="pcoded-mtext">Booking Form</span>
</a>
</li>
<li className=" ">
<a href="ready-form-booking-multi-steps.html">
<span className="pcoded-mtext">Booking Multi Steps Form</span>
</a>
</li>
<li className=" ">
<a href="ready-form-comment.html">
<span className="pcoded-mtext">Comment Form</span>
</a>
</li>
<li className=" ">
<a href="ready-form-contact.html">
<span className="pcoded-mtext">Contact Form</span>
</a>
</li>
<li className=" ">
<a href="ready-job-application-form.html">
<span className="pcoded-mtext">Job Application Form</span>
</a>
</li>
<li className=" ">
<a href="ready-js-addition-form.html">
<span className="pcoded-mtext">JS Addition Form</span>
</a>
</li>
<li className=" ">
<a href="ready-login-form.html">
<span className="pcoded-mtext">Login Form</span>
</a>
</li>
<li className=" ">
<a href="ready-popup-modal-form.html" target="_blank">
<span className="pcoded-mtext">Popup Modal Form</span>
</a>
</li>
<li className=" ">
<a href="ready-registration-form.html">
<span className="pcoded-mtext">Registration Form</span>
</a>
</li>
<li className=" ">
<a href="ready-review-form.html">
<span className="pcoded-mtext">Review Form</span>
</a>
</li>
<li className=" ">
<a href="ready-subscribe-form.html">
<span className="pcoded-mtext">Subscribe Form</span>
</a>
</li>
<li className=" ">
<a href="ready-suggestion-form.html">
<span className="pcoded-mtext">Suggestion Form</span>
</a>
</li>
<li className=" ">
<a href="ready-tabs-form.html">
<span className="pcoded-mtext">Tabs Form</span>
</a>
</li>
</ul>
</li>
</ul>
<div className="pcoded-navigatio-lavel">Tables</div>
<ul className="pcoded-item pcoded-left-item">
<li className="pcoded-hasmenu">
<a href="javascript:void(0)">
<span className="pcoded-micon"><i className="feather icon-credit-card"></i></span>
<span className="pcoded-mtext">Bootstrap Table</span>
</a>
<ul className="pcoded-submenu">
<li className=" ">
<a href="bs-basic-table.html">
<span className="pcoded-mtext">Basic Table</span>
</a>
</li>
<li className=" ">
<a href="bs-table-sizing.html">
<span className="pcoded-mtext">Sizing Table</span>
</a>
</li>
<li className=" ">
<a href="bs-table-border.html">
<span className="pcoded-mtext">Border Table</span>
</a>
</li>
<li className=" ">
<a href="bs-table-styling.html">
<span className="pcoded-mtext">Styling Table</span>
</a>
</li>
</ul>
</li>
<li className="pcoded-hasmenu">
<a href="javascript:void(0)">
<span className="pcoded-micon"><i className="feather icon-inbox"></i></span>
<span className="pcoded-mtext">Data Table</span>
<span className="pcoded-mcaret"></span>
</a>
<ul className="pcoded-submenu">
<li className=" ">
<a href="dt-basic.html">
<span className="pcoded-mtext">Basic Initialization</span>
</a>
</li>
<li className=" ">
<a href="dt-advance.html">
<span className="pcoded-mtext">Advance Initialization</span>
</a>
</li>
<li className=" ">
<a href="dt-styling.html">
<span className="pcoded-mtext">Styling</span>
</a>
</li>
<li className=" ">
<a href="dt-api.html">
<span className="pcoded-mtext">API</span>
</a>
</li>
<li className=" ">
 <a href="dt-ajax.html">
<span className="pcoded-mtext">Ajax</span>
</a>
</li>
<li className=" ">
<a href="dt-server-side.html">
<span className="pcoded-mtext">Server Side</span>
</a>
</li>
<li className=" ">
<a href="dt-plugin.html">
<span className="pcoded-mtext">Plug-In</span>
</a>
</li>
<li className=" ">
<a href="dt-data-sources.html">
<span className="pcoded-mtext">Data Sources</span>
</a>
</li>
</ul>
</li>
<li className="pcoded-hasmenu">
<a href="javascript:void(0)">
<span className="pcoded-micon"><i className="feather icon-server"></i></span>
<span className="pcoded-mtext">Data Table Extensions</span>
</a>
<ul className="pcoded-submenu">
<li className=" ">
<a href="dt-ext-autofill.html">
<span className="pcoded-mtext">AutoFill</span>
</a>
</li>
<li className="pcoded-hasmenu">
<a href="javascript:void(0)">
<span className="pcoded-mtext">Button</span>
</a>
<ul className="pcoded-submenu">
<li className=" ">
<a href="dt-ext-basic-buttons.html">
<span className="pcoded-mtext">Basic Button</span>
</a>
</li>
<li className=" ">
<a href="dt-ext-buttons-html-5-data-export.html">
<span className="pcoded-mtext">Html-5 Data Export</span>
</a>
</li>
</ul>
</li>
<li className=" ">
<a href="dt-ext-col-reorder.html">
<span className="pcoded-mtext">Col Reorder</span>
</a>
</li>
<li className=" ">
<a href="dt-ext-fixed-columns.html">
<span className="pcoded-mtext">Fixed Columns</span>
</a>
</li>
<li className=" ">
<a href="dt-ext-fixed-header.html">
 <span className="pcoded-mtext">Fixed Header</span>
</a>
</li>
<li className=" ">
<a href="dt-ext-key-table.html">
<span className="pcoded-mtext">Key Table</span>
</a>
</li>
<li className=" ">
<a href="dt-ext-responsive.html">
<span className="pcoded-mtext">Responsive</span>
</a>
</li>
<li className=" ">
<a href="dt-ext-row-reorder.html">
<span className="pcoded-mtext">Row Reorder</span>
</a>
</li>
<li className=" ">
<a href="dt-ext-scroller.html">
<span className="pcoded-mtext">Scroller</span>
</a>
</li>
<li className=" ">
<a href="dt-ext-select.html">
<span className="pcoded-mtext">Select Table</span>
</a>
</li>
</ul>
</li>
<li className=" ">
<a href="foo-table.html">
<span className="pcoded-micon"><i className="feather icon-hash"></i></span>
<span className="pcoded-mtext">FooTable</span>
</a>
</li>
<li className="pcoded-hasmenu ">
<a href="javascript:void(0)">
<span className="pcoded-micon"><i className="feather icon-airplay"></i></span>
<span className="pcoded-mtext">Handson Table</span>
</a>
<ul className="pcoded-submenu">
<li className="">
<a href="handson-appearance.html">
<span className="pcoded-mtext">Appearance</span>
</a>
</li>
<li className="">
<a href="handson-data-operation.html">
<span className="pcoded-mtext">Data Operation</span>
</a>
</li>
<li className="">
<a href="handson-rows-cols.html">
<span className="pcoded-mtext">Rows Columns</span>
</a>
</li>
<li className="">
<a href="handson-columns-only.html">
<span className="pcoded-mtext">Columns Only</span>
</a>
</li>
<li className="">
 <a href="handson-cell-features.html">
<span className="pcoded-mtext">Cell Features</span>
</a>
</li>
<li className="">
<a href="handson-cell-types.html">
<span className="pcoded-mtext">Cell Types</span>
</a>
</li>
<li className="">
<a href="handson-integrations.html">
<span className="pcoded-mtext">Integrations</span>
</a>
</li>
<li className="">
<a href="handson-rows-only.html">
<span className="pcoded-mtext">Rows Only</span>
</a>
</li>
<li className="">
<a href="handson-utilities.html">
<span className="pcoded-mtext">Utilities</span>
</a>
</li>
</ul>
</li>
<li className="">
<a href="editable-table.html">
<span className="pcoded-micon"><i className="feather icon-edit"></i></span>
<span className="pcoded-mtext">Editable Table</span>
</a>
</li>
</ul>
<div className="pcoded-navigatio-lavel">Chart And Maps</div>
<ul className="pcoded-item pcoded-left-item">
<li className="pcoded-hasmenu ">
<a href="javascript:void(0)">
<span className="pcoded-micon"><i className="feather icon-pie-chart"></i></span>
<span className="pcoded-mtext">Charts</span>
</a>
<ul className="pcoded-submenu">
<li className="">
<a href="chart-google.html">
<span className="pcoded-mtext">Google Chart</span>
</a>
</li>
<li className="">
<a href="chart-echart.html">
<span className="pcoded-mtext">Echarts</span>
</a>
</li>
<li className="">
<a href="chart-chartjs.html">
<span className="pcoded-mtext">ChartJs</span>
</a>
</li>
<li className="">
<a href="chart-list.html">
<span className="pcoded-mtext">List Chart</span>
</a>
</li>
<li className="">
<a href="chart-float.html">
 <span className="pcoded-mtext">Float Chart</span>
</a>
</li>
<li className="">
<a href="chart-knob.html">
<span className="pcoded-mtext">Knob chart</span>
</a>
</li>
<li className="">
<a href="chart-morris.html">
<span className="pcoded-mtext">Morris Chart</span>
</a>
</li>
<li className="">
<a href="chart-nvd3.html">
<span className="pcoded-mtext">Nvd3 Chart</span>
</a>
</li>
<li className="">
<a href="chart-peity.html">
<span className="pcoded-mtext">Peity Chart</span>
</a>
</li>
<li className="">
<a href="chart-radial.html">
<span className="pcoded-mtext">Radial Chart</span>
</a>
</li>
<li className="">
<a href="chart-rickshaw.html">
<span className="pcoded-mtext">Rickshaw Chart</span>
</a>
</li>
<li className="">
<a href="chart-sparkline.html">
<span className="pcoded-mtext">Sparkline Chart</span>
</a>
</li>
<li className="">
<a href="chart-c3.html">
<span className="pcoded-mtext">C3 Chart</span>
</a>
</li>
</ul>
</li>
<li className="pcoded-hasmenu ">
<a href="javascript:void(0)">
<span className="pcoded-micon"><i className="feather icon-map"></i></span>
<span className="pcoded-mtext">Maps</span>
</a>
<ul className="pcoded-submenu">
<li className="">
<a href="map-google.html">
<span className="pcoded-mtext">Google Maps</span>
</a>
</li>
<li className="">
<a href="map-vector.html">
<span className="pcoded-mtext">Vector Maps</span>
</a>
</li>
<li className="">
<a href="map-api.html">
<span className="pcoded-mtext">Google Map Search API</span>
</a>
</li>
<li className="">
<a href="location.html">
<span className="pcoded-mtext">Location</span>
</a>
</li>
</ul>
</li>
<li className="">
<a href="https://colorlib.com//polygon/adminty/files/extra-pages/landingpage/index.html" target="_blank">
<span className="pcoded-micon"><i className="feather icon-navigation-2"></i></span>
<span className="pcoded-mtext">Landing Page</span>
</a>
</li>
</ul>
<div className="pcoded-navigatio-lavel">Pages</div>
<ul className="pcoded-item pcoded-left-item">
<li className="pcoded-hasmenu ">
<a href="javascript:void(0)">
<span className="pcoded-micon"><i className="feather icon-unlock"></i></span>
<span className="pcoded-mtext">Authentication</span>
</a>
<ul className="pcoded-submenu">
<li className="">
<a href="auth-normal-sign-in.html" target="_blank">
<span className="pcoded-mtext">Login With BG Image</span>
</a>
</li>
<li className="">
<a href="auth-sign-in-social.html" target="_blank">
<span className="pcoded-mtext">Login With Social Icon</span>
</a>
</li>
<li className="">
<a href="auth-sign-in-social-header-footer.html" target="_blank">
<span className="pcoded-mtext">Login Social With Header And Footer</span>
</a>
</li>
<li className="">
<a href="auth-normal-sign-in-header-footer.html" target="_blank">
<span className="pcoded-mtext">Login With Header And Footer</span>
</a>
</li>
<li className="">
<a href="auth-sign-up.html" target="_blank">
<span className="pcoded-mtext">Registration BG Image</span>
</a>
</li>
<li className="">
<a href="auth-sign-up-social.html" target="_blank">
<span className="pcoded-mtext">Registration Social Icon</span>
</a>
</li>
<li className="">
<a href="auth-sign-up-social-header-footer.html" target="_blank">
<span className="pcoded-mtext">Registration Social With Header And Footer</span>
</a>
 </li>
<li className="">
<a href="auth-sign-up-header-footer.html" target="_blank">
<span className="pcoded-mtext">Registration With Header And Footer</span>
</a>
</li>
<li className="">
<a href="auth-multi-step-sign-up.html" target="_blank">
<span className="pcoded-mtext">Multi Step Registration</span>
</a>
</li>
<li className="">
<a href="auth-reset-password.html" target="_blank">
<span className="pcoded-mtext">Forgot Password</span>
</a>
</li>
<li className="">
<a href="auth-lock-screen.html" target="_blank">
<span className="pcoded-mtext">Lock Screen</span>
</a>
</li>
<li className="">
<a href="auth-modal.html" target="_blank">
<span className="pcoded-mtext">Modal</span>
</a>
</li>
</ul>
</li>
<li className="pcoded-hasmenu ">
<a href="javascript:void(0)">
<span className="pcoded-micon"><i className="feather icon-sliders"></i></span>
<span className="pcoded-mtext">Maintenance</span>
</a>
<ul className="pcoded-submenu">
<li className="">
<a href="error.html">
<span className="pcoded-mtext">Error</span>
</a>
</li>
<li className="">
<a href="comming-soon.html">
<span className="pcoded-mtext">Comming Soon</span>
</a>
</li>
<li className="">
<a href="offline-ui.html">
<span className="pcoded-mtext">Offline UI</span>
</a>
</li>
</ul>
</li>
<li className="pcoded-hasmenu ">
<a href="javascript:void(0)">
<span className="pcoded-micon"><i className="feather icon-users"></i></span>
<span className="pcoded-mtext">User Profile</span>
</a>
<ul className="pcoded-submenu">
<li className="">
<a href="timeline.html">
<span className="pcoded-mtext">Timeline</span>
</a>
 </li>
<li className="">
<a href="timeline-social.html">
<span className="pcoded-mtext">Timeline Social</span>
</a>
</li>
<li className="">
<a href="user-profile.html">
<span className="pcoded-mtext">User Profile</span>
</a>
</li>
<li className="">
<a href="user-card.html">
<span className="pcoded-mtext">User Card</span>
</a>
</li>
</ul>
</li>
<li className="pcoded-hasmenu ">
<a href="javascript:void(0)">
<span className="pcoded-micon"><i className="feather icon-shopping-cart"></i></span>
<span className="pcoded-mtext">E-Commerce</span>
<span className="pcoded-badge label label-danger">NEW</span>
</a>
<ul className="pcoded-submenu">
<li className="">
<a href="product.html">
<span className="pcoded-mtext">Product</span>
</a>
</li>
<li className="">
<a href="product-list.html">
<span className="pcoded-mtext">Product List</span>
</a>
</li>
<li className="">
<a href="product-edit.html">
<span className="pcoded-mtext">Product Edit</span>
</a>
</li>
<li className="">
<a href="product-detail.html">
<span className="pcoded-mtext">Product Detail</span>
</a>
</li>
<li className="">
<a href="product-cart.html">
<span className="pcoded-mtext">Product Card</span>
</a>
</li>
<li className="">
<a href="product-payment.html">
<span className="pcoded-mtext">Credit Card Form</span>
</a>
</li>
</ul>
</li>
<li className="pcoded-hasmenu ">
<a href="javascript:void(0)">
<span className="pcoded-micon"><i className="feather icon-mail"></i></span>
<span className="pcoded-mtext">Email</span>
</a>
<ul className="pcoded-submenu">
<li className="">
<a href="email-compose.html">
<span className="pcoded-mtext">Compose Email</span>
</a>
</li>
<li className="">
<a href="email-inbox.html">
<span className="pcoded-mtext">Inbox</span>
</a>
</li>
<li className="">
<a href="email-read.html">
<span className="pcoded-mtext">Read Mail</span>
</a>
</li>
<li className="pcoded-hasmenu ">
<a href="javascript:void(0)">
<span className="pcoded-mtext">Email Template</span>
</a>
<ul className="pcoded-submenu">
<li className="">
<a href="https://colorlib.com//polygon/adminty/files/extra-pages/email-templates/email-welcome.html">
<span className="pcoded-mtext">Welcome Email</span>
</a>
</li>
<li className="">
<a href="https://colorlib.com//polygon/adminty/files/extra-pages/email-templates/email-password.html">
<span className="pcoded-mtext">Reset Password</span>
</a>
</li>
<li className="">
<a href="https://colorlib.com//polygon/adminty/files/extra-pages/email-templates/email-newsletter.html">
<span className="pcoded-mtext">Newsletter Email</span>
</a>
</li>
<li className="">
<a href="https://colorlib.com//polygon/adminty/files/extra-pages/email-templates/email-launch.html">
<span className="pcoded-mtext">App Launch</span>
</a>
</li>
<li className="">
<a href="https://colorlib.com//polygon/adminty/files/extra-pages/email-templates/email-activation.html">
<span className="pcoded-mtext">Activation Code</span>
</a>
</li>
</ul>
</li>
</ul>
</li>
</ul>
<div className="pcoded-navigatio-lavel">App</div>
<ul className="pcoded-item pcoded-left-item">
<li className=" ">
<a href="chat.html">
<span className="pcoded-micon"><i className="feather icon-message-square"></i></span>
<span className="pcoded-mtext">Chat</span>
</a>
</li>
<li className="pcoded-hasmenu ">
<a href="javascript:void(0)">
<span className="pcoded-micon"><i className="feather icon-globe"></i></span>
<span className="pcoded-mtext">Social</span>
</a>
<ul className="pcoded-submenu">
<li className="">
<a href="fb-wall.html">
<span className="pcoded-mtext">Wall</span>
</a>
</li>
<li className="">
<a href="message.html">
<span className="pcoded-mtext">Messages</span>
</a>
</li>
</ul>
</li>
<li className="pcoded-hasmenu ">
<a href="javascript:void(0)">
<span className="pcoded-micon"><i className="feather icon-check-circle"></i></span>
<span className="pcoded-mtext">Task</span>
</a>
<ul className="pcoded-submenu">
<li className="">
<a href="task-list.html">
<span className="pcoded-mtext">Task List</span>
</a>
</li>
<li className="">
<a href="task-board.html">
<span className="pcoded-mtext">Task Board</span>
</a>
</li>
<li className="">
<a href="task-detail.html">
<span className="pcoded-mtext">Task Detail</span>
</a>
</li>
<li className="">
<a href="issue-list.html">
<span className="pcoded-mtext">Issue List</span>
</a>
</li>
</ul>
</li>
<li className="pcoded-hasmenu ">
<a href="javascript:void(0)">
<span className="pcoded-micon"><i className="feather icon-bookmark"></i></span>
<span className="pcoded-mtext">To-Do</span>
</a>
<ul className="pcoded-submenu">
<li className="">
<a href="todo.html">
<span className="pcoded-mtext">To-Do</span>
</a>
</li>
<li className="">
<a href="notes.html">
<span className="pcoded-mtext">Notes</span>
</a>
</li>
</ul>
</li>
<li className="pcoded-hasmenu ">
 <a href="javascript:void(0)">
<span className="pcoded-micon"><i className="feather icon-image"></i></span>
<span className="pcoded-mtext">Gallery</span>
</a>
<ul className="pcoded-submenu">
<li className="">
<a href="gallery-grid.html">
<span className="pcoded-mtext">Gallery-Grid</span>
</a>
</li>
<li className="">
<a href="gallery-masonry.html">
<span className="pcoded-mtext">Masonry Gallery</span>
</a>
</li>
<li className="">
<a href="gallery-advance.html">
<span className="pcoded-mtext">Advance Gallery</span>
</a>
</li>
</ul>
</li>
<li className="pcoded-hasmenu ">
<a href="javascript:void(0)">
<span className="pcoded-micon"><i className="feather icon-search"></i><b>S</b></span>
<span className="pcoded-mtext">Search</span>
</a>
<ul className="pcoded-submenu">
<li className="">
<a href="search-result.html">
<span className="pcoded-mtext">Simple Search</span>
</a>
</li>
<li className="">
<a href="search-result2.html">
<span className="pcoded-mtext">Grouping Search</span>
</a>
</li>
</ul>
</li>
<li className="pcoded-hasmenu ">
<a href="javascript:void(0)">
<span className="pcoded-micon"><i className="feather icon-award"></i></span>
<span className="pcoded-mtext">Job Search</span>
<span className="pcoded-badge label label-danger">NEW</span>
</a>
<ul className="pcoded-submenu">
<li className="">
<a href="job-card-view.html">
<span className="pcoded-mtext">Card View</span>
</a>
</li>
<li className="">
<a href="job-details.html">
<span className="pcoded-mtext">Job Detailed</span>
</a>
</li>
<li className="">
<a href="job-find.html">
<span className="pcoded-mtext">Job Find</span>
</a>
</li>
 <li className="">
<a href="job-panel-view.html">
<span className="pcoded-mtext">Job Panel View</span>
</a>
</li>
</ul>
</li>
</ul>
<div className="pcoded-navigatio-lavel">Extension</div>
<ul className="pcoded-item pcoded-left-item">
<li className="pcoded-hasmenu ">
<a href="javascript:void(0)">
<span className="pcoded-micon"><i className="feather icon-file-plus"></i></span>
<span className="pcoded-mtext">Editor</span>
</a>
<ul className="pcoded-submenu">
<li className="">
<a href="ck-editor.html">
<span className="pcoded-mtext">CK-Editor</span>
</a>
</li>
<li className="">
<a href="wysiwyg-editor.html">
<span className="pcoded-mtext">WYSIWYG Editor</span>
</a>
</li>
<li className="">
<a href="ace-editor.html">
<span className="pcoded-mtext">Ace Editor</span>
</a>
</li>
<li className="">
<a href="long-press-editor.html">
<span className="pcoded-mtext">Long Press Editor</span>
</a>
</li>
</ul>
</li>
</ul>
<ul className="pcoded-item pcoded-left-item">
<li className="pcoded-hasmenu ">
<a href="javascript:void(0)">
<span className="pcoded-micon"><i className="feather icon-file-minus"></i></span>
<span className="pcoded-mtext">Invoice</span>
</a>
<ul className="pcoded-submenu">
<li className="">
<a href="invoice.html">
<span className="pcoded-mtext">Invoice</span>
</a>
</li>
<li className="">
<a href="invoice-summary.html">
<span className="pcoded-mtext">Invoice Summary</span>
</a>
</li>
<li className="">
<a href="invoice-list.html">
<span className="pcoded-mtext">Invoice List</span>
</a>
</li>
</ul>
</li>
<li className="pcoded-hasmenu ">
<a href="javascript:void(0)">
 <span className="pcoded-micon"><i className="feather icon-calendar"></i></span>
<span className="pcoded-mtext">Event Calendar</span>
</a>
<ul className="pcoded-submenu">
<li className="">
<a href="event-full-calender.html">
<span className="pcoded-mtext">Full Calendar</span>
</a>
</li>
<li className="">
<a href="event-clndr.html">
<span className="pcoded-mtext">CLNDER</span>
<span className="pcoded-badge label label-info">NEW</span>
</a>
</li>
</ul>
</li>
<li className="">
<a href="image-crop.html">
<span className="pcoded-micon"><i className="feather icon-scissors"></i></span>
<span className="pcoded-mtext">Image Cropper</span>
</a>
</li>
<li className="">
<a href="file-upload.html">
<span className="pcoded-micon"><i className="feather icon-upload-cloud"></i></span>
<span className="pcoded-mtext">File Upload</span>
</a>
</li>
<li className="">
<a href="change-loges.html">
<span className="pcoded-micon"><i className="feather icon-briefcase"></i><b>CL</b></span>
<span className="pcoded-mtext">Change Loges</span>
</a>
</li>
</ul>
<div className="pcoded-navigatio-lavel">Other</div>
<ul className="pcoded-item pcoded-left-item">
<li className="pcoded-hasmenu ">
<a href="javascript:void(0)">
<span className="pcoded-micon"><i className="feather icon-list"></i></span>
<span className="pcoded-mtext">Menu Levels</span>
</a>
<ul className="pcoded-submenu">
<li className="">
<a href="javascript:void(0)">
<span className="pcoded-mtext">Menu Level 2.1</span>
</a>
</li>
<li className="pcoded-hasmenu ">
<a href="javascript:void(0)">
<span className="pcoded-mtext">Menu Level 2.2</span>
</a>
<ul className="pcoded-submenu">
<li className="">
<a href="javascript:void(0)">
<span className="pcoded-mtext">Menu Level 3.1</span>
</a>
</li>
</ul>
 </li>
<li className="">
<a href="javascript:void(0)">
<span className="pcoded-mtext">Menu Level 2.3</span>
</a>
</li>
</ul>
</li>
<li className="">
<a href="javascript:void(0)" className="disabled">
<span className="pcoded-micon"><i className="feather icon-power"></i></span>
<span className="pcoded-mtext">Disabled Menu</span>
</a>
</li>
<li className="">
<a href="sample-page.html">
<span className="pcoded-micon"><i className="feather icon-watch"></i></span>
<span className="pcoded-mtext">Sample Page</span>
</a>
</li>
</ul> */}

<div className="pcoded-navigatio-lavel">FInance and More</div>
<ul className="pcoded-item pcoded-left-item">
<li className="pcoded-hasmenu">
<a href="javascript:void(0)">
<span className="pcoded-micon"><i className="icofont icofont-coins"></i></span>
<span className="pcoded-mtext">FInancials</span>
</a>
<ul className="pcoded-submenu">
<li className=" ">
<a href="alert.html">
<span className="pcoded-mtext">Current </span>
</a>
</li>
<li className=" ">
<a href="alert.html">
<span className="pcoded-mtext">Current </span>
</a>
</li>
<li className=" ">
<a href="breadcrumb.html">
<span className="pcoded-mtext">History</span>
</a>
</li>
<li className=" ">
<a href="breadcrumb.html">
<span className="pcoded-mtext">Resolve issue</span>
</a>
</li>
</ul>
</li>
</ul>

<div className="pcoded-navigatio-lavel">Support</div>
<ul className="pcoded-item pcoded-left-item">
<li className="">
<a href="http://html.codedthemes.com/Adminty/doc" target="_blank">
<span className="pcoded-micon"><i className="feather icon-monitor"></i></span>
<span className="pcoded-mtext">Documentation</span>
</a>
</li>
<li className="">
<a href="#" target="_blank">
<span className="pcoded-micon"><i className="feather icon-help-circle"></i></span>
<span className="pcoded-mtext">Submit Issue</span>
</a>
</li>
</ul>
</div>
</nav>)}}
export default SideNavigation