import React, { Component } from 'react'
import Notification from '../../common/Notification'
import Loader from '../../common/Loader'
import ajax from '../../utils/ajax'
import functions from '../../utils/functions'


class ResetPassword extends Component {

    state = {
        confirmPassword: '',
        password: '',
        type: '',
        info: '',
        load: '',
    }

    onPressReset = async (event) => {
        event.preventDefault();
        const { password, confirmPassword } = this.state;

        if (
            password.length > 0 &&
            confirmPassword.length > 0
        ) {

            if (password !== confirmPassword) {
                this.setState({
                    type: 'alert alert-danger',
                    info: "Passwords Don't match",
                    load: '',
                })
            } else {

                this.setState({
                    type: '',
                    info: '',
                    load: <Loader />
                })

                const server_response = await ajax.resetPassword(password);
                if (server_response.status === "OK") {
                    localStorage.removeItem('equiva@user');
                    window.location.replace('/login');

                } else {
                    this.setState({
                        type: 'alert alert-danger',
                        info: server_response.details.message,
                        load: '',
                    })
                }
            }
        } else {
            this.setState({
                type: 'alert alert-danger',
                info: 'Both password fields Required',
                load: '',
            })
        }


    }


    onChangePassword = (event) => {
        this.setState({ password: event.target.value });
    }

    onConfirmPassword = (event) => {
        this.setState({ confirmPassword: event.target.value });
    }

    render() {
        return (

            <body class="fix-menu">

                <div class="theme-loader">
                    <div class="ball-scale">
                        <div class='contain'>
                            <div class="ring"><div class="frame"></div></div>
                            <div class="ring"><div class="frame"></div></div>
                            <div class="ring"><div class="frame"></div></div>
                            <div class="ring"><div class="frame"></div></div>
                            <div class="ring"><div class="frame"></div></div>
                            <div class="ring"><div class="frame"></div></div>
                            <div class="ring"><div class="frame"></div></div>
                            <div class="ring"><div class="frame"></div></div>
                            <div class="ring"><div class="frame"></div></div>
                            <div class="ring"><div class="frame"></div></div>
                        </div>
                    </div>
                </div>

                <section class="login-block">

                    <div class="container">
                        <div class="row">
                            <div class="col-sm-12">

                                <form class="md-float-material form-material" method="post" onSubmit={this.onPressReset}>
                                    <div class="text-center">
                                        <img src="assets/images/logo.png" alt="logo.png" />
                                    </div>
                                    <div class="auth-box card">
                                        <div class="card-block">
                                            <div class="row m-b-20">
                                                <div class="col-md-12">
                                                    <h3 class="text-center">Reset Password</h3>
                                                </div>
                                            </div>

                                            {this.state.load}
                                            <Notification
                                                message={{
                                                    type: this.state.type,
                                                    info: this.state.info,
                                                }}
                                            />

                                            <div class="form-group form-primary">
                                                <input type="password" name="password" onChange={this.onChangePassword} class="form-control" required="" placeholder="New Password" />
                                                <span class="form-bar"></span>
                                            </div>
                                            <div class="form-group form-primary">
                                                <input type="password" name="password" onChange={this.onConfirmPassword} class="form-control" required="" placeholder="Confirm Password" />
                                                <span class="form-bar"></span>
                                            </div>

                                            <div class="row m-t-30">
                                                <div class="col-md-12">
                                                    <button type="submit" class="btn btn-primary btn-md btn-block waves-effect waves-light text-center m-b-20">Reset</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>

                            </div>

                        </div>

                    </div>

                </section>

            </body>

        )
    }
}

export default ResetPassword




