import React, { Component } from 'react'
import { Modal} from 'antd'
import 'antd/dist/antd.css'; // or 'antd/dist/antd.less'
import Notification from '../Notification'
import PropagateLoader from '../SubmitingForm'
import ajax from '../../utils/ajax'
import functions from '../../utils/functions'


class EditHospitalCategory extends Component {
  state = {
    userId: functions.sessionGuard(),
    categoryName:"",
    categoryCode:"",
    categoryId:"",
  }

  componentDidMount(){
    this.setState({
      categoryName:this.props.visible.categoryName,
      categoryCode:this.props.visible.categoryCode,
      categoryId:this.props.visible.categoryId,
    })
    
  }

  handleEditCancel = () => {
    const sent = this.props
    this.setState({ visible: false })
    sent.handleEditCancel(false)
  }


  categoryName = (value)=> {
    this.setState({ categoryName: value.target.value })
  }

  categoryCode = (value)=> {
    this.setState({ categoryCode: value.target.value })
  }

  onPressEditCategory= async (event)=>{
    event.preventDefault()
    const { categoryName,categoryCode,categoryId} = this.state

    if (categoryName.length > 0 && 
      categoryCode.length >0 && 
      categoryId.length > 0 ) {
        this.setState({
          type: '',
          info: '',
          load:<PropagateLoader/>
        })

          //fetch category from the API
          const server_response = await ajax.editCategory(categoryName, categoryCode,this.state.userId,this.state.categoryId)

          if (server_response.status === "OK") {
            this.setState({
              type: 'alert alert-success',
              info: server_response.details.message,
              load: '',
            })
            window.location.reload()

        } else {

          this.setState({
            type: 'alert alert-danger',
            info: server_response.details.message,
            load: '',
          })

        }


      }else{
        this.setState({
          type: 'alert alert-danger',
          info: 'Please be advised to fill in all required fields - All fields are required',
          load:'',
        })
      }
  }


  render() {
    return (

        <Modal
        title = {"EDIT CLINIC - HOSPTIAL CATEGORY"}
        centered
        bodyStyle={{ paddingBottom: '0px', paddingTop: '0px' }}
        visible={this.props.visible.visible}
        maskClosable={false}
        footer={[null]}
        maskTransitionName=""
        onCancel={this.handleEditCancel}
        width={700}
        okButtonProps={{ disabled: true }}
        cancelButtonProps={{ disabled: false }}
      >

<br/>
<div class="row">
<div class="col-sm-12 mobile-inputs">

{this.state.load}
  <Notification
        message={{
          type: this.state.type,
          info: this.state.info,
        }}
    />

<form method="post" onSubmit={this.onPressEditCategory}>
<div class="form-group row">
<div class="col-sm-6">
<input type="text" onChange={this.categoryName} value={this.state.categoryName} class="form-control form-txt-primary" />
</div>
<div class="col-sm-6">
<input type="text" onChange={this.categoryCode} value={this.state.categoryCode} class="form-control form-txt-primary"/>
</div>
</div>

<div class="form-group row">
<div class="col-sm-6">
</div>

</div>
<div class="row">
<div class="col-sm-4 card-header-right" >
<button type="submit" class="btn btn-primary btn-block mb-3">EDIT Hospital Category</button>
 </div>
 </div>
</form>
</div>
</div>


     
      </Modal>

    )}}

    export default EditHospitalCategory