import React, { Component } from 'react'
// import './App.css';
import Router from './Routes/Routed'


class App extends Component {
  render() {
    return <Router basename="/" />
  }
}

export default App
