import React, { Component } from 'react'
import { Modal, Select, Table } from 'antd'
import 'antd/dist/antd.css'; // or 'antd/dist/antd.less'
import ajax from '../../utils/ajax';
import PropagateLoader from '../SubmitingForm'
import functions from '../../utils/functions';
import Notification from '../Notification'

const { Option } = Select;

class EditPhysician extends Component {

  state = {
    userId: functions.sessionGuard(),
    specialityList: false,
    hospitalList: false,
    specialistsList: false,
    specialist_id:"",
    speciality: "",
    hospital: "",
    firstName: "",
    lastName: "",
    workHours: "",
    email: "",
    telephone: "",
    load: ""
  }

  componentDidMount() {
    this.getHospitals();
    this.getSpecialities();
    this.setState({
      workHours: this.props.visible.workHours,
      firstName: this.props.visible.firstName,
      lastName: this.props.visible.lastName,
      email: this.props.visible.email,
      telephone: this.props.visible.phone,
      specialist_id: this.props.visible.actioned
    })
  }

  handleEditCancel = () => {
    const sent = this.props
    this.setState({ visible: false })
    sent.handleEditCancel(false)
  }

  firstName = (value) => {
    this.setState({ firstName: value.target.value })
  }
  lastName = (value) => {
    this.setState({ lastName: value.target.value })
  }
  email = (value) => {
    this.setState({ email: value.target.value })
  }
  telephone = (value) => {
    this.setState({ telephone: value.target.value })
  }

  workHours = (value) => {
    this.setState({ workHours: value.target.value })
  }


  hospital = (value) => {
    if (value != null) {
      this.setState({ hospital: value })
    } else {
      this.setState({ hospital: "" })
    }

  }

  speciality = (value) => {
    if (value != null) {
      this.setState({ speciality: value })
    } else {
      this.setState({ speciality: "" })
    }

  }

  getHospitals = async () => {

    const server_response = await ajax.listHospitals();
    const hosOptions = []

    if (server_response.status === "OK") {

      server_response.details.content.list.map((hospitals) =>
        hosOptions.push(<Option value={hospitals.hospital_id}>{hospitals.full_hospital_name}</Option>
        ),
      )

      this.setState({
        hospital: this.props.visible.hospital,
        hospitalsList: hosOptions
      })

    } else {
      // this.setState({
      //     categoryList: "404"
      // })
    }

  }

  getSpecialities = async () => {
    const server_response = await ajax.listSpeciality();
    const speOptions = []

    if (server_response.status === "OK") {
      // console.log(server_response.details.content)
      server_response.details.content.map((speciality) =>
        speOptions.push(<Option value={speciality.speciality_id}>{speciality.speciality_name}</Option>
        ),
      )

      this.setState({
        speciality: this.props.visible.speciality,
        specialistsList: speOptions
      })

    } else {
      // this.setState({
      //     categoryList: "404"
      // })
    }
  }

  onPressAddSpecialist = async (event) => {
    event.preventDefault()

    const { speciality, hospital, firstName, lastName, workHours, email, telephone,specialist_id } = this.state

    //console.log(speciality, hospital, firstName, lastName, workHours, email, telephone,specialist_id)

    if (speciality.length > 0 &&
      hospital.length > 0 &&
      firstName.length > 0 &&
      lastName.length > 0 &&
      workHours.length > 0 &&
      email.length > 0 &&
      specialist_id.length >0 &&
      telephone.length > 0) {

      this.setState({
        type: '',
        info: '',
        load: <PropagateLoader />
      })

      const server_response = await ajax.editSpecialist(speciality, hospital, firstName, lastName, workHours, email, telephone, this.state.userId,specialist_id)

      if (server_response.status === "OK") {
        this.setState({
          type: 'alert alert-success',
          info: server_response.details.message,
          load: '',
        })
        window.location.reload()

      } else {

        this.setState({
          type: 'alert alert-danger',
          info: server_response.details.message,
          load: '',
        })

      }


    } else {
      this.setState({
        type: 'alert alert-danger',
        info: 'Please be advised to fill in all required fields - All fields are required',
        load: "",
      })
    }
  }

  render() {
    return (

      <Modal
        title={"EDIT PHYSICIAN DOCTOR INFORAMATION"}
        centered
        bodyStyle={{ paddingBottom: '0px', paddingTop: '0px' }}
        visible={this.props.visible.visible}

        maskClosable={false}
        footer={[null]}
        maskTransitionName=""
        onCancel={this.handleEditCancel}
        width={700}
        okButtonProps={{ disabled: true }}
        cancelButtonProps={{ disabled: false }}
      >

        <br />
        <div class="row">
          <div class="col-sm-12 mobile-inputs">

            {this.state.load}
            <Notification
              message={{
                type: this.state.type,
                info: this.state.info,
              }}
            />

            <form method="post" onSubmit={this.onPressAddSpecialist}>
              <div class="form-group row">
                <div class="col-sm-6">
                  <input type="text" onChange={this.firstName} value={this.state.firstName} class="form-control form-txt-primary" placeholder="First Name" />
                </div>
                <div class="col-sm-6">
                  <input type="text" onChange={this.lastName} value={this.state.lastName} class="form-control form-txt-primary" placeholder="Last Name" />
                </div>
              </div>

              <div class="form-group row">
                <div class="col-sm-6">
                  <input type="text" onChange={this.email} value={this.state.email} class="form-control form-txt-primary" placeholder="E-Mail" />
                </div>
                <div class="col-sm-6">
                  <input type="text" onChange={this.telephone} value={this.state.telephone} class="form-control form-txt-primary" placeholder="Telephone" />
                </div>
              </div>

              <div class="form-group row">
                <div class="col-sm-6">
                  <Select
                    style={{ width: '100%' }}
                    placeholder="Select Clinic - Hospital"
                    value={this.state.hospital}
                    onChange={this.hospital}>
                    <option> Select Clinic - Hospital </option>
                    {this.state.hospitalsList}

                  </Select>
                </div>
                <div class="col-sm-6">
                  <Select
                    style={{ width: '100%' }}
                    placeholder="Select Speciality"
                    value={this.state.speciality}
                    onChange={this.speciality}>
                    <option> Select Speciality </option>
                    {this.state.specialistsList}

                  </Select>
                </div>
              </div>

              <div class="form-group row">
                <div class="col-sm-6">
                  <input type="text" onChange={this.workHours} class="form-control form-txt-primary" value={this.state.workHours} placeholder="Working Hours" />
                </div>

              </div>
              <div class="row">
                <div class="col-sm-4" >
                  <button type="submit" class="btn btn-primary btn-block mb-3">Edit Physician / Specialist</button>
                </div>
              </div>
            </form>
          </div>
        </div>


      </Modal>

    )
  }
}

export default EditPhysician