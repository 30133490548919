import React, { Component } from 'react'
import { Modal, Select, Table, DatePicker } from 'antd'
import 'antd/dist/antd.css'; // or 'antd/dist/antd.less'
import ajax from '../../utils/ajax'
import functions from '../../utils/functions'
import PropagateLoader from '../../common/SubmitingForm'
import Notification from '../../common/Notification'

const { Option } = Select;
class AddSystemUser extends Component {

  state = {
    userRolesList: false,
    hospitalsList: false,
    firstName: "",
    lastName: "",
    userName: "",
    telephone: "",
    userMail: "",
    profilePic: "",
    userFacility: "",
    userRole: "",
    fileName:""
  }

  componentDidMount() {
    this.fetchUserRoles()
    this.fetchFacilities()
  }

  handleCancel = () => {
    const sent = this.props
    this.setState({ visible: false })
    sent.handleCancel(false)
  }

  fetchFacilities = async () => {

    const server_response = await ajax.listHospitals();
    const hosOptions = []

    if (server_response.status === "OK") {

      server_response.details.content.list.map((hospitals) =>
        hosOptions.push(<Option value={hospitals.hospital_id}>{hospitals.full_hospital_name}</Option>
        ),
      )

      this.setState({
        hospitalsList: hosOptions
      })

    } else {
      // this.setState({
      //     categoryList: "404"
      // })
    }

  }

  fetchUserRoles = async () => {
    const server_response = await ajax.getRoles();
    const userRoles = []

    if (server_response.status === "OK") {
      server_response.details.content.list.map((roles) =>
        userRoles.push(<Option value={roles.role_id}>{roles.role_name}</Option>
        ),
      )
      this.setState({
        userRolesList: userRoles
      })

    } else {
      // this.setState({
      //     categoryList: "404"
      // })
    }
  }

  firstName = (value) => {
    this.setState({ firstName: value.target.value })
  }

  lastName = (value) => {
    this.setState({ lastName: value.target.value })
  }

  userName = (value) => {
    this.setState({ userName: value.target.value })
  }

  telephone = (value) => {
    this.setState({ telephone: value.target.value })
  }

  userMail = (value) => {
    this.setState({ userMail: value.target.value })
  }

  profilePicture = (value) => {
    //console.log("opopoppspsps", value.target.value)
    this.setState({
      fileName: value.target.value
    })
    let files = value.target.files;
    let reader = new FileReader();
    reader.readAsDataURL(files[0]);
    //console.log("opopoppspsps",files[0])
    reader.onload = (e) => {
      //console.log("opopoppspsps",e.target.result)
      this.setState({
        profilePic: e.target.result
      })
    }
  }

  handleChangeFacility = (value) => {
    if (value != null) {
      this.setState({ userFacility: value })
    } else {
      this.setState({
        userFacility: ""
      })
    }
  }

  handleChangeRole = (value) => {
    if (value != null) {
      this.setState({ userRole: value })
    } else {
      this.setState({
        userRole: ""
      })
    }
  }

  onPressAddSystemUser = async (event) => {
    event.preventDefault()

    const { firstName, lastName, userName, telephone, userMail, userFacility, userRole,profilePic } = this.state

    if (firstName.length > 0 &&
      lastName.length > 0 &&
      userName.length > 0 &&
      telephone.length > 0 &&
      userMail.length > 0 &&
      userFacility.length > 0 &&
      userRole.length > 0) {

      this.setState({
        type: '',
        info: '',
        load: <PropagateLoader />
      })

      const server_response = await ajax.createUser(firstName, lastName, userName, telephone, userMail, userFacility, userRole,profilePic, this.state.userId)

      if (server_response.status === "OK") {
        this.setState({
          type: 'alert alert-success',
          info: server_response.details.message,
          load: '',
        })
        window.location.reload()

      } else {

        this.setState({
          type: 'alert alert-danger',
          info: server_response.details.message,
          load: '',
        })

      }


    } else {
      this.setState({
        type: 'alert alert-danger',
        info: 'Please be advised to fill in all required fields - All fields are required',
        load: "",
      })
    }
  }

  render() {
    return (

      <Modal
        title={"ADD NEW SYSTEM USER"}
        centered
        bodyStyle={{ paddingBottom: '0px', paddingTop: '0px' }}
        visible={this.props.visible.visible}
        maskClosable={false}
        footer={[null]}
        maskTransitionName=""
        onCancel={this.handleCancel}
        width={700}
        okButtonProps={{ disabled: true }}
        cancelButtonProps={{ disabled: false }}
      >

        <br />
        <div class="row">
          <div class="col-sm-12 mobile-inputs">

            {this.state.load}
            <Notification
              message={{
                type: this.state.type,
                info: this.state.info,
              }}
            />

            <br />

            <form method="post" onSubmit={this.onPressAddSystemUser}>
              <div class="form-group row">
                <div class="col-sm-6">
                  <input type="text" class="form-control form-txt-primary" onChange={this.firstName} placeholder="First Name" required />
                </div>
                <div class="col-sm-6">
                  <input type="text" class="form-control form-txt-primary" onChange={this.lastName} placeholder="Last Name" required />
                </div>
              </div>

              <div class="form-group row">
                <div class="col-sm-6">
                  <input type="text" class="form-control form-txt-primary" onChange={this.userName} placeholder="Username" required />
                </div>
                <div class="col-sm-6">
                  <div class="custom-file">
                    <input type="file" class="custom-file-input" onChange={this.profilePicture} />
                    <label class="custom-file-label" for="inputGroupFile01">Choose file</label>
                  </div>

                </div>
              </div>

              <div class="form-group row">
                <div class="col-sm-6">
                  <input type="text" class="form-control form-txt-primary" onChange={this.telephone} placeholder="Telephone" required />
                </div>
                <div class="col-sm-6">
                  <input type="text" class="form-control form-txt-primary" onChange={this.userMail} placeholder="E-Mail" required />
                </div>
              </div>

              <div class="form-group row">
                <div class="col-sm-6">
                  <Select placeholder="Select Facility" style={{ width: '100%' }} onChange={this.handleChangeFacility}>
                    <Option value="">Select Facility</Option>
                    {this.state.hospitalsList}
                  </Select>
                </div>

                <div class="col-sm-6">
                  <Select placeholder="Select User Role" style={{ width: '100%' }} onChange={this.handleChangeRole}>
                    <Option value="">Select User Role</Option>
                    {this.state.userRolesList}
                  </Select>
                </div>
              </div>

              <div class="row">
                <div class="col-sm-3" >
                  <button type="submit" class="btn btn-primary btn-block mb-3">Add New User</button>
                </div>
              </div>
            </form>
          </div>
        </div>



      </Modal>

    )
  }
}

export default AddSystemUser