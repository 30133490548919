import React, { Component } from 'react'
import { Modal, Select, Table, DatePicker } from 'antd'
import 'antd/dist/antd.css'; // or 'antd/dist/antd.less'
import Notification from '../Notification'
import functions from '../../utils/functions';

import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'


const { Option } = Select;

class ReviewPrescriptionSuccess extends Component {

  state = {
    userId: functions.sessionGuard(),
    appointmentTimeList: false,
    reviewComment: "",
    prescriptionId: "",
    load: '',
    visible:false, 
    setVisible:false,
    approveState:true,
    verifyStatus:"",
    requestStatus:""

  }

  componentDidMount() {
    this.setState({
      prescriptionImage: this.props.visible.presImage 
    })
  }

  handleCancel = () => {
    const sent = this.props
    this.setState({ visible: false })
    sent.handleCancel(false)
  }
  setVisible = ()=>{
    this.setState({
      visible:true, setVisible:true
    })
  }


  render() {
    const {visible, setVisible } = this.state
    return (
      <>
      <Modal
        title={"REVIEW SUBMITTED PRESCRIPTION IMAGE"}
        centered
        bodyStyle={{ paddingBottom: '0px', paddingTop: '0px' }}
        visible={this.props.visible.visible}
        maskClosable={false}
        footer={[null]}
        maskTransitionName=""
        onCancel={this.handleCancel}
        width={600}
        okButtonProps={{ disabled: true }}
        cancelButtonProps={{ disabled: false }}
      >

        <br />
        <div class="row">



          <div class="col-sm-12 mobile-inputs">
            {this.state.load}
            <Notification
              message={{
                type: this.state.type,
                info: this.state.info,
              }}
            />

            <form >

              <div class="form-group row">
                <div class="col-sm-12">
                <Zoom>
                  <img
                    alt="prescription preview"
                    // src="http://159.65.199.43/img/prescription/img_1658100296.png"
                    src={this.state.prescriptionImage}
                    width="550"
                    height="400"
                  />
                </Zoom>
                </div>
              </div>
            </form>
          </div>
        </div>

      </Modal>
      </>

    )
  }
}

export default ReviewPrescriptionSuccess