import React, { Component } from 'react'
import { Modal, Select, Table, DatePicker } from 'antd'
import moment from 'moment';
import 'antd/dist/antd.css'; // or 'antd/dist/antd.less'
import Notification from '../Notification'
import PropagateLoader from '../SubmitingForm'
import ajax from '../../utils/ajax';
import functions from '../../utils/functions';


const { Option } = Select;

class UpdateHospitalPaymentMethods extends Component {

  state = {
    userId: functions.sessionGuard(),
    appointmentTimeList: false,
    rescheduleReasonList: false,
    selectedAppointmentDate: "",
    selectedSpecialist: "",
    prefTimeId: "",
    scheduleComment: "",
    appointmentId: "",
    insuranceList: false,
    hospitalSelectPayment:false,
    payment:false,
    load: '',
    serviceList: false,
  }

  componentDidMount() {
     //console.log("ooooooooooooooooo",this.props.visible)
    this.setState({
      payment:this.props.visible.payment,
      hospitalId: this.props.visible.hospitalId,
    })
    this.getInsurance()
  }

  selectedPaymets = () => {
    const seletedPya = []
    this.props.visible.payment.map((service) =>
    seletedPya.push(service.payment_method_id),
  )
  this.setState({
    hospitalSelectPayment: seletedPya
  })
  }

  getInsurance = async () => {
    const server_response = await ajax.listPaymentMethods();
    const payOptions = []

    if (server_response.status === "OK") {

      this.selectedPaymets()

      server_response.details.content.map((payment) =>
        payOptions.push(<Option value={payment.payment_method_id}>{payment.payment_method_name}</Option>
        ),
      )
      this.setState({
        insuranceList: payOptions
      })

    } else {
      // this.setState({
      //     categoryList: "404"
      // })
    }
  }

  handleCancel = () => {
    const sent = this.props
    this.setState({ visible: false })
    sent.handleCancel(false)
  }



  onPressRescheduleAppointment = async (event) => {
    event.preventDefault()
    const { selectedAppointmentDate, prefTimeId, scheduleComment, selectedSpecialist, serviceId, hospitalId, clientId, appointmentId } = this.state

    if (prefTimeId.length > 0 &&
      selectedAppointmentDate.length > 0 &&
      prefTimeId.length > 0 &&
      selectedSpecialist.length > 0 &&
      scheduleComment.length > 0) {

      this.setState({
        type: '',
        info: '',
        load: <PropagateLoader />
      })

      //console.log(selectedAppointmentDate+" date "+ prefTimeId+" time "+scheduleComment+" comment "+selectedSpecialist+" doc +appointmentId+" aappointment")

      const server_response = await ajax.rescheduleAppointment(selectedAppointmentDate, prefTimeId, scheduleComment, selectedSpecialist, appointmentId, this.state.userId)

      if (server_response.status === "OK") {
        this.setState({
          type: 'alert alert-success',
          info: server_response.details.message,
          load: '',
        })
        window.location.reload()

      } else {

        this.setState({
          type: 'alert alert-danger',
          info: server_response.details.message,
          load: '',
        })

      }

    } else {
      this.setState({
        type: 'alert alert-danger',
        info: 'Please be advised to fill in all required fields - All fields are required',
        load: '',
      })
    }


  }

  render() {

    return (

      <Modal
        title={"UPDATE HOSPITAL PAYMENT METHODS "}
        centered
        bodyStyle={{ paddingBottom: '0px', paddingTop: '0px' }}
        visible={this.props.visible.visible}
        maskClosable={false}
        footer={[null]}
        maskTransitionName=""
        onCancel={this.handleCancel}
        width={700}
        okButtonProps={{ disabled: true }}
        cancelButtonProps={{ disabled: false }}
      >

        <br />
        <div class="row">

          <div class="col-sm-12 mobile-inputs">
            {this.state.load}
            <Notification
              message={{
                type: this.state.type,
                info: this.state.info,
              }}
            />
            <br />

            <form method="post" onSubmit={this.onPressRescheduleAppointment}>

              <div class="form-group">

                <Select
                  mode="multiple"
                  name="hospitalServices"
                  style={{ width: '100%' }}
                  placeholder="Select Payment Method"
                  value={this.state.hospitalSelectPayment}
                  onChange={this.hospitalServices}>
                  {this.state.insuranceList}

                </Select>
              </div>
              <div class="row">
                <div class="col-sm-4 card-header-right" >
                  <button type="submit" class="btn btn-primary btn-block mb-3">Update Payment Method</button>
                </div>
              </div>
            </form>
          </div>
        </div>



      </Modal>

    )
  }
}

export default UpdateHospitalPaymentMethods