import React, { Component } from 'react'
import { Modal, Select, Table, Button, Form, Input, Popconfirm } from 'antd'

//import React, { useContext, useEffect, useRef, useState } from 'react';

import 'antd/dist/antd.css'; // or 'antd/dist/antd.less'
import Notification from '../Notification'
import PropagateLoader from '../SubmitingForm'
import ajax from '../../utils/ajax';
import functions from '../../utils/functions';
import Viewer from 'react-viewer';

import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'


const { Option } = Select;

class BillOrders extends Component {

  state = {
    userId: functions.sessionGuard(),
    appointmentTimeList: false,
    load: '',
    visible: false,
    billAmount: "",
    rows: [],
    totalAmount:"00",
    unitts:"0",
    amount:"0",
    totalPrices:"0"
  }



  handleChange = idx => e => {
    const { name, value } = e.target;
    const rows = [...this.state.rows];

    rows[idx] = {
      [name]: value
    };

    this.setState({
      rows
    });

    //console.log("rrrrrrrrrrrrsiisierer",rows[idx].quantity)

    if(name === "quantity"){
      this.setState({unitts:value,amount:0})
      // this.state.totalAmount = (this.state.amount * value)
      rows[idx].amounts = (this.state.amount * value)

      // console.log("rrrrrrrrrrrrsiisierer",name+" "+value)
    }else if(name === "unitPrice"){
      this.setState({amount:value})
      // this.state.totalAmount = (this.state.unitts * value)
      rows[idx].amounts = (this.state.unitts * value)
      //console.log("fffffffffsisiisierer",name+" "+value)
    }
  };

  // handleBlur = () =>{
  //   this.setState({amount:""}) 
  // }

  // if(name === "quantity"){
  //   this.setState({unitts:value})
  //   //this.state.totalAmount = (this.state.amount * value)
  //   rows[idx].amounts = (this.state.amount * value)
    
  //   // console.log("rrrrrrrrrrrrsiisierer",name+" "+value)
  // }else if(name === "unitPrice"){
  //   this.setState({amount:value})
  //   rows[idx].amounts = (this.state.unitts * value)
  //   //console.log("fffffffffsisiisierer",name+" "+value)
  // }

  handleAddRow = () => {
    const item = {
      itemName:"",
      quantity:"",
      unitPrice:"",
      amounts:""
    };
    this.setState({
      rows: [...this.state.rows, item]
    });
  };
  handleRemoveRow = () => {
    this.setState({
      rows: this.state.rows.slice(0, -1)
    });
  };

  componentDidMount() {
    this.setState({
      prescriptionImage: this.props.visible.presImage
    })
  }

  handleCancel = () => {
    const sent = this.props
    this.setState({ visible: false })
    sent.handleCancel(false)
  }
  setVisible = () => {
    this.setState({
      visible: true, setVisible: true
    })
  }

  billAmount = (value) => {
    this.setState({ billAmount: value.target.value })
  }

  onPressBillOrder = async (event) => {
    event.preventDefault()
    //this.props.visible.orderId + "# "+this.props.visible.clientId
    const { billAmount } = this.state

    if (this.props.visible.clientId.length > 0 || this.props.visible.orderId.length > 0 || billAmount.length > 0) {

      this.setState({
        type: '',
        info: '',
        load: <PropagateLoader />
      })

      const server_response = await ajax.billPrescription(this.props.visible.clientId, this.props.visible.orderId, billAmount, this.state.userId)

      if (server_response.status === "OK") {
        this.setState({
          type: 'alert alert-success',
          info: server_response.details.message,
          load: '',
        })
        window.location.reload()

      } else {

        this.setState({
          type: 'alert alert-danger',
          info: server_response.details.message,
          load: '',
        })

      }

    } else {
      this.setState({
        type: 'alert alert-danger',
        info: 'Please be advised to fill in all required fields - All fields are required',
        load: '',
      })
    }


  }

  render() {
    const { visible, setVisible } = this.state
    return (
      <>
        <Modal
          title={"VIEW PRESCRIPTION IMAGE AND BILL"}
          centered
          bodyStyle={{ paddingBottom: '0px', paddingTop: '0px' }}
          visible={this.props.visible.visible}
          maskClosable={false}
          footer={[null]}
          maskTransitionName=""
          onCancel={this.handleCancel}
          width={900}
          height={500}
          okButtonProps={{ disabled: true }}
          cancelButtonProps={{ disabled: false }}
        >

          <br />
          {/* <div class="row"> */}
            <div class="col-sm-12">
              {this.state.load}
              {/* <Notification
                message={{
                  type: this.state.type,
                  info: this.state.info,
                }}
              /> */}
              <br />
              <form method="post" onSubmit={this.onPressBillOrder}>

                <div class="form-group row">
                  <div class="col-sm-12">
                    <Zoom>
                      <img
                        alt="prescription preview"
                        // src="http://159.65.199.43/img/prescription/img_1658100296.png"
                        src={this.state.prescriptionImage}
                        width="150"
                        height="100"
                      />
                    </Zoom>
                  </div>
                </div>

                {/* <!-- begin row --> */}
                <div class="row">
                  <div class="col-lg-12">
                    <div class="card card-statistics">
                      <div class="card-body">

                        <div class="datatable-wrapper">

                          <table
                            className="table table-bordered table-hover"
                            id="tab_logic"
                          >
                            <thead>
                              <tr>
                                <th className="text-center"> # </th>
                                <th className="text-center"> Item Name </th>
                                <th className="text-center"> Item Quantity </th>
                                <th className="text-center"> Unit Price </th>
                                <th className="text-center"> Amount </th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.rows.map((item, idx) => (
                                <tr id="addr0" key={idx}>
                                  <td>{idx}</td>
                                  <td>
                                    <input
                                      type="text"
                                      name="itemName"
                                      value={this.state.rows[idx].itemName}
                                      onChange={this.handleChange(idx)}
                                      className="form-control"
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="number"
                                      name="quantity"
                                      value={this.state.rows[idx].quantity}
                                      onChange={this.handleChange(idx)}
                                      className="form-control"
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="number"
                                      name="unitPrice"
                                      value={this.state.rows[idx].unitPrice}
                                      onChange={this.handleChange(idx)}
                                      //onBlur={this.handleBlur()}
                                      className="form-control"
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      // disabled
                                      name="amount"
                                      value={this.state.rows[idx].amounts}
                                      onChange={this.handleChange(idx)}
                                      className="form-control"
                                    />
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                            <thead>
                              <tr>
                                <th className="text-center">#</th>
                                <th className="text-center" colSpan={3} style={{ textAlign: 'left' }}> Total Amount </th>
                                <th className="text-center" disabled style={{ border: "2px solid" }}> {this.state.totalAmount} </th>
                              </tr>
                            </thead>
                          </table>

                          <br />
                          <div class="form-group row" >
                            <div class="col-sm-4 card-header-right" >
                              <button
                                onClick={this.handleAddRow}
                                type="none"
                                className="btn btn-success btn-block mb-2">
                                Add Bill Item
                              </button>
                            </div>
                            <div class="col-sm-4 card-header-right" >
                              <button
                                onClick={this.handleRemoveRow}
                                className="btn btn-danger btn-block mb-2">Delete Bill Item
                              </button>
                            </div>
                            <div class="col-sm-4 card-header-right" >
                              <button type="submit" class="btn btn-primary btn-block mb-2">Accept bill amount</button>
                            </div>

                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <!-- end row --> */}
              </form>
            </div>
          {/* </div> */}

        </Modal>
      </>

    )
  }
}

export default BillOrders