import React, { Component } from 'react'
import { Table, Tooltip } from 'antd'
import { Link } from 'react-router-dom'
import Preloader from '../../common/Preloader'
import NewHeader from '../../common/NewHeader'
import SideNav from '../../common/SideNav'
import ResultModal from '../../common/Modals/AddAppointment'
import RescheduleAppointment from '../../common/Modals/RescheduleAppointment'
import EditResultModal from '../../common/Modals/EditHospitalPayment'
import moment from 'moment'
import PropagateLoader from '../../common/SubmitingForm'
import SweetAlert from 'react-bootstrap-sweetalert'
import NewFooter from '../../common/NewFooter'
import functions from '../../utils/functions'
import ajax from '../../utils/ajax';


class NewAppointments extends Component {

    state = {
        userId: functions.sessionGuard(),
        dialog: false,
        reschedule: false,
        appointmentsList: false,
        sweetAlert: false,
        sweetAlertConfirm: false,
        prefTimeIdentity: "",
        prefDateEdit: "",
        appointmentId: "",
        confirmError: "Patient Apppointment will be scheduled !",
        confirmSuccess: "Appointment accepted",
        appointmentId: "",
        serviceId: "",
        hospitalId: "",
        specialistId: "",
        clientId: "",
        fullhospitalName: "",
        fullhospitalId: "",
        load: "",
        loadError: "Please use the cancel button otherwise"
    }

    componentDidMount() {
        this.getAllAppointmentList();
        this.countFinishedAppointments();
        this.countMissedAppointments();
        this.countAppointments();
        this.countNotAcceptanceAppointments();
    }

    onClickAppointment = (event) => {
        this.setState({ dialog: true })
    }

    onClickReAppointment = (preId, preDate, service, hospital, specailist, client, appointment) => {
        this.setState({
            reschedule: true,
            prefTimeIdentity: preId,
            prefDateEdit: preDate,
            serviceId: service,
            hospitalId: hospital,
            specialistId: specailist,
            clientId: client,
            appointmentId: appointment
        })
    }

    recievedState = (data) => {
        this.setState({ dialog: data })
    }

    recievedStateReschedule = (data) => {
        this.setState({ reschedule: data })
    }

    confirmAppointment = (key) => {
        this.setState({
            appointmentId: key,
            sweetAlert: true
        })
    }

    onCancelAttend = () => {
        this.setState({
            load: "",
            workState: false,
            loadError: "Please use the cancel button otherwise"
        })
    }

    onClickAttendence = (appointment) => {
        this.setState({
            workState: true,
            appointmentId: appointment
        })
    }

    onMarkComplete = async () => {

        const { appointmentId } = this.state

        if (appointmentId.length > 0) {

            this.setState({
                load: <PropagateLoader />
            })

            const server_response = await ajax.markAppointmentComplete(appointmentId, this.state.userId)

            if (server_response.status === "OK") {

                this.setState({
                    workState: false,
                    sweetAlertConfirm: true,
                    confirmSuccess: server_response.details.message,
                })

            } else {
                //console.log("hjhjhjhjhjhjhjhjjh",server_response)
                this.setState({
                    load: "",
                    loadError: server_response.details.message
                })
            }

        } else {

            this.setState({
                workState: false,
                sweetAlertConfirm: true,
                confirmError: "#####  Please select appointment to process  #####"
            })

        }

    }

    onMarkMissed = async () => {

        const { appointmentId } = this.state

        if (appointmentId.length > 0) {

            this.setState({
                load: <PropagateLoader />
            })

            const server_response = await ajax.markAppointmentMissed(appointmentId, this.state.userId)

            if (server_response.status === "OK") {
                this.setState({
                    workState: false,
                    load: "",
                    sweetAlertConfirm: true,
                    confirmSuccess: server_response.details.message,
                })

            } else {
                //console.log("hjhjhjhjhjhjhjhjjh",server_response)
                this.setState({
                    load: "",
                    loadError: server_response.details.message
                })
            }

        } else {

            this.setState({
                load: "",
                confirmError: "#####  Please select appointment to process  #####"
            })

        }

    }

    okAcceptance = (key) => {
        window.location.reload()
    }

    onConfirmAppointment = async () => {

        const { appointmentId } = this.state

        if (appointmentId.length > 0) {

            const server_response = await ajax.acceptClientAppointment(appointmentId)

            if (server_response.status === "OK") {
                this.setState({
                    sweetAlert: false,
                    sweetAlertConfirm: true,
                    confirmSuccess: server_response.details.message,
                })

            } else {
                this.setState({
                    confirmError: server_response.details.message
                })
            }

        } else {

            this.setState({
                confirmError: "#####  Please select appointment to process  #####"
            })

        }

    }

    onCancelConfirm = () => {
        this.setState({
            sweetAlert: false,
            confirmError: "Patient Apppointment will be scheduled !",
        })
    }

    countFinishedAppointments = async () => {

        const server_response = await ajax.countFinishedAppointment();

        if (server_response.status === "OK") {

            this.setState({
                completed: server_response.details.content.total_c
            })

        } else {
            // this.setState({
            //     categoryList: "404"
            // })
        }
    }

    countMissedAppointments = async () => {

        const server_response = await ajax.countMissedAppointment();

        if (server_response.status === "OK") {

            this.setState({
                missedApps: server_response.details.content.total_c
            })

        } else {
            // this.setState({
            //     categoryList: "404"
            // })
        }
    }

    countNotAcceptanceAppointments = async () => {

        const server_response = await ajax.countNotAcceptedAppointment();

        if (server_response.status === "OK") {

            this.setState({
                pendingAccept: server_response.details.content.total_c
            })

        } else {
            // this.setState({
            //     categoryList: "404"
            // })
        }
    }

    countAppointments = async () => {

        const server_response = await ajax.countAppointments();

        if (server_response.status === "OK") {

            this.setState({
                appointments: server_response.details.content.total_c
            })

        } else {
            // this.setState({
            //     categoryList: "404"
            // })
        }
    }

    getAllAppointmentList = async () => {
        const server_response = await ajax.listAllAppointments();
        const tableData = []


        if (server_response.status === "OK") {

            console.log("ioioio", server_response.details.content)

            server_response.details.content.map((appointment) =>
                tableData.push({
                    hospitalName: appointment.appointment_hospital.full_hospital_name,
                    hospitalId: appointment.appointment_hospital.hospital_id,
                    serviceName: appointment.appointment_service.service_name,
                    serviceId: appointment.appointment_service.service_id,
                    clientName: appointment.appointment_client.full_name,
                    clientId: appointment.appointment_client.client_id,
                    clientPhone: appointment.appointment_client.phone_number,
                    appointmentSpecialistId: appointment.appointment_specialist,
                    prefDate: appointment.appointment_date.short_date,
                    prefTime: appointment.appointment_time.appointment_time,
                    prefTimeId: appointment.appointment_time.id,
                    appointmentId: appointment.appointment_id,
                    when: appointment.when_dates.period,
                    appointmentStatus: appointment.appointment_accept_status,
                    attendStatus: appointment.appointment_attend_status,
                    mstatus: appointment.miss_status

                }),
            )
            this.setState({
                appointmentsList: tableData
            })

        } else {
            // this.setState({
            //     categoryList: "404"
            // })
        }
    }

    render() {

        const columns = [
            {
                title: 'Hospital / Clinic',
                dataIndex: 'hospitalName',
            },
            {
                title: 'Service Name',
                dataIndex: 'serviceName',
            },
            {
                title: 'ServiceId',
                dataIndex: 'serviceId',
                hidden: true
            },
            {
                title: 'm status',
                dataIndex: 'mstatus',
                hidden: true
            },
            {
                title: 'appointmentSpecialist',
                dataIndex: 'appointmentSpecialistId',
                hidden: true
            },
            {
                title: 'Client Name',
                dataIndex: 'clientName',
            },
            {
                title: 'ClientIds',
                dataIndex: 'clientId',
                hidden: true
            },
            {
                title: 'Client Phone',
                dataIndex: 'clientPhone',
            },
            {
                title: 'Preffered Date',
                dataIndex: 'prefDate',
            },
            {
                title: 'Preffered Time',
                dataIndex: 'prefTime',
            },
            {
                title: 'Preffered Time id',
                dataIndex: 'prefTimeId',
                hidden: true
            },
            {
                title: 'When',
                dataIndex: 'when',
            },
            {
                title: 'Status',
                dataIndex: 'appointmentStatus',
            },
            {
                title: 'Attend Status',
                dataIndex: 'attendStatus',
            },
            {
                title: 'appointmentId',
                dataIndex: 'appointmentId',
                hidden: true
            },
            {
                title: 'Actions - [ Approve , Re-Schedule ]',
                dataIndex: 'actioned',

                className: 'bg-transparent text-center',
                render: (text, records, index) => {
                    return (
                        <div className="text-nowrap">
                            <Tooltip
                                placement="leftTop"
                                title="Confirm Appointment"
                                color="#454B1B"
                                key="#f50"
                                style={{ margin: "10px" }}
                            >
                                <button
                                    type="button"
                                    className="btn btn-inverse-light"
                                    style={{ marginInlineEnd: "10px" }}
                                    onClick={() => this.confirmAppointment(records.appointmentId)}

                                >
                                    <i className="fa fa-check" />
                                </button>
                            </Tooltip>

                            <Tooltip
                                placement="leftTop"
                                title="Edit Appointment"
                                color="#454B1B"
                                key="#f50"
                                style={{ margin: "10px" }}
                            >
                                <button
                                    type="button"
                                    className="btn btn-inverse-light"
                                    style={{ marginInlineEnd: "10px" }}
                                    onClick={() => this.onClickReAppointment(records.prefTimeId, records.prefDate, records.serviceId, records.hospitalId, records.appointmentSpecialistId, records.clientId, records.appointmentId)}

                                >
                                    <i className="fa fa-edit" />
                                </button>
                            </Tooltip>

                            <Tooltip
                                placement="leftTop"
                                title="Attendence"
                                color="bg-green"
                                key="#f50"
                            >
                                <button
                                    type="button"
                                    className="btn btn-inverse-light"
                                    onClick={() => this.onClickAttendence(records.appointmentId)}
                                >
                                    <i className="fa fa-list" />
                                </button>
                            </Tooltip>

                        </div>
                    )
                }
            },

        ].filter(item => !item.hidden)

        return (
            <body>
                {/* <!-- begin app --> */}
                <div class="app">
                    {/* <!-- begin app-wrap --> */}
                    <div class="app-wrap">
                        {/* <!-- begin pre-loader --> */}
                        <Preloader />
                        <NewHeader />
                        <div class="app-container">
                            <SideNav />
                            {/* <!-- begin app-main --> */}
                            <div class="app-main" id="main">
                                {/* <!-- begin container-fluid --> */}
                                <div class="container-fluid">
                                    {/* <!-- begin row --> */}
                                    <div class="row">
                                        <div class="col-md-12 m-b-30">
                                            {/* <!-- begin page title --> */}
                                            <div class="d-block d-sm-flex flex-nowrap align-items-center">
                                                <div class="page-title mb-2 mb-sm-0">
                                                    <h1>All easyCare Appointments</h1>
                                                </div>
                                                <div class="ml-auto d-flex align-items-center">
                                                    <nav>
                                                        <ol class="breadcrumb p-0 m-b-0">
                                                            <li class="breadcrumb-item">
                                                                <a href="index.html"><i class="ti ti-home"></i></a>
                                                            </li>
                                                            <li class="breadcrumb-item">
                                                                Home
                                                            </li>
                                                            <li class="breadcrumb-item active text-primary" aria-current="page">Schedule Appointments</li>
                                                        </ol>
                                                    </nav>
                                                </div>
                                            </div>
                                            {/* <!-- end page title --> */}
                                        </div>
                                    </div>
                                    {/* <!-- end row --> */}
                                    {/* <!-- begin row --> */}

                                    <div class="row">
                                        <div class="col-xs-6 col-xxl-3 m-b-30">
                                            <div class="card card-statistics h-100 m-b-0 bg-pink">
                                                <div class="card-body">
                                                    <h2 class="text-white mb-0">{this.state.appointments}</h2>
                                                    <p class="text-white">All Appointments</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-xs-6 col-xxl-3 m-b-30">
                                            <div class="card card-statistics h-100 m-b-0 bg-primary">
                                                <div class="card-body">
                                                    <h2 class="text-white mb-0">{this.state.completed}</h2>
                                                    <p class="text-white">Completed Appointments </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-xs-6 col-xxl-3 m-b-30">
                                            <div class="card card-statistics h-100 m-b-0 bg-orange">
                                                <div class="card-body">
                                                    <h2 class="text-white mb-0">{this.state.missedApps}</h2>
                                                    <p class="text-white">Missed Appointments</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-xs-6 col-xxl-3 m-b-30">
                                            <div class="card card-statistics h-100 m-b-0 bg-info">
                                                <div class="card-body">
                                                    <h2 class="text-white mb-0">{this.state.pendingAccept}</h2>
                                                    <p class="text-white">Pending Acceptance</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-lg-12">
                                            <div class="card card-statistics">
                                                <div class="card-body">

                                                    <div class="my-3">
                                                        <button type="button" onClick={this.onClickAppointment} class="btn btn-primary">Add Appointment(s)</button>
                                                    </div>

                                                    <div class="datatable-wrapper table-responsive">

                                                        <Table
                                                            columns={columns}
                                                            bordered
                                                            // onChange={this.onchange}
                                                            dataSource={this.state.appointmentsList}
                                                            size="small"
                                                            rowClassName={(record, index) => (record.prefDate < moment().format('DD MMMM YYYY') && record.mstatus === true ? "badge-danger-inverse" : ((record.prefDate === moment().format('DD MMMM YYYY')) || record.mstatus === false ? ("badge-success-inverse") : (Math.ceil((new Date(record.prefDate) - new Date(new Date().toLocaleDateString())) / (1000 * 60 * 60 * 24) === 1) ? ("badge-warning-inverse") : (""))))}
                                                            pagination={{ pageSizeOptions: ['10'], showTitle: false, hideOnSinglePage: false }} />

                                                        {this.state.dialog ? (
                                                            <ResultModal
                                                                visible={{ visible: true }}
                                                                handleCancel={this.recievedState}
                                                            />
                                                        ) : null}

                                                        {this.state.reschedule ? (
                                                            <RescheduleAppointment
                                                                visible={{ visible: true, prefTimeId: this.state.prefTimeIdentity, prefDate: this.state.prefDateEdit, hospitalId: this.state.hospitalId, serviceId: this.state.serviceId, specialistId: this.state.specialistId, client: this.state.clientId, appointment: this.state.appointmentId }}
                                                                handleCancel={this.recievedStateReschedule}
                                                            />
                                                        ) : null}

                                                        {this.state.sweetAlert ? (

                                                            <SweetAlert
                                                                warning
                                                                showCancel
                                                                confirmBtnText="Yes, confirm!"
                                                                confirmBtnBsStyle="success"
                                                                title="Confirm Appointment?"
                                                                cancelBtnBsStyle="secondary"
                                                                onConfirm={this.onConfirmAppointment}
                                                                onCancel={this.onCancelConfirm}
                                                                focusCancelBtn
                                                            >
                                                                {this.state.confirmError}
                                                            </SweetAlert>

                                                        ) : null}

                                                        {this.state.sweetAlertConfirm ? (

                                                            <SweetAlert
                                                                success
                                                                title={"Appointment Accepted !"}
                                                                onConfirm={this.okAcceptance}
                                                                timeout={5000}
                                                            >
                                                                {this.state.confirmSuccess} !
                                                            </SweetAlert>

                                                        ) : null}

                                                        {this.state.sweetAlertConfirm ? (

                                                            <SweetAlert
                                                                success
                                                                title={"Action Completeted !"} //Appointment Accepted 
                                                                onConfirm={this.okAcceptance}
                                                                timeout={5000}
                                                            >
                                                                {this.state.confirmSuccess} !
                                                            </SweetAlert>

                                                        ) : null}

                                                        {this.state.workState ? (

                                                            <SweetAlert
                                                                custom
                                                                showCancel
                                                                customButtons={
                                                                    <React.Fragment>
                                                                        <button className="btn btn-inverse-danger" style={{ marginInlineEnd: "10px" }} onClick={this.onMarkMissed}>No - Mark as missed</button>
                                                                        <button className="btn btn-inverse-success" onClick={this.onMarkComplete}>Yes - Mark as complete</button> <br /><br />
                                                                        <button className="btn btn-warning" style={{ marginTop: "20px" }} onClick={this.onCancelAttend}>Cancel</button>
                                                                    </React.Fragment>
                                                                }
                                                                customIcon="https://raw.githubusercontent.com/djorg83/react-bootstrap-sweetalert/master/demo/assets/thumbs-up.jpg"
                                                                title="Did the patient attend their appointment?"

                                                            >
                                                                {this.state.loadError}<br />
                                                                {this.state.load}
                                                            </SweetAlert>

                                                        ) : null}

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <!-- end row --> */}
                                </div>
                                {/* <!-- end container-fluid --> */}
                            </div>
                            {/* <!-- end app-main --> */}
                        </div>
                        {/* <!-- end app-container --> */}
                        <NewFooter />
                    </div>
                    {/* <!-- end app-wrap --> */}
                </div>
                {/* <!-- end app --> */}
            </body>
        )
    }
}
export default NewAppointments