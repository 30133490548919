import React from 'react'
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
import functions from '../utils/functions'
import Forgot from '../pages/Auth/Forgot'
import Login from '../pages/Auth/Login'
import UserAuth from '../pages/Auth/UserAuth'
import ErrorPage from '../pages/Error/NewNotFound'
import NewAppointments from '../pages/Appointments/NewAppointments'
import ManageUsers from '../pages/Users/ManageUsers'
import NewSpecialities from '../pages/Physicians/NewSpecialities'
import ResetPassword from '../pages/Auth/ResetPassword'
import HospitalDetails from '../pages/Hospitals/NewHospitalDetails'
import NewHospitals from '../pages/Hospitals/NewHospitals'
import NewDashboard from '../pages/NewDashboard'
import Dashboard from '../pages/HospitalDashboards/Dashboard'
import NewPhysician from '../pages/Physicians/NewPhysician'
import NewSystemSettings from '../pages/NewSystemSettings'
import NewHospitalServices from '../pages/Hospitals/NewHospitalServices'
import NewHospitalPayments from '../pages/Hospitals/NewHospitalPayments'
import NewHospitalCategories from '../pages/Hospitals/NewHospitalCategories'
import NewSpecialityCategories from '../pages/Specialities/NewSpecialityCategories'
import NewManageUsers from '../pages/Users/NewManageUsers'
import Missed from '../pages/Missed'
import Completed from '../pages/Completed'
import Pending from '../pages/Pending'
import PendingApproval from '../pages/Prescriptions/PendingApproval'
import ApprovedPres from '../pages/Prescriptions/ApprovedPres'
import DeclinedPrescriptions from '../pages/Prescriptions/DeclinedPrescriptions'
import PendingOrders from '../pages/Medicines/PendingOrders'
import DispensedMedicines from '../pages/Billing/DispensedMedicines'
import OrderTracking from '../pages/Medicines/OrderTracking'
import Parkaging from '../pages/OrdersParkaging/Parkaging'


class Routed extends React.Component {

  state = {
    screen: "Login",
    loggedIn: true,
    accessInfo: functions.permissionGuard()
  }

  componentDidMount() {
    this.checkLogin();
  }

  checkLogin() {
    if (!localStorage.getItem("equiva@user")) {
      this.setState({
        loggedIn: false
      })
    } else {
      this.setState({
        loggedIn: true
      })
    }
  }

  // dashboards = () => {

  //   const roleList = functions.roleGuard()

  //   if (functions.findInPermission(this.state.accessInfo, "OPERATIONS-00")) {
  //     return (
  //       <Dashboard />
  //     )
  //   } else if (functions.findInPermission(this.state.accessInfo, "CASHIER-00")) {
  //     return (
  //       <CashierDashboard />
  //     )
  //   } else if (functions.findInPermission(this.state.accessInfo, "FINANCE-00")) {
  //     return (
  //       <Finance />
  //     )
  //   }
  //   else if (functions.findInPermission(this.state.accessInfo, "USERS-00")) {
  //     return (
  //       <Users />
  //     )
  //   }
  //   else if (functions.findInPermission(this.state.accessInfo, "CONFIG-00")) {
  //     return (
  //       <SystemSettings />
  //     )
  //   } else {
  //     return (
  //       <WelcomePage />
  //     )
  //   }
  // }

  render() {
    const roleList = functions.roleGuard()
    return (
      <Router forceRefresh={true}>
        {/* {!this.state.loggedIn && <Redirect to="/login" />} */}

        <Switch>

          {!this.state.loggedIn && <Route exact path="/" component={UserAuth} />}

          {roleList === "RECEP" &&
            <>

              <Route exact path="/" component={Dashboard} />

              <Route exact path="/pending-accept" component={Pending} />

              <Route exact path="/missed" component={Missed}/>

              <Route exact path="/completed" component={Completed}/>

              <Route exact path="/prescriptions-pending-approval" component={PendingApproval}/>

              <Route exact path="/prescriptions-approved" component={ApprovedPres}/>
              
              <Route exact path="/prescriptions-declined" component={DeclinedPrescriptions}/>

              <Route exact path="/orders-pending-reviews" component={PendingOrders}/>
              
              <Route exact path="/billing-orders" component={DispensedMedicines}/>

              <Route exact path="/order-tracking" component={OrderTracking}/>

              <Route exact path="/orders-parking" component={Parkaging}/>

            </>

          }

          {roleList === "SUPER" &&
            <>
              <Route exact path="/" component={NewDashboard} />

              <Route exact path="/clinics-hospitals" component={NewHospitals} />

              <Route
                exact
                path="/physicians"
                component={NewPhysician}
              />

              <Route exact path="/appointments" component={NewAppointments} />

              <Route
                exact
                path="/manage-users"
                component={NewManageUsers}
              />

              <Route
                exact
                path="/add-speciality"
                component={NewSpecialities}
              />

              <Route
                exact
                path="/system-settings"
                component={NewSystemSettings}
              />

              <Route
                exact
                path="/clinic-hospital-categories"
                component={NewHospitalCategories}
              />

              <Route
                exact
                path="/add-hospital-service"
                component={NewHospitalServices}
              />

              <Route
                exact
                path="/payment-methods"
                component={NewHospitalPayments}
              />

              <Route
                exact
                path="/hospital-details/:hospital"
                component={HospitalDetails}
              />

              <Route
                exact
                path="/speciality-categories"
                component={NewSpecialityCategories}
              />
       
            <Route exact path="/pending-accept" component={Pending} />

            <Route exact path="/missed" component={Missed}/>

            <Route exact path="/completed" component={Completed}/>

            <Route exact path="/prescriptions-pending-approval" component={PendingApproval}/>

            <Route exact path="/prescriptions-approved" component={ApprovedPres}/>

            <Route exact path="/prescriptions-declined" component={DeclinedPrescriptions}/>

            <Route exact path="/orders-pending-reviews" component={PendingOrders}/>
              
            <Route exact path="/billing-orders" component={DispensedMedicines}/>

            <Route exact path="/order-tracking" component={OrderTracking}/>

            <Route exact path="/orders-parking" component={Parkaging}/>


            </>
          }

          <Route exact path="*" component={ErrorPage} />

          <Route
            exact
            path="/reset-password"
            component={ResetPassword}
          />

          <Route
            exact
            path="/login"
            component={UserAuth}
          />

          <Route
            exact
            path="/forgot-password"
            component={Forgot}
          />

        </Switch>
      </Router>
    )
  }
}

export default Routed
