import React, { Component } from 'react'
import { Table, Tooltip } from 'antd'
import { Link } from 'react-router-dom'
import Preloader from '../../common/Preloader'
import NewHeader from '../../common/NewHeader'
import SideNav from '../../common/SideNav'
import ResultModal from '../../common/Modals/AddHospitalPaymet'
import EditResultModal from '../../common/Modals/EditHospitalPayment'
import SweetAlert from 'react-bootstrap-sweetalert'
import NewFooter from '../../common/NewFooter'
import functions from '../../utils/functions'
import ajax from '../../utils/ajax';


class NewHospitalPayments extends Component {

    state = {
        dialog: false,

        edit: false,
        sweetAlert: false,
        paymentList: false,
        paymentName: "",
        paymentCode: "",
        paymentId: ""
    }

    componentDidMount() {
        this.getAllServices();
    }

    recievedState = (data) => {
        this.setState({ dialog: data })
    }

    recievedEditState = (data) => {
        this.setState({ edit: data })
    }

    onClickRegister = () => {
        this.setState({
            dialog: true
        })
    }

    editRow = (key) => {

        this.setState({
            edit: true, paymentName: key.paymentName,
            paymentCode: key.paymentCode, paymentId: key.actioned
        })
    }

    deleteRow = (key) => {
        this.setState({ sweetAlert: true, paymentId: key })
    }

    onCancelDelet = () => {
        this.setState({ sweetAlert: false, paymentId: "" })
    }

    confirmDelete = async () => {

        const { paymentId } = this.state

        if (paymentId.length > 0) {

            const server_response = await ajax.deletePaymentMethods(paymentId);

            if (server_response.status === "OK") {
                this.setState({
                    sweetAlert: false
                })
                window.location.reload()

            } else {
                console.log(server_response)

                this.setState({
                    sweetAlert: false
                })

            }
        } else {
            // console.log("Nothing to delete")
        }

    }

    getAllServices = async () => {
        const server_response = await ajax.listPaymentMethods();
        const tableData = []

        if (server_response.status === "OK") {

            // console.log(server_response.details.content)

            server_response.details.content.map((payment) =>
                tableData.push({
                    paymentName: payment.payment_method_name,
                    paymentCode: payment.payment_method_code,
                    userId: payment.created_by.first_name + " " + payment.created_by.last_name + " - " + payment.created_by.user_role.role_name,
                    updated: payment.updated_on.long_date,
                    actioned: payment.payment_method_id,
                }),
            )
            this.setState({
                paymentList: tableData
            })

        } else {
            // this.setState({
            //     categoryList: "404"
            // })
        }
    }



    render() {

        const columns = [
            {
                title: 'Payment Method Name',
                dataIndex: 'paymentName',
            },
            {
                title: 'Payment Method Code',
                dataIndex: 'paymentCode',
            },
            {
                title: 'Added By ',
                dataIndex: 'userId',
            },
            {
                title: 'Upddated On',
                dataIndex: 'updated',
            },
            {
                title: 'Actions - [ Edit, Delete ]',
                dataIndex: 'actioned',

                className: 'bg-transparent text-right',
                render: (text, records, index) => {
                    return (
                        <div className="text-nowrap">
                            <Tooltip
                                placement="leftTop"
                                title="Edit Category"
                                color="#454B1B"
                                key="#f50"
                                style={{ marginRight: "10px" }}
                            >
                                <button
                                    type="button"
                                    className="btn btn-light text-red"
                                    onClick={() => this.editRow(records)}

                                >
                                    <i className="fa fa-edit" />
                                </button>
                            </Tooltip>

                            <Tooltip
                                placement="leftTop"
                                title="Delet Category"
                                color="#f50"
                                key="#f50"
                            >
                                <button
                                    type="button"
                                    className="btn btn-light text-red"
                                    onClick={() => this.deleteRow(text)}
                                >
                                    <i className="fa fa-trash" />
                                </button>
                            </Tooltip>

                        </div>
                    )
                }
            },

        ]

        return (
            <body>
                {/* <!-- begin app --> */}
                <div class="app">
                    {/* <!-- begin app-wrap --> */}
                    <div class="app-wrap">
                        {/* <!-- begin pre-loader --> */}
                        <Preloader />
                        <NewHeader />
                        <div class="app-container">
                            <SideNav />
                            {/* <!-- begin app-main --> */}
                            <div class="app-main" id="main">
                                {/* <!-- begin container-fluid --> */}
                                <div class="container-fluid">
                                    {/* <!-- begin row --> */}
                                    <div class="row">
                                        <div class="col-md-12 m-b-30">
                                            {/* <!-- begin page title --> */}
                                            <div class="d-block d-sm-flex flex-nowrap align-items-center">
                                                <div class="page-title mb-2 mb-sm-0">
                                                    <h1>Hospital Payment Methods</h1>
                                                </div>
                                                <div class="ml-auto d-flex align-items-center">
                                                    <nav>
                                                        <ol class="breadcrumb p-0 m-b-0">
                                                            <li class="breadcrumb-item">
                                                                <a href="index.html"><i class="ti ti-home"></i></a>
                                                            </li>
                                                            <li class="breadcrumb-item">
                                                                System Settings
                                                            </li>
                                                            <li class="breadcrumb-item active text-primary" aria-current="page">Hospital Payment Methods</li>
                                                        </ol>
                                                    </nav>
                                                </div>
                                            </div>
                                            {/* <!-- end page title --> */}
                                        </div>
                                    </div>
                                    {/* <!-- end row --> */}
                                    {/* <!-- begin row --> */}
                                    <div class="row">
                                        <div class="col-lg-12">
                                            <div class="card card-statistics">
                                                <div class="card-body">

                                                    <div class="my-3">
                                                        <button type="button" onClick={this.onClickRegister} class="btn btn-primary">Add Payment Method</button>
                                                    </div>

                                                    <div class="datatable-wrapper table-responsive">

                                                        <Table
                                                            columns={columns}
                                                            bordered
                                                            // onChange={this.onchange}
                                                            dataSource={this.state.paymentList}
                                                            size="small"
                                                            pagination={{ pageSizeOptions: ['10'], showTitle: false, hideOnSinglePage: false }}

                                                        />

                                                        {this.state.dialog ? (
                                                            <ResultModal
                                                                visible={{ visible: true }}
                                                                handleCancel={this.recievedState}
                                                            />
                                                        ) : null}

                                                        {this.state.edit ? (
                                                            <EditResultModal
                                                                visible={{ visible: true, paymentName: this.state.paymentName, paymentCode: this.state.paymentCode, paymentId: this.state.paymentId }}
                                                                handleEditCancel={this.recievedEditState}
                                                            />
                                                        ) : null}

                                                        {this.state.sweetAlert ? (

                                                            <SweetAlert
                                                                warning
                                                                showCancel
                                                                confirmBtnText="Yes, delete it!"
                                                                confirmBtnBsStyle="danger"
                                                                title="Are you sure?"
                                                                onConfirm={this.confirmDelete}
                                                                onCancel={this.onCancelDelet}
                                                                focusCancelBtn
                                                            >
                                                                This action is cant be undone!
                                                            </SweetAlert>

                                                        ) : null}

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <!-- end row --> */}
                                </div>
                                {/* <!-- end container-fluid --> */}
                            </div>
                            {/* <!-- end app-main --> */}
                        </div>
                        {/* <!-- end app-container --> */}
                        <NewFooter />
                    </div>
                    {/* <!-- end app-wrap --> */}
                </div>
                {/* <!-- end app --> */}
            </body>
        )
    }
}
export default NewHospitalPayments