import React, { Component } from 'react'
import { Table, Tooltip } from 'antd'
import { Link } from 'react-router-dom'
import Preloader from '../../common/Preloader'
import NewHeader from '../../common/NewHeader'
import SideNav from '../../common/SideNav'
import ResultModal from '../../common/Modals/AddSpecialityCategory'
import NewFooter from '../../common/NewFooter'
import EditResultModal from '../../common/Modals/EditSpecialityCategory'
import functions from '../../utils/functions'
import ajax from '../../utils/ajax'
import SweetAlert from 'react-bootstrap-sweetalert'

class NewSpecialityCategories extends Component {

    state = {
        dialog: false,
        edit: false,
        sweetAlert: false,
        confirmAlert:false,
        specialityCategoryList: false,
        specialtityCategoryName: "",
        categoryCode: "",
        categoryId: ""
    }

    recievedState = (data) => {
        this.setState({ dialog: data })
    }

    onConfirmed = () => {
        window.location.reload()
    }

    componentDidMount() {
        this.getAllCategories();
    }

    editRow = (key) => {
        // console.log(key)
        this.setState({
            edit: true, specialtityCategoryName: key.specialityName,
            categoryCode: key.categoryCode, categoryId: key.actioned
        })
    }

    deleteRow = (key) => {
        this.setState({ sweetAlert: true, categoryId: key })
    }

    onCancelDelet = () => {
        this.setState({ sweetAlert: false, categoryId: "" })
    }

    confirmDelete = async () => {

        const { categoryId } = this.state

        if (categoryId.length > 0) {

            const server_response = await ajax.deleteSpecialityCategory(categoryId);

            if (server_response.status === "OK") {
                this.setState({
                    sweetAlert: false,
                    confirmAlert:true
                })

            } else {
                this.setState({
                    sweetAlert: false,
                    confirmAlert:false
                })

            }
        } else {
            console.log("Nothing to delete")
        }

    }

    recievedEditState = (data) => {
        this.setState({ edit: data })
    }

    componentDidMount() {
        this.getAllCategories();
    }

    onClickRegister = () => {
        this.setState({
            dialog: true,
        })
    }

    onClickEdit = () => {
        this.setState({
            edit: true,
        })
    }

    getAllCategories = async () => {
        const server_response = await ajax.listSpecialityCategories();
        const tableData = []

        if (server_response.status === "OK") {

            server_response.details.content.map((categories) =>
                tableData.push({
                    specialityName: categories.category_name,
                    categoryCode: categories.service_category_code,
                    // userId: categories.created_by.first_name + " " + categories.created_by.last_name + " - " + categories.created_by.user_role.role_name,
                    updated: categories.updated_on.long_date,
                    actioned: categories.category_id,
                }),
            )

            this.setState({
                specialityCategoryList: tableData
            })

        } else {
            // this.setState({
            //     categoryList: "404"
            // })
        }
    }


    render() {

        const columns = [
            {
                title: 'Speciality Category Name',
                dataIndex: 'specialityName',
            },
            {
                title: 'Speciality Category Code',
                dataIndex: 'categoryCode',
            },
            // {
            //     title: 'Added By ',
            //     dataIndex: 'userId',
            // },
            {
                title: 'Upddated On',
                dataIndex: 'updated',
            },
            {
                title: 'Actions - [ Edit, Delete ]',
                dataIndex: 'actioned',

                className: 'bg-transparent text-right',
                render: (text, records, index) => {
                    return (
                        <div className="text-nowrap">
                            <Tooltip
                                placement="leftTop"
                                title="Edit Category"
                                color="#454B1B"
                                key="#f50"
                                style={{ marginRight: "10px" }}
                            >
                                <button
                                    type="button"
                                    className="btn btn-light text-red"
                                    onClick={() => this.editRow(records)}

                                >
                                    <i className="fa fa-edit" />
                                </button>
                            </Tooltip>

                            <Tooltip
                                placement="leftTop"
                                title="Delet Category"
                                color="#f50"
                                key="#f50"
                            >
                                <button
                                    type="button"
                                    className="btn btn-light text-red"
                                    onClick={() => this.deleteRow(text)}
                                >
                                    <i className="fa fa-trash" />
                                </button>
                            </Tooltip>

                        </div>
                    )
                }
            },

        ]


        return (
            <body>
                {/* <!-- begin app --> */}
                <div class="app">
                    {/* <!-- begin app-wrap --> */}
                    <div class="app-wrap">
                        {/* <!-- begin pre-loader --> */}
                        <Preloader />
                        <NewHeader />
                        <div class="app-container">
                            <SideNav />
                            {/* <!-- begin app-main --> */}
                            <div class="app-main" id="main">
                                {/* <!-- begin container-fluid --> */}
                                <div class="container-fluid">
                                    {/* <!-- begin row --> */}
                                    <div class="row">
                                        <div class="col-md-12 m-b-30">
                                            {/* <!-- begin page title --> */}
                                            <div class="d-block d-sm-flex flex-nowrap align-items-center">
                                                <div class="page-title mb-2 mb-sm-0">
                                                    <h1>Speciality Categories </h1>
                                                </div>
                                                <div class="ml-auto d-flex align-items-center">
                                                    <nav>
                                                        <ol class="breadcrumb p-0 m-b-0">
                                                            <li class="breadcrumb-item">
                                                                <a href="index.html"><i class="ti ti-home"></i></a>
                                                            </li>
                                                            <li class="breadcrumb-item">
                                                                System Settings
                                                            </li>
                                                            <li class="breadcrumb-item active text-primary" aria-current="page">Speciality Categories</li>
                                                        </ol>
                                                    </nav>
                                                </div>
                                            </div>
                                            {/* <!-- end page title --> */}
                                        </div>
                                    </div>
                                    {/* <!-- end row --> */}
                                    {/* <!-- begin row --> */}
                                    <div class="row">
                                        <div class="col-lg-12">
                                            <div class="card card-statistics">
                                                <div class="card-body">

                                                    <div class="my-3">
                                                        <button type="button" onClick={this.onClickRegister} class="btn btn-primary">Add Speciality Category</button>
                                                    </div>

                                                    <div class="datatable-wrapper table-responsive">

                                                        <Table
                                                            columns={columns}
                                                            bordered
                                                            // onChange={this.onchange}
                                                            dataSource={this.state.specialityCategoryList}
                                                            size="small"
                                                            pagination={{ pageSizeOptions: ['10'], showTitle: false, hideOnSinglePage: false }}

                                                        />


                                                        {this.state.dialog ? (
                                                            <ResultModal
                                                                visible={{ visible: true }}
                                                                handleCancel={this.recievedState}
                                                            />
                                                        ) : null}

                                                        {this.state.edit ? (
                                                            <EditResultModal
                                                                visible={{ visible: true, specialtityCategoryName: this.state.specialtityCategoryName, categoryCode: this.state.categoryCode, categoryId: this.state.categoryId }}
                                                                handleEditCancel={this.recievedEditState}
                                                            />
                                                        ) : null}

                                                        {this.state.sweetAlert ? (

                                                            <SweetAlert
                                                                warning
                                                                showCancel
                                                                confirmBtnText="Yes, delete it!"
                                                                confirmBtnBsStyle="danger"
                                                                cancelBtnBsStyle="secondary"
                                                                title="Are you sure?"
                                                                onConfirm={this.confirmDelete}
                                                                onCancel={this.onCancelDelet}
                                                                focusCancelBtn
                                                            >
                                                                This action is cant be undone!
                                                            </SweetAlert>

                                                        ) : null}

                                                        {this.state.confirmAlert ? (
                                                            <SweetAlert
                                                                success
                                                                title="Deleted !"
                                                                onConfirm={this.onConfirmed}
                                                                timeout={2000}
                                                            >
                                                                Deleted Successfully !
                                                            </SweetAlert>
                                                        ) : null}

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <!-- end row --> */}
                                </div>
                                {/* <!-- end container-fluid --> */}
                            </div>
                            {/* <!-- end app-main --> */}
                        </div>
                        {/* <!-- end app-container --> */}
                        <NewFooter />
                    </div>
                    {/* <!-- end app-wrap --> */}
                </div>
                {/* <!-- end app --> */}
            </body>
        )
    }
}
export default NewSpecialityCategories