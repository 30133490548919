import React, { Component } from 'react'
import { SpinnerDotted } from 'spinners-react'

class Loader extends Component {
  render() {
    return (
      <div >
        <div class="text-center" >
          <SpinnerDotted size="40" color='#17a2b8' style={{ height: '100px' }} />
        </div>
        <br />
      </div>
    )
  }
}

export default Loader

