import React, { Component } from 'react'
import { Table, Tooltip } from 'antd'
import { Link } from 'react-router-dom'
import Preloader from '../../common/Preloader'
import NewHeader from '../../common/NewHeader'
import SideNav from '../../common/SideNav'
import EditResultModal from '../../common/Modals/EditPhysician'
import SweetAlert from 'react-bootstrap-sweetalert'
import ResultModal from '../../common/Modals/AddPhysician'
import NewFooter from '../../common/NewFooter'
import functions from '../../utils/functions'
import ajax from '../../utils/ajax'


class NewPhysician extends Component {

  state = {
    dialog: false,
    edit: false,
    sweetAlert: false,
    doctorId: "",
    physiciansList: false,


  }

  componentDidMount() {
    this.getPhysicianList();
  }

  editRow = (key) => {
    this.setState({
      edit: true,
      firstName: key.firstName,
      lastName: key.lastName,
      phone: key.phone,
      email: key.email,
      speciality: key.specId,
      hosptial: key.hospId,
      workHours: key.workHours,
      userId: key.userId,
      actioned: key.actioned
    })
  }

  onCancelDelet = () => {
    this.setState({ sweetAlert: false, doctorId: "" })
  }

  recievedState = (data) => {
    this.setState({ dialog: data })
  }

  recievedEditState = (data) => {
    this.setState({ edit: data })
  }

  deleteRow = (key) => {
    this.setState({ sweetAlert: true, doctorId: key })
  }

  onClickRegister = () => {
    this.setState({
      dialog: true
    })
  }


  confirmDelete = async () => {

    const { doctorId } = this.state

    if (doctorId.length > 0) {

      const server_response = await ajax.deleteDoctor(doctorId);

      if (server_response.status === "OK") {
        this.setState({
          sweetAlert: false
        })
        window.location.reload()

      } else {

        this.setState({
          sweetAlert: false
        })

      }
    } else {
      console.log("Nothing to delete")
    }

  }

  getPhysicianList = async () => {
    const server_response = await ajax.listSpecialist();
    const tableData = []


    if (server_response.status === "OK") {

      server_response.details.content.map((specialist) =>
        tableData.push({
          firstName: specialist.first_name,
          lastName: specialist.last_name,
          phone: specialist.phone_number,
          email: specialist.email,
          speciality: specialist.speciality.speciality_name,
          hosptial: specialist.hospital.full_hospital_name, 
          workHours: specialist.workHours,
          userId: specialist.created_by.first_name + " " + specialist.created_by.last_name,
          updated: specialist.updated_on.short_date,
          actioned: specialist.specialist_id,
          specId:specialist.speciality.speciality_id,
          hospId:specialist.hospital.hospital_id
        }),
      )
      this.setState({
        physiciansList: tableData
      })

    } else {
      // this.setState({
      //     categoryList: "404"
      // })
    }
  }

  render() {


    const columns = [
      {
        title: 'First Name',
        dataIndex: 'firstName',
      },
      {
        title: 'Last Name',
        dataIndex: 'lastName',
      },
      {
        title: 'Phone Number',
        dataIndex: 'phone',
      },
      {
        title: 'E - Mail',
        dataIndex: 'email',
      },
      {
        title: 'Speciality',
        dataIndex: 'speciality',
      },
      {
        title: 'Speciality Id',
        dataIndex: 'specId',
        hidden: true
      },
      {
        title: 'Hospital / Clinic ',
        dataIndex: 'hosptial',
      },
      {
        title: 'Hospital Id',
        dataIndex: 'hospId',
        hidden: true
      },
      {
        title: 'Work Hours',
        dataIndex: 'workHours',
      },
      {
        title: 'Added By ',
        dataIndex: 'userId',
      },
      {
        title: 'Upddated On',
        dataIndex: 'updated',
      },
      {
        title: 'Actions - [ Edit, Delete ]',
        dataIndex: 'actioned',

        className: 'bg-transparent text-right',
        render: (text, records, index) => {
          return (
            <div className="text-nowrap">
              <Tooltip
                placement="leftTop"
                title="Edit Specialist"
                color="#454B1B"
                key="#f50"
                style={{ marginRight: "10px" }}
              >
                <button
                  type="button"
                  className="btn btn-light text-red"
                  onClick={() => this.editRow(records)}

                >
                  <i className="fa fa-edit" />
                </button>
              </Tooltip>

              <Tooltip
                placement="leftTop"
                title="Delete Specialist"
                color="#f50"
                key="#f50"
              >
                <button
                  type="button"
                  className="btn btn-light text-red"
                  onClick={() => this.deleteRow(text)}
                >
                  <i className="fa fa-trash" />
                </button>
              </Tooltip>

            </div>
          )
        }
      },

    ].filter(item => !item.hidden)

    return (

      <body>
        {/* <!-- begin app --> */}
        <div class="app">
          {/* <!-- begin app-wrap --> */}
          <div class="app-wrap">
            {/* <!-- begin pre-loader --> */}
            <Preloader />
            <NewHeader />
            <div class="app-container">
              <SideNav />
              {/* <!-- begin app-main --> */}
              <div class="app-main" id="main">
                {/* <!-- begin container-fluid --> */}
                <div class="container-fluid">
                  {/* <!-- begin row --> */}
                  <div class="row">
                    <div class="col-md-12 m-b-30">
                      {/* <!-- begin page title --> */}
                      <div class="d-block d-sm-flex flex-nowrap align-items-center">
                        <div class="page-title mb-2 mb-sm-0">
                          <h1>Physicians [Doctors] </h1>
                        </div>
                        <div class="ml-auto d-flex align-items-center">
                          <nav>
                            <ol class="breadcrumb p-0 m-b-0">
                              <li class="breadcrumb-item">
                                <a href="index.html"><i class="ti ti-home"></i></a>
                              </li>
                              <li class="breadcrumb-item">
                                Home
                              </li>
                              <li class="breadcrumb-item active text-primary" aria-current="page">Physicians</li>
                            </ol>
                          </nav>
                        </div>
                      </div>
                      {/* <!-- end page title --> */}
                    </div>
                  </div>
                  {/* <!-- end row --> */}
                  {/* <!-- begin row --> */}
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="card card-statistics">
                        <div class="card-body">

                          <div class="my-3">
                            <button type="button" onClick={this.onClickRegister} class="btn btn-primary">Add Physician (s)</button>
                          </div>

                          <div class="datatable-wrapper table-responsive">

                            <Table
                              columns={columns}
                              bordered
                              // onChange={this.onchange}
                              dataSource={this.state.physiciansList}
                              size="small"
                              pagination={{ pageSizeOptions: ['10'], showTitle: false, hideOnSinglePage: false }} />

                            {this.state.dialog ? (
                              <ResultModal
                                visible={{ visible: true }}
                                handleCancel={this.recievedState}
                              />
                            ) : null}

                            {this.state.sweetAlert ? (

                              <SweetAlert
                                warning
                                showCancel
                                confirmBtnText="Yes, delete it!"
                                confirmBtnBsStyle="danger"
                                title="Are you sure?"
                                cancelBtnBsStyle="secondary"
                                onConfirm={this.confirmDelete}
                                onCancel={this.onCancelDelet}
                                focusCancelBtn
                              >
                                This action is cant be undone!
                              </SweetAlert>

                            ) : null}

                            {this.state.edit ? (
                              <EditResultModal
                                visible={{
                                  visible: true, firstName: this.state.firstName, lastName: this.state.lastName, phone: this.state.phone,
                                  email: this.state.email, speciality: this.state.speciality, hospital: this.state.hosptial, workHours: this.state.workHours, 
                                  userId: this.state.userId, actioned: this.state.actioned
                                }}
                                handleEditCancel={this.recievedEditState}
                              />
                            ) : null}

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <!-- end row --> */}
                </div>
                {/* <!-- end container-fluid --> */}
              </div>
              {/* <!-- end app-main --> */}
            </div>
            {/* <!-- end app-container --> */}
            <NewFooter />
          </div>
          {/* <!-- end app-wrap --> */}
        </div>
        {/* <!-- end app --> */}
      </body>

    )
  }
}
export default NewPhysician