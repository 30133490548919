import React, { Component } from 'react'
import { Table, Tooltip } from 'antd'
import { Link } from 'react-router-dom'
import Preloader from '../../common/Preloader'
import NewHeader from '../../common/NewHeader'
import SideNav from '../../common/SideNav'
import ResultModal from '../../common/Modals/AddClinicHospital'
import NewFooter from '../../common/NewFooter'
import ajax from '../../utils/ajax';
import functions from '../../utils/functions'
import ReviewPrescriptionSuccess from '../../common/Modals/ReviewPrescriptionSuccess'
import PendingApproval from './PendingApproval'


class DeclinedPrescriptions extends Component {

    state = {
        userId: functions.sessionGuard(),
        review: false,
        dialog: false,
        isActive: false,
        hospitalsList: false,
        hosId: "",
        prescriptionId:"",
        presciptionImage:""
        
    };

    componentDidMount() {
        this.getDeclinedPrescriptions();
    }

    recievedStateReview = (data) => {
        this.setState({ reschedule: data })
    }

    onClickRegister = () => {
      this.setState({ dialog: true })
    }

    recievedState = (data) => {
      this.setState({ dialog: data })
    }

    getDeclinedPrescriptions = async () => {
        const server_response = await ajax.listDeclinedPrescriptions(this.state.userId);
        const tableData = []

        if (server_response.status === "OK") {
             //console.log("lplplplplplplplooo",server_response.details.content.list)

            server_response.details.content.map((pending) =>
            tableData.push({
                clientName: pending.pres_client.full_name,
                clientPhone: pending.pres_client.phone_number,
                prescriptionName: pending.pres_name,
                prescriptionid: pending.pres_id,
                prescriptionimage:pending.pres_photo,
                prescriptionStatus: pending.verify_status == 0? "Declined":"",
                created: pending.created_at.long_date,
                reviewer:pending.verified_by.last_name+" "+pending.verified_by.first_name,
                actioned: pending.pres_id,
            }),
        )

            this.setState({
               hospitalsList: tableData
            })

        } else {
            //   this.setState({
            //     hospitalsList: "404"
            //   })
        }
    }

    onClickReview = (prescriptionId, presciptionImage) => {
        this.setState({
            reschedule: true,
            prescriptionId:prescriptionId,
            presciptionImage:presciptionImage
        })
    }

    render() {

        const columns = [
            {
                title: 'Client Name',
                dataIndex: 'clientName',
            },
            {
                title: 'Client Phone',
                dataIndex: 'clientPhone',
            },
            {
                title: 'Precription Name',
                dataIndex: 'prescriptionName',
            },
            {
                title: 'Precription ID',
                dataIndex: 'prescriptionid',
                hidden: true
            },
            {
                title: 'Precription Image',
                dataIndex: 'prescriptionimage',
                hidden: true
            },
            {
                title: 'Precription Status',
                dataIndex: 'prescriptionStatus'
            },
            {
                title: 'Reviewed By',
                dataIndex: 'reviewer'
            },
            {
                title: 'Reviewed At',
                dataIndex: 'created',
            },
            {
                title: 'Actions - [ View ]',
                dataIndex: 'actioned',

                className: 'bg-transparent text-right',
                render: (text, records, index) => {
                    return (
                        <div className="text-nowrap">
                            <Tooltip
                                placement="leftTop"
                                title="View to take action "
                                color="#454B1B"
                                key="#f50"
                                style={{ marginRight: "10px" }}
                            >
                                <button
                                    type="button"
                                    className="btn btn-light text-red"
                                    style={{ marginInlineEnd: "10px" }}
                                    onClick={() => this.onClickReview(records.prescriptionid, records.prescriptionimage)}                                >
                                    Review Prescription <i className="fa fa-eye" />
                                </button>

                            </Tooltip>

                        </div>
                    )
                }
            },

        ].filter(item => !item.hidden)

        return (

            <body>
                {/* <!-- begin app --> */}
                <div class="app">
                    {/* <!-- begin app-wrap --> */}
                    <div class="app-wrap">
                        {/* <!-- begin pre-loader --> */}
                        <Preloader />
                        <NewHeader />
                        <div class="app-container">
                            <SideNav />
                            {/* <!-- begin app-main --> */}
                            <div class="app-main" id="main">
                                {/* <!-- begin container-fluid --> */}
                                <div class="container-fluid">
                                    {/* <!-- begin row --> */}
                                    <div class="row">
                                        <div class="col-md-12 m-b-30">
                                            {/* <!-- begin page title --> */}
                                            <div class="d-block d-sm-flex flex-nowrap align-items-center">
                                                <div class="page-title mb-2 mb-sm-0">
                                                    <h1>Declined Prescriptions</h1>
                                                </div>
                                                <div class="ml-auto d-flex align-items-center">
                                                    <nav>
                                                        <ol class="breadcrumb p-0 m-b-0">
                                                            <li class="breadcrumb-item">
                                                                <a href="index.html"><i class="ti ti-home"></i></a>
                                                            </li>
                                                            <li class="breadcrumb-item">
                                                                System Settings
                                                            </li>
                                                            <li class="breadcrumb-item active text-primary" aria-current="page">All Declined Prescriptions</li>
                                                        </ol>
                                                    </nav>
                                                </div>
                                            </div>
                                            {/* <!-- end page title --> */}
                                        </div>
                                    </div>
                                    {/* <!-- end row --> */}
                                    {/* <!-- begin row --> */}
                                    <div class="row">
                                        <div class="col-lg-12">
                                            <div class="card card-statistics">
                                                <div class="card-body">

                                                    <div class="datatable-wrapper table-responsive">

                                                        <Table
                                                            columns={columns}
                                                            bordered
                                                            dataSource={this.state.hospitalsList}
                                                            size="small"
                                                            rowClassName="badge-danger-inverse"
                                                            pagination={{ pageSizeOptions: ['10'], showTitle: false, hideOnSinglePage: false }}

                                                        />

                                                        {this.state.dialog ? (
                                                        <ResultModal
                                                            visible={{ visible: true }}
                                                            handleCancel={this.recievedState}
                                                        />
                                                        ) : null}  

                                                        {this.state.reschedule ? (
                                                            <ReviewPrescriptionSuccess
                                                                visible={{ visible: true,presImage: this.state.presciptionImage}}
                                                                handleCancel={this.recievedStateReview}
                                                            />
                                                        ) : null}

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <!-- end row --> */}
                                </div>
                                {/* <!-- end container-fluid --> */}
                            </div>
                            {/* <!-- end app-main --> */}
                        </div>
                        {/* <!-- end app-container --> */}
                        <NewFooter />
                    </div>
                    {/* <!-- end app-wrap --> */}
                </div>
                {/* <!-- end app --> */}
            </body>

        )
    }
}
export default DeclinedPrescriptions